/*
 * (c) Verra Technology Corporation
 */

import ModifiableObject from '../../../model/ModifiableObject.mjs';
import OpenModalCommand from '../../commands/OpenModalCommand';
import Alert from '../../components/controls/Alert';
import SphereAdminServices from '../../model/SphereAdminServices';
import SphereAdminSession from '../../model/SphereAdminSession';
import ServiceRequestCommand from '../ServiceRequestCommand';

/**
 * Saves a Site
 */
class SaveSiteRequest extends ServiceRequestCommand {

	/**
	 * Constructs the Request
	 * @param site The Site to save
	 */
	constructor( site, completeCommand ){
		super();
		this.site = site;
	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
    getService() {
		return SphereAdminServices.SAVE_SITE;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		return { site: this.site };
	}

   /**
	* Handles completion of the command
	*/
	handleComplete() {
		const response = this.getResponse();
		//console.info( 'SaveSiteRequest::handleComplete', this.site, response );
		if( response.success ) {

			// update the clientId, status
			this.site.clientId = response.result.site.clientId;
			this.site.status = ModifiableObject.SAVED; // TODO: should this come from the DB?
			
			// this is a new site that's been saved for the first time
			if( this.site.id == null ){
				// update the id and switch to the edit view
				this.site.id = response.result.site.id;
				SphereAdminSession.selectedAccount.sites.push( this.site ); // TODO: ensure selectedAccount.sites is used and needed
				SphereAdminSession.sites.push( this.site );
			}
		} else {
			var alert = <Alert content={response.error} showCancelBtn={false}  okHandler={ this.#handleErrorConfirmed.bind( this ) }/>;
			var openModal = new OpenModalCommand( 'Error', alert, 300 );
			openModal.execute();
		}
	}

	/**
	 * Handles the click on the confirm button in the Alert modal
	 */
	#handleErrorConfirmed(){
		// var setState = new SetStateCommand( AdminStates.ADMIN_CONFIGURATION_SITES );
		// setState.execute();
	}
 
}

export default SaveSiteRequest;
