/*
* (c) Verra Technology Corporation
*/

import React, { Component } from 'react';

import CloseModalCommand from '../../commands/CloseModalCommand';

//

//

/**
 * A modal alert
 */
class Alert extends Component {

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {

		const okLabel = ( this.props.continueLabel != null ) ? this.props.continueLabel : 'Ok';
		const showCancel = ( this.props.showCancelBtn != null ) ? this.props.showCancelBtn : true;
		const cancelLabel = ( this.props.cancelLabel != null ) ? this.props.cancelLabel : 'Cancel';
		const cancelBtn = ( !showCancel ) ? '' : <div className='button control-pad-left' style={{ width: '90px' }} onClick={handleCancel.bind( this )}>{ cancelLabel }</div>;

		return ( 
			<div className='alert'>
				<div className='grid panel-cell'>
					<div className='grid-cell default-100 pad-cell-top'>
						{ this.props.content }
					</div>
					<div className='grid-cell default-100' style={{ padding: '20px 0 0 0', textAlign: 'center' }}>
						<div className='primary-button' style={{ width: '90px' }} onClick={ handleOk.bind( this )}>{ okLabel }</div>
						{ cancelBtn }
					</div>
				</div>
			</div>
		);
	}

}

//

/**
 * Handles a click on the ok button, closes the modal
 */
function handleOk( e ) {
	const closeModal = new CloseModalCommand();
	closeModal.execute();
	if( this.props.okHandler != null ) this.props.okHandler();
};

/**
 * Handles a click on the cancel button, closes the modal
 */
function handleCancel( e ) {
	const closeModal = new CloseModalCommand();
	closeModal.execute();
	if( this.props.cancelHandler != null ) this.props.cancelHandler();
};

//

export default Alert;