/*
 * (c) Verra Technology Corporation
 */

import ServiceRequestCommand from '../ServiceRequestCommand.js';
import SphereAdminSession from '../../model/SphereAdminSession.js';
import SphereAdminServices from '../../model/SphereAdminServices.js';
import SetStateCommand from '../../commands/SetStateCommand.js';
import AdminStates from '../../model/AdminStates.js';

/**
 * Makes a request to authenticate the user
 */
class SignInUserRequest extends ServiceRequestCommand {


	/**
	 * Constructs the request
	 */
	 constructor( email, password ){
		super();
		this.email = email;
		this.password = password;
	}

   /**
	* Handles execution of the Command
	*/
	handleExecute() {
		// reset the error state
		SphereAdminSession.signInError = null;
		var setState = new SetStateCommand( AdminStates.NOT_AUTHENTICATED );
		setState.execute();

		// execute the request
		super.handleExecute();
   	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
	getService() {
		return SphereAdminServices.AUTHENTICATE;
	}

	/**
	 * @return Whether or not the request requires an authenticated session. Default is true
	 */
	 requiresAuth() {
		return false;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		return { email: this.email, password: this.password };
	}

   /**
	* Handles completion of the command
	*/
	handleComplete() {
		var response = this.getResponse();
		//console.info( 'SignInUserRequest::handleComplete', response, SphereAdminSession.token );
		var setState;
		if( response.success ) {
			SphereAdminSession.user = response.result.user;
			setState = new SetStateCommand( AdminStates.AUTHENTICATED );
		} else {
			SphereAdminSession.signInError = response.error;
			setState = new SetStateCommand( AdminStates.AUTHENTICATION_FAILED );
		}
		setState.execute();
	}
 
}

export default SignInUserRequest;
