/*
 * (c) Verra Technology Corporation
 */

import AdminStates from '../model/AdminStates.js'
import AsynchronousCommand from '../../commands/AsynchronousCommand.mjs';
import SphereAdminSession from '../model/SphereAdminSession.js';
import SphereAdminServices from '../model/SphereAdminServices.js';
import SetStateCommand from '../commands/SetStateCommand.js';


/**
 * Command class for making service layer requests
 */
class ServiceRequestCommand extends AsynchronousCommand {

   	/**
	 * Handles execution of the Command
	 */
   	handleExecute() {

		//console.info( 'ServiceRequestCommand::handleExecute', this.getService() );
		// TODO: refactor to fetch
		/*
		this.request = new XMLHttpRequest();
		this.request.onreadystatechange = ( e ) => { handleRequestResponse.apply( this, [ e ]); };
		this.request.open( 'POST', this.getBaseUrl() + this.getService() );
		this.request.setRequestHeader( 'Content-Type', 'application/json' );

		var params = this.getParams();
		
		if( this.requiresAuth() ) {
			//if( params == null ) params = {};
			//params.sessionId = SphereAdminSession.sessionId;
			//if( SphereAdminSession.selectedAccount != null ) params.accountId = SphereAdminSession.selectedAccount.id;
			this.request.setRequestHeader( 'Authorization', 'Bearer ' + SphereAdminSession.token );
		}

		var requestParams = ( params != null ) ? JSON.stringify( params ) : null;
		this.request.send( requestParams );
		*/

		///*
		var params = this.getParams();
		var options = {
			method: 'POST',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' },
			body: ( params != null ) ? JSON.stringify( params ) : null
		};

		if( this.requiresAuth() ){
			options.headers[ 'Authorization' ] = 'Bearer ' + SphereAdminSession.token;
		}

		fetch( this.getBaseUrl() + this.getService(), options )
			.then(( response ) => {
				if( response.ok ){
					var authHeader = response.headers.get( 'Authorization' );
					if( authHeader != null ){
						SphereAdminSession.token = authHeader.split( 'Bearer ' )[1];
					}
					return response.json();
				} else {
					if( response.status === 401 ){
						var setState = new SetStateCommand( AdminStates.NOT_AUTHENTICATED );
						setState.execute();
					} else {
						this.#handleFailedResponse( response.statusText );
					}
				}
			})
			.then(( data ) => {
				if( data.success ){
					try{
						this.#handleResponse( data );
					} catch( e ) {
						console.error( e );
					}
				} else {
					this.#handleFailedResponse( data.error ); 
				}
			})
			.catch(( err ) => { 
				// console.dir( err );
				this.#handleFailedResponse( err.message ); 
			}
		);
		//*/
   	}

   	// Template methods

	/**
	 * @return The base URL for the request, defaults to SphereAdminServices.BASE_URL
	 */
	getBaseUrl() {
		return SphereAdminServices.BASE_URL;
	}

	/**
	 * @return The URL for the request
	 */
	getService() {
		// template method, override
		return null;
	}

	/**
	 * @return Whether or not the request requires an authenticated session. Default is true
	 */
   	requiresAuth() {
		return true;
	}

	/**
	 * @return The parameters to send with the request
	 */
   	getParams() {
		// template method, override
		return null;
	}

	/**
	 * @return The response from the request to verify the user
	 */
	getResponse() {
		return this.response;
	}

	//

	/**
	 * Handles the response from the request
	 */
	#handleResponse( result ) {
		// console.info( '(Verra) ServiceRequestCommand::handleRequestResponse', this.getService(), result );
		this.response = result;
		this.complete();
	};

	/**
	 * Handles failed requests
	 */
	#handleFailedResponse( error ){
		console.error( '(Verra) ServiceRequestCommand::handleFailedResponse', this.getService(), error );
		this.response = { success: false, error };
		this.complete();
	}

}

/**
 * Handles the response from the request
 */
/*
var handleRequestResponse = function( e ) {
	console.info( 'ServiceRequestCommand::handleRequestResponse', this.getService(), e );
	if( this.request.readyState === 4 ){
		if( this.request.status === 200 ) {
			var authHeader = this.request.getResponseHeader( 'Authorization' );
			console.info( 'authHeader', authHeader );
			if( authHeader != null ){
				SphereAdminSession.token = authHeader.split( 'Bearer ' )[1];
			}
			this.rawResponse = this.request.responseText;
			if( !this.raw ) this.response = JSON.parse( this.request.responseText );
			this.complete();	
		} else if ( this.request.status === 401 ) {
			console.info( 'ServiceRequestCommand::handleRequestResponse', '401 not authorized', this.getService() );
			var setState = new SetStateCommand( AdminStates.NOT_AUTHENTICATED );
			setState.execute();
		}
	}
};
*/

//

export default ServiceRequestCommand;
