/*
 * (c) Verra Technology Corporation
 */

import PublishableObject from './PublishableObject.mjs';
import OptimizationModelTypes from './OptimizationModelTypes';
import Audience from './Audience';
import Experience from './Experience';
import Metric from './Metric';

/**
 * Represents an Optimization
 */
class Optimization extends PublishableObject {

	/**
	 * Represents a status of complete for the Optimization
	 * @see ModifiableObject
	 */
	static COMPLETE = 5;

	/**
	 * Defines the structure of the Channel class. Used with ObjectUtil.CreateFromDefinition
	 */
	static definition = {
		type: Optimization,
		properties: {
			audiences: {
				type: Audience
			},
			experiences: {
				type: Experience
			},
			metrics: {
				type: Metric
			}
		}
	}

	// Properties

	/**
	 * The ID of the account in which the Optimization is associated
	 */
	accountId = null;

	/**
	 * The ID of the Site in which the Optimization is associated
	 */
	siteId = null;

	/**
	 * The ID of the Optimization
	 */
	id  = '';

	/**
	 * The name of the Optimzation
	 */
	name = '';

	/**
	 * The type of Optimization, see OptimizationModelTypes
	 */
	type = OptimizationModelTypes.AB;
	
	/**
	 * An array of Audiences for the Optimization
	 */
	audiences = [];
	
	/**
	 * An array of Experiences for the Optimization
	 */
	experiences = [];

	/**
	 * The metrics used to track performance
	 */
	metrics = [];
	
}

//

export default Optimization;