/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import Hint from '../controls/Hint';
import SphereAdminSession from '../../model/SphereAdminSession';
import DataGrid from '../controls/DataGrid';
import OpenModalCommand from '../../commands/OpenModalCommand';
import SelectAudienceModal from '../modals/SelectAudienceModal';
import CreateAudienceModal from '../modals/CreateAudienceModal';
import PublishableObject from '../../../model/PublishableObject.mjs';

//

/**
 * The TargetingTab contains the UI for setting up and managing URL rules and audiences for Optimizations
 */
class TargetingTab extends Component {
	
	/**
	 * Constructs the panel.
	 */
	 constructor() {
		super();

		this.state = {
			audiencesSelected: false
		};

		this.audiencesGridRef = React.createRef();
	}

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		return 	<div>
					{/* { this.#getUrlRulesMarkup() } */}
					{ this.#getAudiencesMarkup() }
				</div>;
	}

	/**
	 * @return the markup for managing URL rules --- Started down this path but, URL targeting can be done as an Audience using the Current Page parameter
	 */
	// #getUrlRulesMarkup() {
	// 	const optimization = SphereAdminSession.optimization;
	// 	const urlsToolTip = 'URL rules define which pages on your site the Optimization will apply';
	// 	const noUrlRulesMsg = <div className='grid-cell default-100'>No URL rules have been assigned to the Optimization, this optimization will apply to every page of your site.</div>;
	// 	const isPublished = ( optimization.status === PublishableObject.PUBLISHED );
	// 	const buttonsDisabled = ( optimization.locked ) ? 'disabled' : '';
	// 	const removeDisabled = ( !optimization.locked && this.state.audiencesSelected ) ? '' : 'disabled';
		
	// 	const headers = [ 
	// 		{ label: 'Name', field: 'name', width: '90' }
	// 	];

	// 	const urlRules = [];

	// 	return <div>
	// 				<div className='content-panel'>
	// 					<div className='grid panel-cell'>
	// 						<div className='grid-cell default-60'>
	// 							<h3>URL Rules <Hint width='250px' content={ urlsToolTip }/></h3>
	// 						</div>
	// 						{ !isPublished && 
	// 							<div className='grid-cell default-40 align-right'>
	// 								<button className={ 'button ' + buttonsDisabled } onClick={ this.#handleCreateNewAudience.bind( this ) }>Create</button>
	// 								<button className={ 'button control-pad-left ' + removeDisabled }  onClick={ this.#handleRemoveAudience.bind( this ) }>Remove</button>
	// 							</div>
	// 						}
	// 					</div>
	// 					<div className='url-rules'>
	// 						<div className='rule'>
	// 							<InputField
	// 								placeholder='Enter the URL'
	// 								maxLength='256'
	// 								readOnly={ isPublished }
	// 								onChange={( value ) => { console.info( 'change'); }}/>
	// 							<DropDownField
	// 								className='pad-cell-left'
	// 								width='250px'
	// 								labelField='label'
	// 								items={ [ 'test 1', 'test 2' ] }
	// 								selectedIndex={ 0 }
	// 								changeHandler={( combinator ) => { console.info( 'test' ); }}/>
	// 						</div>
	// 						<div className='rule'>
	// 							<InputField
	// 								value={ 'optimization.name' }
	// 								maxLength='256'
	// 								readOnly={ isPublished }
	// 								onChange={( value ) => { console.info( 'change'); }}/>
	// 						</div>

	// 					</div>
	// 				</div>
	// 			</div>;
	// }

	/**
	 * @return The markup for managing Audiences
	 */
	#getAudiencesMarkup() {
		const optimization = SphereAdminSession.optimization;
		const isPublished = ( optimization.status === PublishableObject.PUBLISHED );
		const audiencesToolTip = 'Choose the audience for which this optimization will apply';
		const canEditMsg = ( !isPublished ) ? 'Use the buttons to the right to create and assign audiences.' : '';
		const noAudiencesMsg = <div className='grid-cell default-100'>No audiences have been assigned to the Optimization, this optimization will apply to everyone. { canEditMsg }</div>;
		const buttonsDisabled = ( optimization.locked ) ? 'disabled' : '';
		const removeDisabled = ( !optimization.locked && this.state.audiencesSelected ) ? '' : 'disabled';
		
		const headers = [{ label: 'Name', field: 'name', width: '90' }];

		return <div>
					<div className='content-panel'>
						<div className='grid panel-cell'>
							<div className='grid-cell default-60'>
								<h3>Audiences <Hint width='250px' content={ audiencesToolTip }/></h3>
							</div>
							{ !isPublished && 
								<div className='grid-cell default-40 align-right'>
									<button className={ 'button ' + buttonsDisabled } onClick={ this.#handleCreateNewAudience.bind( this ) }>Create</button>
									<button className={ 'button control-pad-left ' + buttonsDisabled } onClick={ this.#handleAddAudience.bind( this ) }>Add</button>
									<button className={ 'button control-pad-left ' + removeDisabled }  onClick={ this.#handleRemoveAudience.bind( this ) }>Remove</button>
								</div>
							}
						</div>
					</div>
					<DataGrid 
						ref={ this.audiencesGridRef } 
						headers={ headers } 
						data={ optimization.audiences } 
						idField='id' 
						checkColumn={ true } 
						checkOnRowClick={ true }
						showHeaderIfEmpty={ false } 
						statusIndicator={ false } 
						disabled={ optimization.locked || isPublished } 
						maxBodyHeight='300px'
						noContent={ noAudiencesMsg }
						checkBoxChangeHandler={ this.#handleGridCheckBoxSelect.bind( this )}
					/>
				</div>;
	}

	//

	/**
	 * Handles clicks on the new Channel button
	 */
	#handleCreateNewAudience( e ) {
		const createAudienceModal = <CreateAudienceModal createdHandler={ this.#handleAudienceCreatedAndAdded.bind( this )}/>;
		const openModal = new OpenModalCommand( 'Create Audience', createAudienceModal, '70%', false );
		openModal.execute();
	};

	/**
	 * Handles a click to add an existing Audience
	 */
	#handleAddAudience() {
		const selectAudienceModal = <SelectAudienceModal selectHandler={ this.#handleAudienceSelectedForAdd.bind( this )}/>;
		const openModal = new OpenModalCommand( 'Select Audience', selectAudienceModal, '700px', false );
		openModal.execute();
	}

	/**
	 * Handles selection of Audiences in the SelectAudienceModal
	 */
	#handleAudienceCreatedAndAdded() {
		this.props.changeHandler();
		this.setState({});
	}

	/**
	 * Handles selection of Audiences in the SelectAudienceModal
	 */
	#handleAudienceSelectedForAdd( selectedAudiences ) {
		const audiences = SphereAdminSession.optimization.audiences;
		selectedAudiences.forEach(( selectedAudience ) => {
			if( audiences.find( audience => audience === selectedAudience ) == null ) {
				audiences.push( selectedAudience );
			}
		})
		this.audiencesGridRef.current.unCheckAll();
		this.props.changeHandler();
		this.setState({});
	}

	/**
	 * Handles clicks on a checkbox in the Content grid
	 */
	#handleGridCheckBoxSelect( grid ) {
		const selected = grid.getChecked();
		this.setState({ audiencesSelected: ( selected.length > 0 )});
	}

	/**
	 * Handles the click to remove and audience from the optimization
	 */
	#handleRemoveAudience() {
		const selectedAudiences = this.audiencesGridRef.current.getChecked();
		const optimization = SphereAdminSession.optimization;
		optimization.audiences = optimization.audiences.filter(( audience ) => {
			return selectedAudiences.find( selectedAudience => audience === selectedAudience ) == null;
		});
		this.audiencesGridRef.current.unCheckAll();
		this.props.changeHandler();
		this.setState({ audiencesSelected: false });
	}

}

//

export default TargetingTab;
