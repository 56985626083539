/*
 * (c) Verra Technology Corporation
 */

import OptimizationEventAnalytics from "./OptimizationEventAnalytics";
import OptimizationMetricAnalytics from "./OptimizationMetricAnalytics";

/*

{

	events: {
				'experience-id-1': {
					count: 50,
					sessions: 25
				},
				'experience-id-2': {
					count: 50,
					sessions: 25
				}
			}
		}
	},
	metrics: {
		'979a98a8-e652-4fcd-973e-0b3ac04796f3': {
			total: 10,
			sessions: 50,
			cvr: 2.5,
			revenue: 25000,
			aov: 125.5
			engagement: <points to events obj>,
			experiences: {
				'experience-id-1': {
					count: 5,
					sessions: 2,
					cvr: 2.5,
					revenue: 25000,
					aov: 125.5
				},
				'experience-id-2': {
					count: 3,
					sessions: 2,
					cvr: 2.5,
					...
				}
			}
		}
	}

				'experience-id-1': {
					count: 50
				},
				'experience-id-2': {
					count: 50
				}
			},
			conversions: {
				'place-order': {
					total: 10,
					cvr: 2.5,
					revenue: 25000,
					aov: 125.5
					experiences: {
						'experience-id-1': {
							count: 5,
							cvr: 2.5,
							revenue: 25000,
							aov: 125.5
						},
						'experience-id-2': {
							count: 3,
							...
						}
					},
				}
			}
		},
		sessions: {
			total: 50
			experiences: {
				'experience-id-1': {
					count: 25
				},
				'experience-id-2': {
					count: 25
				}
			},
			conversions: {
				...
			}
		}
	}
	
}

*/

/**
 * Represents the analytics (events and metrics) for an Optimization
 */
class OptimizationAnalytics {

	/**
	 * The Optimization in which the metrics are for
	 */
	#optimization;

	/**
	 * The events that have occurred with the Optimization present
	 */
	#events;

	/**
	 * The metrics assigned to the Optimization
	 */
	#metrics;

	//

	/**
	 * Constructs a new OptimizationAnalytics model object. 
	 * @param optimization The Optimization object in which the analytics apply
	 */
	constructor( optimization ) {
		this.#optimization = optimization;
		this.#events = {};
		this.#metrics = {};
		// console.info( optimization );
	}

	//

	/**
	 * Updates analytics values for a events
	 * @param analytics An object with the analytics to update { 'page-load': { total: 5, sessions: 2, experiences: { ... }}}
	 */
	updateEventAnalytics( analytics ) {
		Object.keys( analytics ).forEach( eventId => {
			const eventData = analytics[ eventId ];
			// let eventAnalytics = this.#events[ eventId ];
			// if( eventAnalytics == null ) {
			// 	eventAnalytics = new OptimizationEventAnalytics( this.#optimization, eventId );
			// 	this.#events[ eventId ] = eventAnalytics;
			// }
			this.#events[ eventId ] = new OptimizationEventAnalytics( this.#optimization, eventId );
			this.#events[ eventId ].updateAnalytics( eventData );
		});
		// console.info( this.#events );
	}

	/**
	 * Updates analytics values for a Metric
	 * @param metric The Metric to update data for
	 * @param analytics An object with the metrics to update, { count: 5, order-value: 100, ... }
	 */
	updateConversionAnalytics( metric, analytics ) {
		const engagementEventAnalytics = this.#events[ metric.engagementEventId ];
		const eventData = analytics[ metric.conversionEventId ];
		// let metricAnalytics = this.#metrics[ metric.id ];
		// if( metricAnalytics == null ) {
		// 	metricAnalytics = new OptimizationMetricAnalytics( this.#optimization, engagementEventAnalytics, metric );
		// 	this.#metrics[ metric.id ] = metricAnalytics;
		// }
		this.#metrics[ metric.id ] = new OptimizationMetricAnalytics( this.#optimization, engagementEventAnalytics, metric );
		this.#metrics[ metric.id ].updateAnalytics( eventData );
	}

	//

	/**
	 * @return The metric for a specific event
	 */
	getEngagementEventMetric( eventTypeId ) {
		return this.#events[ eventTypeId ];
	}

	/**
	 * @return The metrics for a specific event
	 */
	getExperienceEngagementEventMetric( eventId, frequencyId, experienceId ) {
		const metricProp = ( frequencyId === 0 ) ? 'count' : 'sessions';
		const count = this.#events?.[ eventId ]?._experiences?.[ experienceId ]?.[ metricProp ];
		return count;
	}

	/**
	 * @return The metrics for a specific event
	 */
	getConversionEventMetric( metricId ) {
		return this.#metrics[ metricId ];
	}

	/**
	 * @return The metrics for a specific event
	 */
	getExperienceConversionEventMetric( metricId, experienceId, metric ) {
		return this.#metrics[ metricId ]._experiences[ experienceId ][ metric ];
	}

	/**
	 * @return The the analytics for experiences for a metric
	 */
	getMetricExperiencesAnalytics( metricId ) {
		return this.#metrics?.[ metricId ]?._experiences;
	}

}

//

export default OptimizationAnalytics;