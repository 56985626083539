/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';

import DataGrid from '../controls/DataGrid';
import RetrieveAudiencesListRequest from '../../requests//audiences/RetrieveAudiencesListRequest';
import AudienceRule from '../../../model/AudienceRule';
import SphereAdminSession from '../../model/SphereAdminSession';
import AudienceRuleOperators from '../../../model/AudienceRuleOperators';

//

/**
 * Displays the list of Audiences in a data grid
 */
class AudiencesList extends Component {
	
	/**
	 * Constructs the ChannelPanel.
	 */
	constructor() {
		super();
		
		this.state = {
			audiencesSelected: false
		};

		this.audiencesGrid = React.createRef();

		if( SphereAdminSession.audiences == null || SphereAdminSession.audiences.length === 0 ) {
			SphereAdminSession.loading = true;
			const retrieveAudiences = new RetrieveAudiencesListRequest();
			retrieveAudiences.execute(( command ) => { this.#handleAudiencesRetrieved( command ); } );
		}
	}

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {	
		const audiences = SphereAdminSession.audiences;
		const emptyResultsMarkup = ( this.props.noContent != null ) ? this.props.noContent : <div>You do not have any Audiences</div>;
		const statusIndicator = ( this.props.statusIndicator != null ) ? this.props.statusIndicator : false; 
		const checkOnRowClick = ( this.props.checkOnRowClick ) ? this.props.checkOnRowClick : false;

		const headers = [ 
			{ label: 'Name', field: 'name', width: '40' }, 
			{
				label: 'Rules', 
				field: ( audience ) => { 
					let rulesLabel;
					if( audience.rules.length == 0 ) {
						rulesLabel = 'No rules have been assigned to this Audience'
					} else {
						rulesLabel = audience.rules.reduce(( accumulator, rule, index ) => {  
							const operatorSymbol = AudienceRuleOperators[ AudienceRuleOperators.operatorsByValue[ rule.operator ]].symbol;
							const ruleDetails = `${ rule.variableName } ${ operatorSymbol } ${ rule.value }`;
							return ( accumulator != null ) ? 
								`${ accumulator }, ${ ruleDetails }` : 
								`${ ruleDetails }`
						}, null );
					}
					return rulesLabel;
				}, 
				width: '55' }
		];

		return 	<DataGrid 
					ref={ this.audiencesGrid }
					headers={ headers } 
					data={ audiences } 
					idField='id' 
					checkColumn={ true } 
					checkOnRowClick={ checkOnRowClick }
					statusIndicator={ statusIndicator } 
					statusField='status' 
					noContent={ emptyResultsMarkup }
					rowClickHandler={ this.props.rowClickHandler }
					checkBoxChangeHandler={ this.props.checkBoxChangeHandler }/>;
	}

	// Handlers

	/**
	 * Handles the response from the request to retrieve Channel
	 */
	#handleAudiencesRetrieved( command ) {
		SphereAdminSession.loading = false;
		this.setState({}); // redraw
	}

	//

	/**
	 * Gets the selected audiences in the list
	 */
	getChecked() {
		return this.audiencesGrid.current.getChecked();
	}

	/**
	 * Un checks all of the audiences in the list
	 */
	unCheckAll() {
		return this.audiencesGrid.current.unCheckAll();
	}

}

//

export default AudiencesList;
