/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';

import SphereAdminConfig from '../model/SphereAdminConfig';
import SphereAdminSession from '../model/SphereAdminSession';
import SetStateCommand from '../commands/SetStateCommand';
import PrimaryNavigationItem from '../components/PrimaryNavigationItem';
import LayersIcon from '../icons/LayersIcon';
import AdminStates from '../model/AdminStates';
import PageIcon from '../icons/PageIcon';
import LaptopIcon from '../icons/LaptopIcon';
import SettingsIcon from '../icons/SettingsIcon';
import PerformanceIcon from '../icons/PerformanceIcon';
import ArrowLeftIcon from '../icons/ArrowLeftIcon';
import ArrowRightIcon from '../icons/ArrowRightIcon';
import MoveIcon from '../icons/MoveIcon';
import UserIcon from '../icons/UserIcon';
import ChartIcon from '../icons/ChartIcon';

//

let currentOpenItem = null;
let currentOpenElement = null;

/**
 * Flag used to re-minimize the nav after clicking a nav item
 */
let reMinimize = false;

/**
 * Defines a mapping between states and icons
 */
const iconMap = {};
iconMap[ AdminStates.INITIALIZED ] = <LaptopIcon width='23' height='23'/>;
iconMap[ AdminStates.ADMIN_OPTIMIZATIONS ] = <PerformanceIcon size='25'/>;
iconMap[ AdminStates.ADMIN_EXPERIENCES ] = <LayersIcon width='25' height='25'/>;
iconMap[ AdminStates.ADMIN_AUDIENCES ] = <UserIcon size='25'/>;
iconMap[ AdminStates.ADMIN_METRICS ] = <ChartIcon size='25'/>;
iconMap[ AdminStates.ADMIN_CHANNELS ] = <MoveIcon size='25' color='#ffffff'/>;
iconMap[ AdminStates.ADMIN_CONTENT ] = <PageIcon size='24'/>;
iconMap[ AdminStates.ADMIN_CONFIGURATION ] = <SettingsIcon width='25' height='25'/>;

//

/**
 * Renders the PrimaryNavigation for the administration app
 */
class PrimaryNavigation extends Component {
	
	/**
	 * Constructs the Component
	 */
	constructor(){
		super();
		currentOpenElement = null;
		window.addEventListener( 'resize', e => this.#handleWindowResize() );
		const observer = new ResizeObserver( e => this.#handleWindowResize() );
		observer.observe( document.body );
	}

	/**
	 * Handles the mounting of the component
	 */
	componentDidMount(){
		// console.info( 'componentDidMount' );
		this.#handleWindowResize();
	}
	
	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		const currentState = SphereAdminSession.currentState;
		const states = SphereAdminConfig.states;
		const clickHandler = (( e, navItem, isSubNavItem, hasSubNavItems ) => { this.#handleNavItemSelect( e, navItem, isSubNavItem, hasSubNavItems ); });
		const navItems = [];
		
		for( let i = 0; i < states.length; i++ ) {
			let panel = states[ i ];
			let selected = currentState === panel.state;
			if( panel.isNavigationItem ) {
				let navItem = <PrimaryNavigationItem 
								key={panel.state} 
								state={panel.state} 
								clickHandler={clickHandler} 
								panel={panel} 
								selected={selected}
								icon={iconMap[ panel.state ]}
								/>;
				navItems.push( navItem );
			}
		}

		const minimized = ( SphereAdminSession.navMinimized ) ? 'minimized' : '';
	
		return ( 
			<div id='primary-nav' className={'primary-nav ' + minimized}>
				<div className='shadow-layer layer-1'></div>
				<div className='shadow-layer layer-2'></div>
				<nav>
					{navItems}
				</nav>
				<div className='controls'>
					<button className='close' onClick={ this.#toggleMinMax.bind( this ) }><ArrowLeftIcon color='#666666'/></button>
					<button className='open' onClick={ this.#toggleMinMax.bind( this ) }><ArrowRightIcon color='#666666'/></button>
				</div>
			</div>
		);
	}

	//

	/**
	 * Handles selections (clicks) on navigational items
	 */
	#handleNavItemSelect( event, navItem, isSubNavItem, hasSubNavItems ){
		// console.info( 'handleNavItemSelect' );

		event.preventDefault();
		event.stopPropagation();

		const lastOpenItem = currentOpenItem;

		if( currentOpenItem !== null && !isSubNavItem ) currentOpenItem.toggleOpenClose();
		
		if( navItem.hasSubNav() && !isSubNavItem ) {
			if( currentOpenItem !== navItem ) navItem.toggleOpenClose();
			currentOpenItem = ( currentOpenItem !== navItem ) ? navItem : null;
			currentOpenElement = ( currentOpenElement !== event.currentTarget ) ? event.currentTarget : null;
		} else {
			if( !isSubNavItem ){
				currentOpenItem = null;
				currentOpenElement = null;
			}
			const state = event.currentTarget.getAttribute( 'data-panel-state' );
			const setState = new SetStateCommand( state );
			setState.execute();
		}

		const primaryNavElement = document.getElementById( 'primary-nav' );
		const isMinimized = primaryNavElement.classList.contains( 'minimized' );

		if( isMinimized && hasSubNavItems ){
			reMinimize = true;
			primaryNavElement.classList.remove( 'minimized' );
		} else if( reMinimize && ( isSubNavItem || lastOpenItem === navItem )){
			reMinimize = false;
			if( currentOpenItem != null ) currentOpenItem.toggleOpenClose();
			currentOpenItem = null;
			currentOpenElement = null;
			primaryNavElement.classList.add( 'minimized' );
		}

		this.#handleWindowResize();
	}

	/**
	 * Toggles the min and max state of the nav
	 */
	#toggleMinMax(){
		const primaryNavElement = document.getElementById( 'primary-nav' );
		const isMinimized = primaryNavElement.classList.contains( 'minimized' );
		if( isMinimized ){
			primaryNavElement.classList.remove( 'minimized' );
			SphereAdminSession.navMinimized = false;
		} else {
			primaryNavElement.classList.add( 'minimized' );
			if( currentOpenItem != null ) currentOpenItem.toggleOpenClose();
			currentOpenItem = null;
			currentOpenElement = null;
			this.#handleWindowResize();
			SphereAdminSession.navMinimized = true;
		}
	}

	/**
	 * Handles resizing the nav
	 */
	#handleWindowResize(){
		var navElement = document.querySelector( '#primary-nav nav' );
		if( navElement != null ){
			
			const navElementRect = navElement.getBoundingClientRect();
			const layer1 = document.querySelector( '#primary-nav .shadow-layer.layer-1' );
			const layer2 = document.querySelector( '#primary-nav .shadow-layer.layer-2' );
	
			// reset the styles before we calc height
			layer1.style.height = '0px';
			layer2.style.height = '0px';
			layer2.style.top = '0px';
	
			const navY = navElementRect.top + window.scrollY;
			const body = document.body;
			const html = document.documentElement;
			const height = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
	
			if( currentOpenElement === null ){
				layer1.style.height = ( height - navY ) + 'px';
			} else {
				const currentOpenElementRect = currentOpenElement.getBoundingClientRect();
				layer1.style.height = ( currentOpenElementRect.bottom - navY + window.scrollY ) + 'px';
	
				const subNavElement = currentOpenElement.nextSibling;
				const subNavElementRect = subNavElement.getBoundingClientRect();
				const subNavElementBottom = subNavElementRect.bottom + window.scrollY;
	
				layer2.style.top = ( subNavElementBottom - navY ) + 'px';
				layer2.style.height = ( height - subNavElementBottom ) + 'px';
			}
		}
	}
	
}

//

export default PrimaryNavigation;
