/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import { 
	VictoryAxis,
	VictoryBar,
	VictoryChart,
	VictoryLabel} from 'victory';
import Loader from '../controls/Loader';
import ChartsTheme from './ChartsTheme';
import DropDownField from '../controls/DropDownField';
import SphereAdminSession from '../../model/SphereAdminSession';
import ChannelModelTypes from '../../model/ChannelModelTypes';
import MetricEventTypes from '../../../model/MetricEventTypes';

//

const CHANNEL_VIEW_TYPE = 'Channel';
const VARIANTS_VIEW_TYPE = 'Variants';

/**
 * 
 */
const viewTypes = [
	CHANNEL_VIEW_TYPE,
	VARIANTS_VIEW_TYPE
];

//

/**
 * Displays a bar graph of winner probabilities
 */
class ExperienceWinnerChart extends Component {

	/**
	 * Constructs the component
	 */
	constructor() {
		super();
		this.state = {
			// selectedViewType: ( SphereAdminSession.channel.model === ChannelModelTypes.PREDICTIVE ) ? CHANNEL_VIEW_TYPE : VARIANTS_VIEW_TYPE
		};
	}

	//

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		const markup = <div>
			<h3 style={{ display: 'inline-block' }}>Probabilty to be the Best - { this.props.selectedMetric.name }</h3>
			{ this.#getChartMarkup() }
		</div>;

		return markup;
	}
	
	/**
	 * @return the markup for the chart
	 */
	#getChartMarkup() {
		const chartData = this.#getVariantsChartData();
		
		const height = chartData.length * 15;
		const theme =  ChartsTheme;
		
		const labelStyle = { ...ChartsTheme.bar.style.labels };
		labelStyle.fontSize = 3.5;

		const markup = <div style={{ padding: '25px 0 5px 0' }}>
			<VictoryChart 
				theme={ theme }
				width={ 350 }
				height={ height } 
				sortKey='i'
				sortOrder='ascending'
				domain={{ y:[ 0, 100 ]}}
				singleQuadrantDomainPadding={ false }
				domainPadding={{ x: [ 5, 5 ], y: [ 0, 2 ] }}
				padding={{ top: 0, right: 15, bottom: 0, left: 0 }}
			>
				<VictoryAxis 
					style={{ axis: { stroke: "none" }, ticks: { stroke: "transparent" } }}
					tickFormat={( datum ) => '' }
					dependentAxis={ true }/>
				<VictoryAxis 
					style={{ axis: { stroke: "none" }, ticks: { stroke: "transparent" } }} 
					tickFormat={( datum ) => {
						return `${ datum }`;
					}}
					dependentAxis={ false } 
					crossAxis={ true }
					tickLabelComponent={
						<VictoryLabel 
							dx={ 8 }
							dy={ -7 }
							textAnchor='start'
							verticalAnchor='middle'
							style={ labelStyle }/>
					}/>
				<VictoryBar
					horizontal={ true }
					barWidth={ 7 }
					style={{ data: { fill: ({ datum }) => datum.color } }}
					data={ chartData }
					labels={({ datum }) => {
						let percent = `${ Intl.NumberFormat( 'en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 } ).format( datum.y ) }%`;
						return percent;
					}}
					labelComponent={
						<VictoryLabel 
							dx={ 3 }
							style={ labelStyle }/>
					}
				/>
			</VictoryChart>	
		</div>;
		return markup;
	}
	
	/**
	 * @return data for displaying variants in the chart
	 */
	#getVariantsChartData() {
		const chartData = [];
		const experienceAnalytics = this.props.analytics.getMetricExperiencesAnalytics( this.props.selectedMetric.id );
		const { experiences } = SphereAdminSession.optimization;
		experiences.forEach( experience => {
			const expAnalytics = experienceAnalytics?.[ experience.id ];
			const y = expAnalytics?.successProbability || 0;
			chartData.push({ x: experience.name, y, color: experience.color });
		});
		chartData.sort(( a, b ) => a.y > b.y );
		return chartData; 
	}

}

export default ExperienceWinnerChart;
