/*
 * (c) Verra Technology Corporation
 */

import SphereAdminSession from '../../model/SphereAdminSession.js';
import DruidQueryRequest from './DruidQueryRequest.js';

//

/**
 * Retrieves Channel load and view events
 */
class RetrieveTopPerformersRequest extends DruidQueryRequest {

	// Static

	/**
	 * Constant used to for retrieving data for channels
	 */
	static OBJECT_TYPE_CHANNEL = 'sphere-channel-id';

	/**
	 * Constant used to for retrieving data for content
	 */
	static OBJECT_TYPE_CONTENT = 'sphere-content-id';

	//

	/**
	 * Constructs the Request
	 * @param objectType The type of object to retrieve data for, either Content or Channel
	 * @param eventType The type of event to retrieve; view, click, add to cart, etc
	 * @param startDate The start date in which to retrieve data
	 * @param endDate The end date in which to retrieve data
	 * @param timeIncrement the increment or grouping of values to return, for example, by minute, day, week, etc.
	 */
	constructor( objectType, eventType, startDate, endDate, timeIncrement ){
		super();
		this.objectType = objectType;
		this.eventType = eventType;
		this.startDate = startDate;
		this.endDate = endDate;
		this.timeIncrement = timeIncrement;
	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
   	getQuery() {
		return `SELECT
					COUNT( type ) as "count",
					type,
					"${this.objectType}" as "object-id"
				FROM Event 
				WHERE 
					"account-id" = ${SphereAdminSession.selectedAccount.id} AND 
					type = '${this.eventType}' AND 
					__time BETWEEN MILLIS_TO_TIMESTAMP(${this.startDate.getTime()}) AND MILLIS_TO_TIMESTAMP(${this.endDate.getTime()}) 
				GROUP BY "${this.objectType}", type
				ORDER BY "count" DESC`;
	}
	
	//

   	/**
	 * Handles completion of the command
	 */
	handleComplete() {
		// TODO handle errors
		this.response = this.getResponse();
		//console.info( 'RetrieveTopPerformersRequest::handleComplete', this.response );
		this.metrics = [];
		//this.response.forEach( metric => {
		for( var i = 0; i < 6; i++ ) { // force there to be a specific number even if there aren't to force the chart to align to the top 
			var metric = this.response[ i ];
			if( metric == null ) metric = { 'object-id': ''.padEnd( i ), count: 0 };
			var objectId = metric[ 'object-id' ];
			if( objectId.length > 25 ) objectId = objectId.substr( 0, 22 ) + '...';
			this.metrics.push( { x: objectId, y: metric.count } );
		}			
		//});
	}

	/**
	 * @return The event counts for the Channel
	 */
	getMetrics(){
		return this.metrics;
	}
 
}

export default RetrieveTopPerformersRequest;
