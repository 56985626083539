/*
* (c) Verra Technology Corporation
*/

import React, { Component } from 'react';
import CloseModalCommand from '../../commands/CloseModalCommand';
import AudiencesList from '../optimizations/AudiencesList';
import SphereAdminSession from '../../model/SphereAdminSession';
import CreateAudienceModal from './CreateAudienceModal';
import OpenModalCommand from '../../commands/OpenModalCommand';

//

/**
 * Modal used to select an Audience
 */
class SelectAudienceModal extends Component {

	/**
	'* Constructs the Component
	*/
	constructor( props ) {
		super( props );
		this.state = { audiencesSelected: false };
		this.audienceList = React.createRef();
	}
	
	/**
	* Renders the component
	* @see react docs
	*/
	render() {
		const emptyResultsMarkup = <div>You do not have any Audiences. <button className='link-button' onClick={ this.#handleCreateAudience.bind( this )}>Create Audience</button></div>;
		const hasAudiences = ( SphereAdminSession.audiences != null && SphereAdminSession.audiences.length > 0 );
		const selectDisabled = ( hasAudiences && this.state.audiencesSelected );
		const selectDisabledClass = ( selectDisabled ) ? '' : 'disabled';
		return (
			<div className='dialog'>
				<AudiencesList 
					ref={ this.audienceList } 
					noContent={ emptyResultsMarkup } 
					checkOnRowClick={ true }
					onRetrieved={ this.#handleAudiencesRetrieved.bind( this )}
					checkBoxChangeHandler={ this.#handleCheckBoxChecked.bind( this )}/>
				<div className='grid-cell default-100' style={{ padding: '20px 0 0 0', textAlign: 'center' }}>
					<button className={'primary-button ' + selectDisabledClass } disabled={ !selectDisabled } style={{ width: '90px' }} onClick={ this.#handleSelect.bind( this )}>Select</button>
					<button className='button control-pad-left' style={{ width: '90px' }} onClick={ this.#handleCancel.bind( this )}>Cancel</button>
				</div>
			</div>
		);
	}

	/**
	 * Handles the onRetrieved event from the AudiencesList
	 */
	#handleAudiencesRetrieved( audiences ) {
		// force a redraw
		this.setState({});
	}

	/**
	 * Handles the onRetrieved event from the AudiencesList
	 */
	#handleCheckBoxChecked() {
		const selected = this.audienceList.current.getChecked();
		this.setState({ audiencesSelected: ( selected.length > 0 )});
	}

	/**
	 * Handles the click to create an Audience
	 */
	#handleCreateAudience( e ) {
		const closeModal = new CloseModalCommand();
		closeModal.execute();

		const createAudienceModal = <CreateAudienceModal/>;
		const openModal = new OpenModalCommand( 'Create Audience', createAudienceModal, '60%', false );
		openModal.execute();
	}
	
	/**
	 * Closes the Predictive Attribute editor modal
	 */
	#handleSelect( e ) {
		this.props.selectHandler( this.audienceList.current.getChecked() );
		const closeModal = new CloseModalCommand();
		closeModal.execute();
	}

	/**
	 * Closes the Modal
	 */
	#handleCancel( e ) {
		const closeModal = new CloseModalCommand();
		closeModal.execute();
	}

}

export default SelectAudienceModal;