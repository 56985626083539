/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import SphereAdminSession from '../model/SphereAdminSession';
import ExperienceFactory from '../managers/ExperienceFactory';
import ExperienceEditor from '../components/editors/ExperienceEditor';
import SelectSiteModal from '../components/modals/SelectSiteModal';
import OpenModalCommand from '../commands/OpenModalCommand';
import SetStateCommand from '../commands/SetStateCommand';
import AdminStates from '../model/AdminStates';
import EditOptimizationCommand from '../commands/EditOptimizationCommand';

//

/**
 * The CreateExperiencePanel contains the administration UI for ceating a new experience
 */
class CreateExperiencePanel extends Component {
	
	/**
	 * Constructs the ContentPanel.
	 */
	constructor() {
		super();

		// We are still on the admin site and have not been redirected to the chosen site for the Experience
		if( window.location === window.parent.location ) {
			// console.info( 'ask user to select a site' );
			const selectSite = <SelectSiteModal selectHandler={ this.#handleSiteSelected.bind( this )} cancelHandler={ this.#handleCancelSelectSite.bind( this )}/>;
			const openModal = new OpenModalCommand( 'Select Site' , selectSite, 350, false );
			openModal.execute();
		} 
		// We are now on the site (different domain) that is assigned to the Experience. In this case, the url params
		// will have an experienceId and we'll use that to figure out where to get the Experience model object.
		else {
			this.#populateExperience();
		}
	}

	/**
	 * Populates the session Experience object, either pulling it from session storage or creating a new one
	 */
	#populateExperience() {
		const params = new URLSearchParams( SphereAdminSession.locationSearch );
		const experienceId = params.get( 'verra-experience-id' );
		let experience;
		// Check to see if there is an Experience model object in session storage. This will exist when the user
		// has loaded the site and then navigates to another page on the site while editing.
		const experienceData = window.sessionStorage.getItem( 'verra-site-mode-experience' );
		if( experienceData != null ) {
			// console.info( 'experience found in storage' );
			const experienceInStorage = JSON.parse( experienceData );
			// Ensure the Experience in storage matches the ID in the params
			if( experienceInStorage.id === experienceId ) {
				// console.info( 'experience id in url matches storage' );
				experience = experienceInStorage;
			}
		} 
		// No matching Experience object exists in session storage. Create the Experience, assigning it an ID and site
		if( experience == null ) {
			experience = ExperienceFactory.createExperience( experienceId, params.get( 'verra-site-id' ));
		}

		SphereAdminSession.experience = experience;
		SphereAdminSession.optimizationId = params.get( 'verra-optimization-id' );
		// console.info( 'SphereAdminSession.experience', SphereAdminSession.experience );
	}
	
	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		return ( SphereAdminSession.experience != null ) ? <ExperienceEditor/> : '';
	}

	//

	/**
	 * Handles a selection of a site from the Sites modal
	 */
	#handleSiteSelected( site ) {
		// console.info( 'handleSiteSelected', site );
		this.#redirectToSite( site )
	}

	/**
	 * Handles the cancelation of selecting a site
	 */
	#handleCancelSelectSite() {
		if( SphereAdminSession.optimization == null ) {
			const setState = new SetStateCommand( AdminStates.ADMIN_EXPERIENCES );
			setState.execute();
		} else {
			const editOptimization = new EditOptimizationCommand( SphereAdminSession.optimization.id );
			editOptimization.execute();
		}
	}

	/**
	 * Redirects the user to the site assigned to the Experience, loading Verra in site mode
	 */
	#redirectToSite( site ) {
		const editMode = 'verra-edit-mode=create';
		const siteId = `verra-site-id=${ site.id }`;
		const experienceId = `verra-experience-id=${ ExperienceFactory.createExperienceId() }`;
		const verraId = `verra-id=${ SphereAdminSession.token }`;
		let url = `https://${ site.url }/?${ editMode }&${ siteId }&${ experienceId }&${ verraId }`;
		if( SphereAdminSession.optimization != null ) url += `&verra-optimization-id=${ SphereAdminSession.optimization.id }`;
		// console.info( 'redirectToSite', url );
		window.location.href = url;
	}

}

//

export default CreateExperiencePanel;
