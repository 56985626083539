/*
 * (c) Verra Technology Corporation
 */

import Site from "../../model/Site.mjs";
import SphereAdminSession from "../model/SphereAdminSession";

/**
 * Factory class for creating Site model objects
 */
const SiteFactory = {

	/**
	 * Creates a new Site
	 * @return Site
	 */	
	createSite: function(){
		const site = new Site();
		site.status = 0;
		site.accountId = SphereAdminSession.selectedAccount.id;
		site.type = 2;
		site.name = '';
		site.url = '';
		site.dataLayer = 'digitalData'
		return site;
	}
	
};

export default SiteFactory;
