/*
 * (c) Verra Technology Corporation
 */

import Command from '../../commands/Command.mjs';
import AdminStates from '../model/AdminStates';
import SetStateCommand from './SetStateCommand';

/**
 * Initiates the view to create an Optimization
 */
class CreateOptimizationCommand extends Command {
	
	/**
	 * Handles execution of the command
	 */
	handleExecute() {
		const setState = new SetStateCommand( AdminStates.ADMIN_OPTIMIZATIONS_CREATE );
		setState.execute();
	}
	
}

//

export default CreateOptimizationCommand;
