/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import MetricEventTypes from '../../../model/MetricEventTypes';
import ModifiableObject from '../../../model/ModifiableObject.mjs';
import EditMetricCommand from '../../commands/EditMetricCommand';
import LocateElementCommand from '../../commands/LocateElementCommand';
import OpenModalCommand from '../../commands/OpenModalCommand';
import SetStateCommand from '../../commands/SetStateCommand';
import ValidatorCommand from '../../commands/ValidatorCommand';
import AdminStates from '../../model/AdminStates';
import ObjectStatusMap from '../../model/ObjectStatusMap';
import SphereAdminSession from '../../model/SphereAdminSession';
import SaveMetricRequest from '../../requests/metrics/SaveMetricRequest';
import Alert from '../controls/Alert';
import DropDownField from '../controls/DropDownField';
import Hint from '../controls/Hint';
import InputField from '../controls/InputField';

//

/**
 * The MetricEditor contains the UI for editing and creating a Metric
 */
class MetricEditor extends Component {
	
	/**
	* Constructs the Component.
	*/
	constructor() {
		super();
		this.state = {};
	}

	/**
	* Renders the component
	* @see react docs
	*/
	render() {
		return 	<div className='content-panel metric-editor'>
					{ !this.props.modal && this.#getHeaderMarkup() }
					{ this.#getPrimaryFieldsMarkup() }
					{ this.#getMetricsMarkup() }
				</div>;
	}
	
	
	/**
	 * @return the markup for the header ui
	 */
	#getHeaderMarkup() {
		const metric = SphereAdminSession.metric;
		const isLocked = ( metric.status === ModifiableObject.LOCKED );
		const isEditing = ( SphereAdminSession.currentState === AdminStates.ADMIN_AUDIENCES_EDIT );
		const title = ( isEditing ) ? 'Edit Metric' : 'Create Metric';
		const saveDisabled = ( metric.status === ModifiableObject.SAVED );
		const saveButtonsDisabledClass = ( saveDisabled ) ? ' disabled' : '';
		return 	<div>
					<div className='grid'>
						<div className='grid-cell default-50'>
							<h2>{ title }</h2>
							<div className='breadcrumb'>
								<a href='/metrics/' className='breadcrumb'>Metrics</a> / { metric.name }
							</div>
						</div>
						<div className='grid-cell default-50 align-right header-actions'>
							{ !isLocked &&
								<button
									className={'primary-button control-pad-left' + saveButtonsDisabledClass }
									disabled={saveDisabled} style={{width: '80px'}}
									onClick={ this.#handleSave.bind( this )}>Save</button>
							}
							<button
								className={'button control-pad-left'}
								style={{width: '80px'}}
								onClick={ this.#handleCancel.bind( this )}>Cancel</button>
						</div>
					</div>
					{ isLocked &&
						<div className='panel-cell'>The Metric is in use by a published optimization</div>
					}
				</div>;
	}

	/**
	 * @return The markup for displaying id, name, and model fields
	 */
	#getPrimaryFieldsMarkup(){
		const metric = SphereAdminSession.metric;
		const isLocked = ( metric.status === ModifiableObject.LOCKED );
		
		const nameToolTip = 'User friendly name for the Audience.';
		const status = ( metric.locked ) ? 'locked' : ObjectStatusMap[ metric.status ];
		const statusIndicatorElement = <div className={'status-indicatator ' + status}></div>;

		return <div className='grid panel-cell primary-fields'>
					<div className='grid-cell default-100'>
						{ !this.props.modal && statusIndicatorElement }
						<label>Name <Hint width='250px' content={nameToolTip}/></label>
						<InputField 
							value={ metric.name } 
							maxLength='256' 
							disabled={ isLocked } 
							onChange={( value ) => {  this.#handleFieldChanged( 'name', value ); }}/>
					</div>
				</div>;
	}

	/**
	 * @return The markup for managing Audiences
	 */
	#getMetricsMarkup() {
		const metric = SphereAdminSession.metric;
		const isLocked = ( metric.status === ModifiableObject.LOCKED );

		const engagementMetricType = MetricEventTypes.engagementTypes.find( type => type.eventId === metric.engagementEventId );
		const conversionMetricType = MetricEventTypes.conversionTypes.find( type => type.eventId === metric.conversionEventId );

		const engagementToolTip = 'The engagement event determines the event that must occur to start tracking conversion. Typically this is the presence of the experience on the page but can be changed to something like the click of a button.';
		const conversionToolTip = 'The conversion event determines the success of the Experience. Conversion is calculated as conversion events divided by engagement events.';

		return 	<div className='panel-cell'>
					<div style={{ marginBottom: 13 }}>
						<h3>Engagement Event <Hint width='250px' content={ engagementToolTip }/></h3>
					</div>
					<div style={{ display: 'flex', alignItems: 'flex-end', gap: 13, marginBottom: 32 }}>
						<div style={{ flexBasis: '19%' }}>
							<DropDownField
								width='100%'
								label='Select Engagement Metric'
								labelField='name'
								items={ MetricEventTypes.engagementTypes }
								selectedItem={ engagementMetricType }
								disabled={ isLocked }
								changeHandler={ type => this.#handleEventTypeSelected( 'engagementEventId', type ) }/>
						</div>
						{ engagementMetricType != null && 
							<div style={{ flexBasis: '19%' }}>
								<DropDownField
									width='100%'
									labelField='name'
									items={ MetricEventTypes.eventFreqencies }
									selectedIndex={ metric.engagementEventFrequency }
									disabled={ isLocked }
									changeHandler={( type ) => { this.#handleEventFrequencySelected( 'engagementEventFrequency', type.value ); }}/>
							</div>
						}
						{ engagementMetricType != null && engagementMetricType.requiresValue &&
							<div style={{ flexBasis: '60%' }}>
								<InputField
									value={ metric.engagementEventTarget }
									placeholder={ engagementMetricType.valueHint }
									maxLength='256'
									readOnly={ false }
									disabled={ isLocked }
									onChange={( value ) => { this.#handleFieldChanged( 'engagementEventTarget', value ); }}/>
							</div>
						}
						{/* { engagementMetricType != null && engagementMetricType.requiresValue &&
							<div>
								<button
									className='link-button'
									style={{ height: 20 }}
									onClick={ () => { this.#handleLocateElement( 'engagementEventId' ) }}>
									<LocationIcon size='20' color='#bcbcbc'/>
								</button>
							</div>
						} */}
					</div>
					<div style={{ marginBottom: 13 }}>
						<h3>Conversion Event <Hint width='250px' content={ conversionToolTip }/></h3>
					</div>
					<div style={{ display: 'flex', alignItems: 'flex-end', gap: 13 }}>
						<div style={{ flexBasis: '19%' }}>
							<DropDownField
								width='100%'
								label='Select Conversion Metric'
								labelField='name'
								items={ MetricEventTypes.conversionTypes }
								selectedItem={ conversionMetricType }
								disabled={ isLocked }
								changeHandler={ type => this.#handleEventTypeSelected( 'conversionEventId', type ) }/>
						</div>
						{ conversionMetricType != null &&
							<div style={{ flexBasis: '19%' }}>
								<DropDownField
									width='100%'
									labelField='name'
									items={ MetricEventTypes.eventFreqencies }
									selectedIndex={ metric.conversionEventFrequency }
									disabled={ isLocked }
									changeHandler={( type ) => { this.#handleEventFrequencySelected( 'conversionEventFrequency', type.value ); }}/>
							</div>
						}
						{ conversionMetricType != null && conversionMetricType.requiresValue &&
							<div style={{ flexBasis: '60%' }}>
								<InputField
									value={ metric.conversionEventTarget }
									placeholder={ conversionMetricType.valueHint }
									maxLength='256'
									readOnly={ false }
									disabled={ isLocked }
									onChange={( value ) => { this.#handleFieldChanged( 'conversionEventTarget', value ); }}/>
							</div>
						}
						{/* { conversionMetricType != null && conversionMetricType.hasLocateButton &&
							<div>
								<button className='link-button' style={{ height: 20 }} onClick={ this.#handleLocateElement.bind( this ) }>
									<LocationIcon size='20' color='#bcbcbc'/>
								</button>
							</div>
						} */}
					</div>
				</div>;
	}

	// Editing Event Handlers

	/**
	 * Handles changes to the input fields, invalidating the Channel object
	 */
	#handleFieldChanged( field, value ) {
		SphereAdminSession.metric[ field ] = value;
		SphereAdminSession.metric.status = ModifiableObject.MODIFIED;
		this.setState({});
	};

	/**
	 * Handles the selection of the event type
	 */
	#handleEventTypeSelected( eventProperty, type ) {
		SphereAdminSession.metric[ eventProperty ] = type.eventId;
		SphereAdminSession.metric.status = ModifiableObject.MODIFIED;
		this.setState({});
	}

	/**
	 * Handles the selection of the frequency of the event
	 */
	#handleEventFrequencySelected( eventProperty, frequency ) {
		SphereAdminSession.metric[ eventProperty ] = frequency;
		SphereAdminSession.metric.status = ModifiableObject.MODIFIED;
		this.setState({});
	}

	// Element Locating

	/**
	 * Handles the click on the locate element button
	 */
	#handleLocateElement( type ) {
		const locateElement = new LocateElementCommand( SphereAdminSession.metric.siteId );
		locateElement.execute();
		
		// const hasChanged = SphereAdminSession.metric.status === ModifiableObject.MODIFIED || SphereAdminSession.metric.status === ModifiableObject.CREATED;
		// if( hasChanged ){
		// 	const alert = <Alert content='You have have unsaved changes. Would you like to save before continuing?' okHandler={ this.#handleSaveThenLocateConfirm.bind( this ) }/>;
		// 	const openModal = new OpenModalCommand( 'Would you like to save first?', alert, '500px', true );
		// 	openModal.execute();
		// } else {
		// 	console.info( 'locate' );
		// }
	}

	/**
	 * Handles the click to add content
	 */
	#handleSaveThenLocateConfirm() {
		// this.props.saveOptimizationHandler();
	}

	// Save, Cancel

	/**
	 * Handles a click on the save button, saves the Channel 
	 */
	#handleSave() {
		const fields = { 
			id: ValidatorCommand.isNotNullOrEmpty, 
			name: ValidatorCommand.isNotNullOrEmpty,
			engagementEventId: ValidatorCommand.isNotNullOrEmpty,
			engagementEventFrequency: ValidatorCommand.isNotNullOrEmpty,
			conversionEventId: ValidatorCommand.isNotNullOrEmpty,
			conversionEventFrequency: ValidatorCommand.isNotNullOrEmpty,
		};

		const validateSite = new ValidatorCommand( SphereAdminSession.metric, fields );
		const isValid = validateSite.execute();

		if( isValid ){
			SphereAdminSession.loading = true;
			const saveAudience = new SaveMetricRequest( SphereAdminSession.metric );
			saveAudience.execute(( command ) => { this.#handleSaveComplete( command ); });
		} else {
			
			const invalidFields = validateSite.getInvalidFields();
			const invalidFieldsElements = [];
	
			var i = 0;
			invalidFields.forEach( field => {
				invalidFieldsElements.push( <li key={i++}>{field}</li> );
			});
	
			const content = <div className='alert'>
				The Metric cannot be saved. The following fields are invalid or incomplete:
				<ul>{ invalidFieldsElements }</ul>
			</div>;
	
			const openModal = new OpenModalCommand( 'Invalid Metric', content, '500px', true );
			openModal.execute();
		}
	};

	/**
	 * Handles completion of the save channel reques
	 */
	#handleSaveComplete() {
		SphereAdminSession.loading = false;
		if( !this.props.modal ){
			if( SphereAdminSession.currentState === AdminStates.ADMIN_METRICS_CREATE ) {
				const editMetric = new EditMetricCommand( SphereAdminSession.metric.id );
				editMetric.execute();
			} else {
				this.setState({}); // redraw
			}
		}

		if( this.props.saveHandler != null ) this.props.saveHandler( SphereAdminSession.metric );
	}

	/**
	 * Handles a click on the cancel button
	 */
	#handleCancel() {
		const hasChanged = SphereAdminSession.metric.status === ModifiableObject.MODIFIED || SphereAdminSession.metric.status === ModifiableObject.CREATED;
		if( hasChanged ){
			const alert = <Alert content='You have unsaved changes, are you sure you want to exit?' okHandler={ this.#handleCancelConfirm.bind( this ) }/>;
			const openModal = new OpenModalCommand( 'Are you sure?', alert, '500px', true );
			openModal.execute();
		} else {
			const setState = new SetStateCommand( AdminStates.ADMIN_AUDIENCES );
			setState.execute();
		}
	}

	/**
	 * Handles a confirmation to cancel changes
	 */
	#handleCancelConfirm() {
		const setState = new SetStateCommand( AdminStates.ADMIN_METRICS );
		setState.execute();
	}

	// Public

	/**
	 * Saves the Audience
	 */
	save() {
		this.#handleSave();
	}

}

//

export default MetricEditor;
