/*
 * (c) Verra Technology Corporation
 */

import ServiceRequestCommand from '../ServiceRequestCommand';
import SphereAdminServices from '../../model/SphereAdminServices';

/**
 * Request Command used to perform Druid queries
 * TODO: add accountId filters
 */
class DruidQueryRequest extends ServiceRequestCommand {

	/**
	 * Construcs the Request
	 * @param query The Druid query
	 */
	constructor( query ){
		super();
		this.query = query;
	}

	/**
	 * Template method @see ServiceRequestCommand
	 */
	getBaseUrl() {
		return SphereAdminServices.DRUID_BASE_URL;
	}
	
	/**
	 * Template method @see ServiceRequestCommand
	 */
   	getService() {
		return '/druid/v2/sql/';
	}

	/**
	 * Template method @see ServiceRequestCommand
	 */
	requiresAuth() {
		return false;
	}

	/**
	 * Template method @see ServiceRequestCommand
	 */
	getParams() {
		return { query: this.getQuery() };
	}

	/**
	 * Template method
	 */
	getQuery() {
		return this.query;
	}

    /**
	 * Handles completion of the command
	 */
	handleComplete() {
		this.response = this.getResponse();
	}
 
}

export default DruidQueryRequest;
