/*
 * (c) Verra Technology Corporation
 */

import ServiceRequestCommand from './ServiceRequestCommand';
import SphereAdminSession from '../model/SphereAdminSession';
import SphereAdminServices from '../model/SphereAdminServices';

/**
 * Deletes items from the DB, returns any items that may be in use
 */
class DeleteItemsRequest extends ServiceRequestCommand {

	/**
	 * Tracks any of the items that are in use can could not be delete
	 */
	#itemsInUse;

	//

	/**
	 * Constructs the Request
	 * @param itemIds An array of item IDs to delete
	 * @param deleteEndPoint The services end point used for deletion
	 */
	constructor( itemIds, deleteEndPoint ){
		super();
		this.itemIds = itemIds;
		this.deleteEndPoint = deleteEndPoint;
	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
    getService() {
		return this.deleteEndPoint;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		return { accountId: SphereAdminSession.selectedAccount.id, itemIds: this.itemIds };
	}

   /**
	* Handles completion of the command
	*/
	handleComplete() {
		const response = this.getResponse();
		// console.info( 'DeleteItemsRequest::handleComplete', response );
		this.#itemsInUse = ( response.success ) ? response.result : [];
	}
 
	//

	/**
	 * @return Any Content that could not be deleted because it's in use by a Channel
	 */
	getItemsInUse(){
		return this.#itemsInUse;
	}

}

export default DeleteItemsRequest;
