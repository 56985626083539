/*
* (c) Verra Technology Corporation
*/

import CodeEditor from '@uiw/react-textarea-code-editor';
import React, { Component } from 'react';
import CloseModalCommand from '../../commands/CloseModalCommand';
import OpenModalCommand from '../../commands/OpenModalCommand';
import ValidatorCommand from '../../commands/ValidatorCommand';
import CheckIcon from '../../icons/CheckIcon';
import CopyIcon from '../../icons/CopyIcon';
import SiteFactory from '../../managers/SiteFactory';
import SphereAdminSession from '../../model/SphereAdminSession';
import SaveSiteRequest from '../../requests/sites/SaveSiteRequest';
import Alert from '../controls/Alert';
import Hint from '../controls/Hint';
import InputField from '../controls/InputField';

//

const addToCartEventCode = `verra.trackEvent({
    type: 'add-to-cart',
    verra: {
        event: {
            'product-id': productId,
            'product-qty': qty
        }
    }
});`;

const placeOrderEventCode = `verra.trackEvent({
    type: 'place-order',
    verra: {
        event: {
            'subtotal': subtotal,
            'total': total
        }
    }
});`;

const customEventCode = `verra.trackEvent({
    type: 'name-of-custom-event',
    verra: {
        event: {
            custom event data here
        }
    }
});`;

//

/**
 * Provides the onboarding experience
 */
class OnboardingModal extends Component {

	/**
	 * Tracks the step for onboarding
	 */
	#steps;

	//

	/**
	 * Constructs the Component
	 */
	constructor( props ) {
		super( props );

		const hasSites = ( SphereAdminSession.sites.length > 0 );
		const siteUrl = ( hasSites ) ? SphereAdminSession.sites[ 0 ].url : '';
		const siteName = ( hasSites ) ? SphereAdminSession.sites[ 0 ].name : '';

		this.#steps = [
			{ 
				label: '1. Sign Up'
			},
			{ 
				label: '2. Site', 
				complete: hasSites, 
				markup: this.#getSiteStepMarkup.bind( this ), 
				save: this.#saveSiteStep.bind( this ), 
				data: { url: siteUrl, name: siteName } 
			},
			{ 
				label: '3. Tag', 
				complete: false, 
				markup: this.#getTagStepMarkup.bind( this ), 
				save: this.#saveTagStep.bind( this ),
			},
			{ 
				label: '4. Events', 
				complete: false,
				markup: this.#getEventTrackingMarkup.bind( this ),
				save: this.#saveEventsStep.bind( this )
			},
			{ 
				markup: this.#getFinalStepMarkup.bind( this ),
			}
		];

		const currentIndex = ( !hasSites || SphereAdminSession.hasOnboarded ) ? 1 : 2;
		const currentStep = this.#steps[ currentIndex ];

		this.state = { currentIndex, currentStep };
	}
	
	/**
	* Renders the component
	* @see react docs
	*/
	render() {
		const stepsList = [];
		this.#steps.forEach(( step, index ) => {
			if( index < this.#steps.length - 1 ) {
				const className = ( step === this.state.currentStep ) ? 'current' : ( step.complete ) ? 'complete' : '';
				const checkColor = ( step === this.state.currentStep ) ? '#000000' : '#efefef';
				stepsList.push( 
					<li className={ className } key={ index }>
						{ step.complete && <CheckIcon size={ 22 } color={ checkColor }/> }
						{ index <= this.state.currentIndex && index !== 0 && <button onClick={ () => this.#goToStep( index ) }>{ step.label }</button> }
						{ ( index > this.state.currentIndex || index === 0 ) && <span>{ step.label }</span> }
					</li>
				);
			}
		});

		const finalStep = ( this.state.currentIndex === this.#steps.length - 1 );
		
		return (
			<div className='dialog onboarding-modal' style={{ padding: 50 }}>
				<h2>Welcome to Verra! Let's get started!</h2>
				<ol className='steps'>
					{ stepsList }
				</ol>
				{ this.state.currentStep.markup() }
				{ !finalStep && 
					<div className='controls'>
						<button className='primary-button' style={{ width: '90px' }} onClick={ this.#handleNext.bind( this )}>Next</button>
						<button className='link-button' onClick={ this.#handleClose.bind( this ) }>I'll do this later</button>
					</div>
				}
				{ finalStep && 
					<div className='controls'>
						<button className='primary-button' style={{ width: '90px' }} onClick={ this.#handleClose.bind( this )}>Close</button>
					</div>
				}
			</div>
		);
	}

	// Steps Markup

	/**
	 * @return markup for displaying the site setup step
	 */
	#getSiteStepMarkup() {
		const urlToolTip = 'The URL of the site, for example: mysite.com. Required';
		const nameToolTip = 'An optional, user friendly name for the site';
		const hasSite = ( SphereAdminSession.sites.length > 0 );
		return (
			<div>
				<p>There's a few things we need to to do before you can start optimizting. Let's start by setting up your website.</p>
				{ hasSite && 
					<em style={{ display: 'block', margin: '0 0 35px 0', fontWeight: 600, color: '#ffffff' }}>This step is complete, you can edit your site from the Sites panel under Settings</em>
				}
				<div className='field'>
					<label>* Web Site URL <Hint width='250px' content={ urlToolTip }/></label>
					<InputField 
						maxLength='256' 
						value={ this.state.currentStep.data.url } 
						disabled={ hasSite }
						onChange={( value ) => { this.#handleFieldChanged( 'url', value ); }}/>
				</div>
				<div className='field'>
					<label>Name of Site <Hint width='250px' content={ nameToolTip }/></label>
					<InputField 
						maxLength='256' 
						value={ this.state.currentStep.data.name} 
						disabled={ hasSite }
						onChange={( value ) => { this.#handleFieldChanged( 'name', value ); }} />
				</div>
			</div>
		);
	}

	/**
	 * @return markup for displaying the JS include setup step
	 */
	#getTagStepMarkup() {
		// since this is onboarding we assume we're only working with a single site. Technically, there could be more than 
		// one but it is unlikely
		const site = SphereAdminSession.sites[ 0 ];
		const tag = `<link rel="preconnect" href="https://client-services.verra.ai" crossorigin>\n<script>window.VerraClientConfig={clientId:'${ site.clientId }'};</script>\n<script src="https://client-services.verra.ai/verra.js"></script>`;
		return (
			<div>
				<p>Verra requires an integration with your website using an HTML tag. This can be accomplished by using a tag management system or through direct modification of your website's code.</p>
				<h3>HTML Tag</h3>
				<div style={{ position: 'relative', margin: '0 0 30px 0' }}>
					<CodeEditor
						value={ tag }
						language='html'
						data-color-mode='dark'
						padding={ 15 }
						wrap='off'
						style={{
							fontSize: 12,
							backgroundColor: '#1b1b1b',
							fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
						}}
					/>
					<button className='copy-button' onClick={ () => navigator.clipboard.writeText( tag )}>
						<CopyIcon size={ 18 } color='#ffffff'/>
					</button>
				</div>
				<h3>Learn How</h3>
				<div style={{ display: 'flex', justifyContent: 'space-evenly', margin: '0 0 30px 0' }}>
					<a className='link-button help-button' href='https://docs.verra.ai/integration/tag-manager' target='_blank'>Tag Management Integration</a>
					<a className='link-button help-button' href='https://docs.verra.ai/integration/code' target='_blank'>Direct Code Integration</a>
				</div>
			</div>
		);
	}

	/**
	 * @return markup for displaying the JS include setup step
	 */
	#getEventTrackingMarkup() {
		const tabs = [
			{ 
				label: 'Add to Cart', 
				content: <div>
							<CodeEditor
								value={ addToCartEventCode }
								language='javascript'
								data-color-mode='dark'
								padding={ 15 }
								wrap='off'
								style={{
									fontSize: 12,
									backgroundColor: '#1b1b1b',
									fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
								}}
							/>
						</div>
			},
			{ 
				label: 'Purchase',
				content:  <div>
							<CodeEditor
								value={ placeOrderEventCode }
								language='javascript'
								data-color-mode='dark'
								padding={ 15 }
								wrap='off'
								style={{
									fontSize: 12,
									backgroundColor: '#1b1b1b',
									fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
								}}
							/>
						</div>
			},
			{ 
				label: 'Custom',
				content:  <div>
							<CodeEditor
								value={ customEventCode }
								language='javascript'
								data-color-mode='dark'
								padding={ 15 }
								wrap='off'
								style={{
									fontSize: 12,
									backgroundColor: '#1b1b1b',
									fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
								}}
							/>
						</div>
			}
		];
		return (
			<div>
				<p>Almost there! To get the most out of Verra let's setup e-commerce events. This is done by adding a small bit of JavaScript to your site. Custom events can also be added to optimize on additional metrics.</p>
				{/* <h3>Event Tracking</h3>
				<p>The following are generic examples of event tracking with Verra. Refer to the documentation for more details and platform specific integrations.</p>
				<TabComponent tabs={ tabs } style={{ margin: '0 0 30px 0' }}/> */}
				<h3>Learn How</h3>
				<div style={{ display: 'flex', justifyContent: 'space-evenly', margin: '0 0 30px 0' }}>
					<a className='link-button help-button' href='https://docs.verra.ai/analytics/e-commerce-events' target='_blank'>E-commerce Event Tracking</a>
					<a className='link-button help-button' href='https://docs.verra.ai/analytics/custom-events' target='_blank'>Custom Event Tracking</a>
				</div>
			</div>
		)
	}

	/**
	 * @return the markup for the final step in the wizare
	 */
	#getFinalStepMarkup() {
		return (
			<div>
				<p>Hooray! You completed the initial setup of Verra.</p>
				<h3>Next Steps</h3>
				<div style={{ display: 'flex', justifyContent: 'space-evenly', margin: '0 0 30px 0' }}>
					<a className='link-button help-button' href='https://docs.verra.ai/analytics/e-commerce-events' target='_blank'>Create Your First Optimization</a>
					<a className='link-button help-button' href='https://docs.verra.ai/analytics/custom-events' target='_blank'>Review Documentation</a>
				</div>
			</div>
		);
	}

	// Navigation

	/**
	 * Handles a click on the next button, saves the current step.
	 */
	#handleNext() {
		this.state.currentStep.save();
	}

	/**
	 * Navigates the user to the next step.
	 */
	#nextStep() {
		this.state.currentIndex++;
		this.state.currentStep = this.#steps[ this.state.currentIndex ];
		this.setState({});
	}

	/**
	 * Handles a click on a specific step, navigates the user to the step
	 */
	#goToStep( index ) {
		this.setState({ currentIndex: index, currentStep: this.#steps[ index ] });
	}

	/**
	 * Completes the site step
	 */
	#saveSiteStep() {

		// if the site is already saved we can skip all of this
		const hasSite = ( SphereAdminSession.sites.length > 0 );

		if( hasSite ) {
			this.#nextStep();
		} else {
			let url = this.state.currentStep.data.url;
			if( url != null ) url = url.replace( 'https://', '' ).replace( 'http://', '' );

			const fields = { url: ValidatorCommand.isNotNullOrEmpty };
			const validateSite = new ValidatorCommand( { url }, fields );
			const isValid = validateSite.execute();

			let openModal;
			let alert;

			if( isValid ) {

				let name = this.state.currentStep.data.name;
				if( name == null || name === '' ) name = url;

				const site = SiteFactory.createSite()
				site.url = url;
				site.name = name;

				// sanity check, ensure a site with this URL does not already exist
				let hasUniqueUrl = true;
				SphereAdminSession.sites.forEach( existingSite => {
					if( site.url === existingSite.url ) hasUniqueUrl = false;
				});

				if( hasUniqueUrl ){
					SphereAdminSession.loading = true;
					const saveSite = new SaveSiteRequest( site );
					saveSite.execute( this.#handleSaveSiteComplete.bind( this ));
				} else {
					alert = <Alert content='Hmm, looks like a site with this URL already exists.' showCancelBtn={ false }/>;
					openModal = new OpenModalCommand( 'Invalid Site', alert, '500px', true );
					openModal.execute();
				}

			} else {
				const invalidFields = validateSite.getInvalidFields();
				const invalidFieldsElements = [];
				
				invalidFields.forEach(( field, index ) => {
					invalidFieldsElements.push( <li key={ index }>{ field }</li> );
				});
		
				const content = <div>
					Oops, looks like there's an issue, the following fields are required:
					<ul>{ invalidFieldsElements }</ul>
				</div>;
		
				alert = <Alert content={ content } showCancelBtn={ false }/>;
				openModal = new OpenModalCommand( 'Invalid Site', alert, '500px', true );
				openModal.execute();
			}

		}
	}

	/**
	 * Handles completion of the save channel reques
	 */
	#handleSaveSiteComplete(){
		SphereAdminSession.loading = false;
		this.#nextStep();
	}

	/**
	 * Saves the tag integration step
	 */
	#saveTagStep() {
		this.state.currentStep.complete = true;
		this.#nextStep();
	}

	/**
	 * Saves the event integration step
	 */
	#saveEventsStep() {
		SphereAdminSession.hasOnboarded = true;
		this.state.currentStep.complete = true;
		this.#nextStep();
	}

	/**
	 * Handles a close of the modal
	 */
	#handleClose() {
		SphereAdminSession.hasOnboarded = true;
		const closeModal = new CloseModalCommand();
		closeModal.execute();
	}

	//

	/**
	 * Handles changes to the input fields, invalidating the Channel object
	 */
	#handleFieldChanged( field, value ) {
		this.state.currentStep.complete = true;
		this.state.currentStep.data[ field ] = value;
	};

}

export default OnboardingModal;