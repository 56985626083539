/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';

import InitializeAdminCommand from './commands/InitializeAdminCommand';
import AdminStates from './model/AdminStates';
import SphereAdminSession from './model/SphereAdminSession';

// Screens

import SetStateCommand from './commands/SetStateCommand';
import LoadingModal from './components/modals/LoadingModal';
import VerifyAuthenticationRequest from './requests/account/VerifyAuthenticationRequest';
import Admin from './screens/Admin';
import IFrame from './screens/IFrame';
import SignIn from './screens/SignIn';

// Private

/**
 * @return The sign in component for rendering. Called when the user has not been authenticated.
 */
var getSignIn = function() {
	return ( <SignIn/> );
};

/**
 * @return The admin component
 */
var getAdmin = function() {
	// for some reason passing this.state.modals directly to the UI doesn't work. The order is weird
	// can can't seem to be updated.
	const modalsStack = [];
	this.state.modals.forEach( modal => {
		modalsStack.push( modal );
	});

	const loader = ( this.state.loading ) ? <LoadingModal/> : '';
	return ( 
		<div>
			<Admin/> 
			{ modalsStack }
			{ loader }
		</div>
	);
};

/**
 * @return The iframe component
 */
var getIFrame = function() {
	var modal = ( this.state.modals.length > 0 ) ? this.state.modals[ this.state.modals.length - 1 ] : '';
	var loader = ( this.state.loading ) ? <LoadingModal/> : '';
	return ( 
		<div>
			<IFrame/> 
			{ modal }
			{ loader }
		</div>
	);
};

//

/**
 * Application class for the Sphere Administration suite
 * TODO: when the session has timed out automatically put the user back on the sign in screen
 */
class SphereAdmin extends Component {
	
	/**
	 * Constructs the component
	 */
	constructor() {
		super(); 

		//var skipAuth = window.location.href.toString().includes( 'skipauth' ); // TODO: temp, remove
		const resetPassword = ( window.location.pathname.indexOf( '/reset-password/' ) === 0 );
		const register = ( window.location.pathname.indexOf( '/setup-account/' ) === 0 );

		let currentState = null;
		if( resetPassword ){
			currentState = AdminStates.RESET_PASSWORD;
		} else if( register ){
			currentState = AdminStates.REGISTERING;
		}

		this.state = { currentState: currentState, modals: [] };
		SphereAdminSession.adminInstance = this;

		window.addEventListener( 'popstate', ( event ) => {
			const setState = new SetStateCommand( event.state.state, event.state.parameters, true );
			setState.execute();
		});
	}

	/**
	 * Renders the component
	 */
	render() {
		
		const currentState = this.state.currentState;
		let jsx = '';

		// console.info( 'SphereAdmin::render', this.state );

		if( currentState === null || currentState === AdminStates.AUTH_NOT_VERIFIED ) {
			// TODO: consider a verify auth Command -- Commands should be the heart of all business logic, while a Request
			// is a type of Command, it would be cleaner and create consistency to route all business logic through first 
			// level Commands, the Command can then initiate Requests, udpate state, etc. -- Requests probably should not 
			// be updating state as the VerifyAuthenticationRequest does in this case
			var verifyAuth = new VerifyAuthenticationRequest();
			verifyAuth.execute();
		} else if ( currentState === AdminStates.NOT_AUTHENTICATED || currentState === AdminStates.AUTHENTICATION_FAILED ) {
			// TODO: refactor all (or at least pwd reset) of the sign in states to be different screens
			jsx = getSignIn();
		} else if ( currentState === AdminStates.REGISTERING || currentState === AdminStates.REGISTRATION_FAILED ) {
			jsx = getSignIn();
		} else if ( currentState === AdminStates.RESET_PASSWORD ) {
			jsx = getSignIn();
		} else if ( currentState === AdminStates.AUTHENTICATED ) {
			const initAdmin = new InitializeAdminCommand();
			initAdmin.execute();
		} else if ( currentState === AdminStates.IFRAME ){
			jsx = getIFrame.apply( this );
		} else if( currentState != null ) {
			jsx = getAdmin.apply( this );
		}

		return jsx;
	}
	
}

//

export default SphereAdmin;
