import React from 'react';

var LargePencilIcon = function( props ) {
	return (
		<svg height="52" width="52" viewBox="0 0 52 52">
			<g strokeLinecap="square" strokeWidth="2" fill="none" stroke="#ffffff" strokeLinejoin="miter" className="nc-icon-wrapper" strokeMiterlimit="10">
				<line data-cap="butt" x1="6" y1="32" x2="16" y2="42" strokeLinecap="butt" stroke="#ffffff"></line> 
				<line data-cap="butt" x1="11" y1="37" x2="31" y2="17" strokeLinecap="butt" stroke="#ffffff"></line> 
				<line x1="30" y1="8" x2="40" y2="18" stroke="#ffffff"></line> <line x1="26" y1="12" x2="36" y2="22" stroke="#ffffff"></line>
				<path d="M16,42L4,44l2-12L33.2,4.8 c1.6-1.6,4.1-1.6,5.7,0l4.3,4.3c1.6,1.6,1.6,4.1,0,5.7L16,42z"></path>
			</g>
		</svg>
	);
};

export default LargePencilIcon;

