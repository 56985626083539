/*
 * (c) Verra Technology Corporation
 */

import Metric from "../../model/Metric";
import SphereAdminSession from "../model/SphereAdminSession";

/**
 * Factory class for creating Metric model objects
 */
const MetricFactory = {

	/**
	 * Creates a new Metric
	 * @return Metric
	 */	
	createMetric: function(){
		const metric = new Metric();
		metric.accountId = SphereAdminSession.selectedAccount.id;
		metric.id = window.crypto.randomUUID();
		return metric;
	}
	
};

export default MetricFactory;
