/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import MetricEventTypes from '../../model/MetricEventTypes';
import Optimization from '../../model/Optimization';
import OptimizationModelTypes from '../../model/OptimizationModelTypes';
import FormatUtil from '../../util/FormatUtil';
import OpenModalCommand from '../commands/OpenModalCommand';
import SetStateCommand from '../commands/SetStateCommand';
import ExperienceWinnerChart from '../components/charts/ExperienceWinnerChart';
import Alert from '../components/controls/Alert';
import DropDownField from '../components/controls/DropDownField';
import Hint from '../components/controls/Hint';
import Loader from '../components/controls/Loader';
import AdminStates from '../model/AdminStates';
import ChartTimeValues from '../model/ChartTimeValues';
import OptimizationAnalytics from '../model/OptimizationAnalytics';
import SphereAdminSession from '../model/SphereAdminSession';
import CompleteOptimizationRequest from '../requests/optimizations/CompleteOptimizationRequest';
import RetrieveOptimizationConversionAnalyticsRequest from '../requests/optimizations/RetrieveOptimizationConversionAnalyticsRequest';
import RetrieveOptimizationEngagementAnalyticsRequest from '../requests/optimizations/RetrieveOptimizationEngagementAnalyticsRequest';
import RetrieveOptimizationImpressionAnalyticsRequest from '../requests/optimizations/RetrieveOptimizationImpressionAnalyticsRequest';
import RetrieveOptimizationRequest from '../requests/optimizations/RetrieveOptimizationRequest';

//

/**
 * The currently selected time period
 */
let selectedTimePeriod = ChartTimeValues.availableRanges[ 4 ];

/**
 * Available revenue potential types
 */
// const revenuePotentialTypes = [
// 	{ label: 'Revenue Potential (CVR)' },
// 	{ label: 'Revenue Potential Delta (CVR)' },
// 	{ label: 'Revenue Potential (CVR/AOV)' },
// 	{ label: 'Revenue Potential Delta (CVR/AOV)' },
// 	{ label: 'Revenue Potential (AOV)' },
// 	{ label: 'Revenue Potential Delta (AOV)' }
// ];

/** 
 * The colors used to represent the experiences
 */
const colors = [
	/*
	'#09608f',
	'#4964a5',
	'#8063af',
	'#b45da9',
	'#df5795',
	'#fc5c75',
	'#ff714f',
	'#ff911f',
	*/
	'#65c1b7',
	'#5ea6c5',
	'#5679c9',
	'#5c4fcd',
	'#8d47d1',
	'#c83fd6',
	'#da37a7',
	'#df2f61',
];

const otherColor = '#999999';

//

/**
 * Displays the analytics for a published Optimization
 */
class ViewOptimizationPanel extends Component {

	/**
	 * Constructs the Component.
	 */
	constructor() {
		super();

		this.state = {
			analytics: null,
			loadingEvents: true,
			loadingConversionMetrics: true,
			selectedMetric: null
		};

		const optimizationId = SphereAdminSession.stateParameters.optimizationId;

		SphereAdminSession.loading = true;
		const retrieveOptimization = new RetrieveOptimizationRequest( optimizationId ); // don't really need the full optimiztion here do we?
		retrieveOptimization.execute( this.#handleOptimizationRetrieved.bind( this ));
	}

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {	
		const optimization = SphereAdminSession.optimization;
		return ( optimization != null && this.state.analytics != null ) ? this.#getOptimizationViewMarkup() : '';
	}

	/**
	 * The markup for the Optimization view
	 */
	#getOptimizationViewMarkup() {
		const optimization = SphereAdminSession.optimization;
		return 	<div className='content-panel optimization-analytics'>
					<div className='pad-cell-bottom' style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
						<div>
							<h2>Optimization</h2>
							<div className='breadcrumb'>
								<a href='/optimizations/' className='breadcrumb'>Optimizations</a> / { optimization.name }
							</div>
						</div>
						{ optimization.status !== Optimization.COMPLETE &&
							<button className={ 'button control-pad-left' } style={{ width: '155px' }} onClick={ this.#completeOptimization.bind( this )}>Complete Test</button>
						}
					</div>
					{ this.#getAnalyticsMarkup() }
				</div>;
	}

	/**
	 * @return the markup for displaying Channel analytics
	 */
	#getAnalyticsMarkup() {
		const metrics = SphereAdminSession.optimization.metrics;
		return 	<div>
					<div ref={ this.metricsContainerRef } className='performance-metrics'>
						<div className='align-right'>
							{ metrics.length > 1 &&
								<DropDownField
									className='padless' 
									style={{ display: 'inline-block' }}
									itemsWidth='250px'
									hideBackground={ true }
									labelAlignRight={ true }
									alignRight={ true }
									items={ metrics }
									labelField='name'
									selectedItem={ this.state.selectedMetric } 
									changeHandler={ this.#handlMetricSelected.bind( this )}/>
							}
							<DropDownField
								className='padless pad-cell-left' 
								style={{ display: 'inline-block' }}
								itemsWidth='150px' 
								hideBackground={ true } 
								labelAlignRight={ true } 
								alignRight={ true }
								items={ ChartTimeValues.availableRanges }
								labelField='label'
								selectedItem={ selectedTimePeriod }
								changeHandler={this.#handleDateRangeChange.bind( this )}/>
						</div>
						<div style={{ display: 'flex', alignItems: 'stretch', gap: 13 }}>
							{ this.#getImpressionsPanelMarkup() }
							{ this.#getMetricPanelMarkup() }
							{ this.#getConversionMarkup() }
						</div>
						{ !this.state.loadingConversionMetrics && 
							<div>
								{ this.#getVariantWinnerMarkup() }
								{ this.#getMetricDetailsMarkup() }
								{ this.state.selectedMetric.conversionEventId === 'place-order' && this.#getOrderDetailsMarkup() } 
							</div>
						}
					</div>
				</div>;	
	}

	/**
	 * @return the markup for the impressions metrics
	 */
	#getImpressionsPanelMarkup() {
		let impressionsPanel;
		if( this.state.loadingEvents ){
			impressionsPanel = <div className='panel-cell info-panel loader'>
				<Loader/>
			</div>;
		} else {
			const views = this.#getImpressionAnalytics( 'page-load', 0 );
			const sessions = this.#getImpressionAnalytics( 'page-load', 1 );
			impressionsPanel = <div className='panel-cell info-panel'>
				<h3>Impressions</h3>
				<div className='info-block' style={{ color: 'rgb(217, 195, 255)' }}>
					<span className='info-stat'>{ FormatUtil.roundMetric( sessions )}</span>
					<span className='info-stat-label'> Sessions</span>
					<p>The Optimization was present in { sessions } sessions over the { selectedTimePeriod.label.toLowerCase() }</p>
				</div>
				<div className='info-block' style={{ color: 'rgb(172, 123, 255)' }}>
					<span className='info-detail'>{ FormatUtil.roundMetric( views )}</span>
					<span className='info-stat-label'> Views</span>
					<p>The Optimization has been viewed { views } times over the { selectedTimePeriod.label.toLowerCase() }</p>
				</div>
			</div>;
		}
		return impressionsPanel;
	}

	/**
	 * @return the markup for the impressions metrics
	 */
	#getMetricPanelMarkup() {
		let metricPanel;
		if( this.state.loadingConversionMetrics ){
			metricPanel = <div className='panel-cell info-panel loader'>
				<Loader/>
			</div>;
		} else {
			const engagementEventName = this.#getEngagementEventName();
			const conversionEventName = this.#getConversionEventName();
			const selectedMetric = this.state.selectedMetric;
			const engagementsCount = this.#getImpressionAnalytics( selectedMetric.engagementEventId, selectedMetric.engagementEventFrequency );
			const metricCount = FormatUtil.roundMetric( this.#getConversionAnalytics( 'count' ));
			metricPanel = <div className='panel-cell info-panel'>
				<div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
					<h3>Metric</h3>
				</div>
				<div className='info-block' style={{ color: '#F6C7AE' }}>
					<span className='info-stat'>{ metricCount }</span>
					<span className='info-stat-label'>{ conversionEventName }</span>
					<p>{ conversionEventName } events have occurred over the { selectedTimePeriod.label.toLowerCase() }</p>
				</div>
				<div className='info-block' style={{ color: 'rgb(255, 143, 87)' }}>
					<span className='info-detail'>{ FormatUtil.roundMetric( engagementsCount )}</span>
					<span className='info-stat-label'>{ engagementEventName }</span>
					<p>{ engagementEventName } events have occurred over the { selectedTimePeriod.label.toLowerCase() }</p>
				</div>
			</div>;
		}
		return metricPanel;
	}

	/**
	 * @return the markup displaying details of the optimization
	 */
	#getConversionMarkup() {
		let conversionPanel;
		if( this.state.loadingConversionMetrics ){
			conversionPanel = <div className='panel-cell info-panel loader'>
				<Loader/>
			</div>;
		} else {
			const selectedMetric = this.state.selectedMetric;
			const cvr = FormatUtil.roundMetric( this.#getConversionAnalytics( 'cvr' ) * 100 );

			const engagementEventType = MetricEventTypes.engagementTypes.find( type => type.eventId === selectedMetric.engagementEventId );
			const engagementEventName = ( engagementEventType != null ) ? engagementEventType.name : selectedMetric.engagementEventId;
			
			const conversionEventType = MetricEventTypes.conversionTypes.find( type => type.eventId === selectedMetric.conversionEventId );
			const conversionEventName = ( conversionEventType != null ) ? conversionEventType.name : selectedMetric.conversionEventId;

			const optimization = SphereAdminSession.optimization;
			const published = new Date( optimization.publishDate );
			const days =  Math.round(( Date.now() - optimization.publishDate ) / ( 1000 * 60 * 60 * 24 ));

			conversionPanel = <div className='panel-cell info-panel'>
				<h3>Overall</h3>
				<div className='info-block' style={{ color: 'rgb(172, 245, 225)' }}>
					<span className='info-stat'>{ cvr }%</span>
					<span className='info-stat-label'>Conversion Rate</span>
					<p>{ engagementEventName } to { conversionEventName } conversion rate</p>
				</div>
				<div className='info-block' style={{ color: 'rgb(63, 187, 146)' }}>
					<span className='info-detail'>{ days }</span>
					<span className='info-stat-label'> Days Active</span>
					<p>The Optimization was published on { published.toLocaleDateString() }</p>
				</div>
				
			</div>;
		}
		return conversionPanel;
	}

	/**
	 * @return the markup for the charts
	 */
	#getVariantWinnerMarkup(){
		return <div className='panel-cell'>
			<ExperienceWinnerChart
				analytics={ this.state.analytics } 
				selectedMetric={ this.state.selectedMetric }/>
		</div>;
	}

	/**
	 * @return the markup for display details of a metric
	 */
	#getMetricDetailsMarkup() {
		const rowsMarkup = [];
		const experienceAnalytics = this.state.analytics.getMetricExperiencesAnalytics( this.state.selectedMetric.id );
		const selectedMetric = this.state.selectedMetric;
		const { experiences } = SphereAdminSession.optimization;

		// Enagement Event Name, Enagement Event Frequency, Conversion Event Name, Conversion Event Frequency,
		// Experience Name, Enagement Event Count, Conversion Event Count, CVR

		// Object.keys( experienceAnalytics ).forEach(( key, index ) => {
		experiences.forEach(( experience, index ) => {
			const enagementCount = this.state.analytics.getExperienceEngagementEventMetric( selectedMetric.engagementEventId, selectedMetric.engagementEventFrequency, experience.id );
			const expAnalytics = experienceAnalytics?.[ experience.id ];
			const conversionCount = ( expAnalytics != null ) ? expAnalytics.count : 0;
			const cvr = ( expAnalytics != null ) ? expAnalytics.cvr : 0;
			rowsMarkup.push( <div key={ index } className='details-detail details-row' >
				<div className='info-panel' style={{ color: experience.color }}>{ experience.name }</div>
				<div className='info-panel'>{ enagementCount }</div>
				<div className='info-panel'>{conversionCount }</div>
				<div className='info-panel'>{ FormatUtil.roundMetric( cvr * 100 ) }%</div>
			</div> );
		});

		return <div className='panel-cell'>
			<h3>Details</h3>
			<div className='details-header details-row'>
				<div className='info-panel'><h4>Experience</h4></div>
				<div className='info-panel'><h4>{ this.#getEngagementEventName() }</h4></div>
				<div className='info-panel'><h4>{ this.#getConversionEventName() }</h4></div>
				<div className='info-panel'><h4>Conversion Rate</h4></div>
			</div>
			{ rowsMarkup }
		</div>;
	}

	/**
	 * @return the markup for display details of a metric
	 */
	#getOrderDetailsMarkup() {
		
		const rowsMarkup = [];
		const experienceAnalytics = this.state.analytics.getMetricExperiencesAnalytics( this.state.selectedMetric.id );
		const { experiences } = SphereAdminSession.optimization;

		const rpeTooltip = 'Revenue Per Engagement - Calculated by dividing the revenue by engagement count';
		const rpCvrTooltip = 'Revenue Potential CVR - The revenue potential calculated by overall engagement * the experience\'s conversion rate * overall average order value';
		const prCvrAovTooltip = 'Revenue Potential CVR/AOV - The revenue potential calculated by overall engagement * the experience\'s conversion rate * the experience\'s average order value';
		const prAovTooltip = 'Revenue Potential AOV - The revenue potential calculated by overall engagement * overall conversion rate * the experience\'s average order value';

		experiences.forEach(( experience, index ) => {
			const expAnalytics = experienceAnalytics?.[ experience.id ];
			rowsMarkup.push( <div key={ index } className='details-detail details-row' >
				<div className='info-panel' style={{ color: experience.color }}>{ experience.name }</div>
				<div className='info-panel'>{ FormatUtil.getValueAsCurrency( expAnalytics?.aov || 0 )}</div>
				<div className='info-panel'>{ FormatUtil.getValueAsCurrency( expAnalytics?.revenue || 0  )}</div>
				<div className='info-panel'>{ FormatUtil.getValueAsCurrency( expAnalytics?.rpe || 0  )}</div>
				<div className='info-panel'>{ FormatUtil.getValueAsCurrency( expAnalytics?.rpcvr || 0  )}</div>
				<div className='info-panel'>{ FormatUtil.getValueAsCurrency( expAnalytics?.rpcvrd || 0  )}</div>
				<div className='info-panel'>{ FormatUtil.getValueAsCurrency( expAnalytics?.rpcvraov  || 0 )}</div>
				<div className='info-panel'>{ FormatUtil.getValueAsCurrency( expAnalytics?.rpcvraovd || 0  )}</div>
				<div className='info-panel'>{ FormatUtil.getValueAsCurrency( expAnalytics?.rpaov || 0  )}</div>
				<div className='info-panel'>{ FormatUtil.getValueAsCurrency( expAnalytics?.rpaovd || 0  )}</div>
			</div> );
		});

		return <div className='panel-cell'>
			<h3>Place Order Details</h3>
			<div className='details-header details-row'>
				<div className='info-panel'><h4>Experience</h4></div>
				<div className='info-panel'><h4>AOV</h4></div>
				<div className='info-panel'><h4>Revenue</h4></div>
				<div className='info-panel'><h4>RPE <Hint width='250px' content={ rpeTooltip }/></h4></div>
				<div className='info-panel'><h4>RP (CVR) <Hint width='300px' content={ rpCvrTooltip }/></h4></div>
				{/* <div className='info-panel'>
					<DropDownField
						className='padless' 
						style={{ display: 'inline-block' }}
						itemsWidth='250px'
						hideBackground={ true }
						labelAlignRight={ true }
						alignRight={ true }
						items={ revenuePotentialTypes }
						labelField='label'
						selectedIndex={ 0 }
						// selectedItem={ this.state.selectedMetric } 
						changeHandler={ this.#handlMetricSelected.bind( this )}/>
					</div> */}
				<div className='info-panel'><h4>δ</h4></div>
				<div className='info-panel'><h4>RP (CVR/AOV) <Hint width='300px' content={ prCvrAovTooltip }/></h4></div>
				<div className='info-panel'><h4>δ</h4></div>
				<div className='info-panel'><h4>RP (AOV) <Hint width='300px' content={ prAovTooltip } position='left'/></h4></div>
				<div className='info-panel'><h4>δ</h4></div>
			</div>
			{ rowsMarkup }
		</div>;
	}

	//

	/**
	 * Makes a request to retrieve impression analytics
	 */
	#updateOptimizationImpressionAnalytics(){ 
		const { startDate, endDate } = ChartTimeValues.getDateRange( selectedTimePeriod.value );
		const startTime = startDate.getTime();
		const endTime = endDate.getTime();
		const retrieveImpressions = new RetrieveOptimizationImpressionAnalyticsRequest( SphereAdminSession.optimization.id, startTime, endTime );
		retrieveImpressions.execute( command => this.#handleRetrieveImpressionAnalyticsComplete( command ));
		this.setState({ loadingEvents: true });
	}

	/**
	 * Makes a request to retrieve engagment analytics
	 */
	#updateOptimizationEngagementAnalytics(){ 
		const { startDate, endDate } = ChartTimeValues.getDateRange( selectedTimePeriod.value );
		const startTime = startDate.getTime();
		const endTime = endDate.getTime();
		const retrieveEngagements = new RetrieveOptimizationEngagementAnalyticsRequest( SphereAdminSession.optimization.id, this.state.selectedMetric, startTime, endTime );
		retrieveEngagements.execute( command => this.#handleRetrieveEngagementAnalyticsComplete( command ));
		this.setState({ loadingEvents: true });
	}

	/**
	 * Makes a request to retrieve conversion analytics
	 */
	#updateOptimizationConversionAnalytics(){ 
		// console.info( 'updateOptimizationConversionAnalytics', this.state.selectedMetric );

		const { startDate, endDate } = ChartTimeValues.getDateRange( selectedTimePeriod.value );
		const startTime = startDate.getTime();
		const endTime = endDate.getTime();
		const retrieveConversions = new RetrieveOptimizationConversionAnalyticsRequest( SphereAdminSession.optimization.id, this.state.selectedMetric, startTime, endTime );
		retrieveConversions.execute( command => this.#handleRetrieveConversionAnalyticsComplete( command ));

		this.setState({ loadingConversionMetrics: true });
	}

	// Request Event Handlers

	/**
	 * Handles the response from the request to retrieve Channel
	 */
	#handleOptimizationRetrieved( command ) {
		SphereAdminSession.loading = false;
		if( SphereAdminSession.optimization != null ) {

			// if this is an AB Optimization, add the default experience
			if( SphereAdminSession.optimization.type === OptimizationModelTypes.AB ) {
				SphereAdminSession.optimization.experiences.unshift({ id: 'default', name: 'Default' });
			}

			// attach some colors to the experiences so they look pretty
			const expLen = SphereAdminSession.optimization.experiences.length + 1;
			const colorOffset = Math.max( 1, Math.round( colors.length / expLen ));
			SphereAdminSession.optimization.experiences.forEach(( experience, index ) => {
				experience.color = (( index + 1 ) < colors.length ) ? colors[ Math.min(( index + 1 ) * colorOffset, colors.length - 1 ) ] : otherColor;
			});
			
			const analytics = new OptimizationAnalytics( SphereAdminSession.optimization );

			// we directly set the state here as the updateOptimizationConversionAnalytics needs to know what the selectedMetric is
			// setState will be called after retrieving data
			// this.state.selectedMetric = SphereAdminSession.optimization.metrics.find( metric => metric.name === 'Place Order' ); // metric.primary );
			this.state.selectedMetric =  SphereAdminSession.optimization.metrics.find( metric => metric.primary );
			this.state.analytics = analytics;

			this.#updateOptimizationImpressionAnalytics();
		} else {
			const setState = new SetStateCommand( AdminStates.ADMIN_OPTIMIZATIONS );
			setState.execute();

			const alert = <Alert content='Channel does not exist' showCancelBtn={false}/>;
			const openModal = new OpenModalCommand( '', alert, '500px', true );
			openModal.execute();
		}
	};

	/**
	 * Handles completion of the RetrieveChannelEventCountsRequest
	 */
	#handleRetrieveImpressionAnalyticsComplete( command ) {
		// console.info( command.getData() );
		this.state.analytics.updateEventAnalytics( command.getData() );
		this.#updateOptimizationEngagementAnalytics();
	}

	/**
	 * Handles completion of the RetrieveChannelEventCountsRequest
	 */
	#handleRetrieveEngagementAnalyticsComplete( command ) {
		// console.info( command.getData() );
		this.state.analytics.updateEventAnalytics( command.getData() );
		this.setState({ loadingEvents: false });
		this.#updateOptimizationConversionAnalytics();
	}

	/**
	 * Handles completion of the RetrieveChannelEventCountsRequest
	 */
	#handleRetrieveConversionAnalyticsComplete( command ) {
		// console.info( 'handleRetrieveConversionAnalyticsComplete' );
		// console.info( this.state.selectedMetric );
		// console.info( command.getData() );
		this.state.analytics.updateConversionAnalytics( this.state.selectedMetric, command.getData() );
		// console.info( this.state.analytics );
		this.setState({ loadingConversionMetrics: false });
	}

	//

	/**
	 * Handles changes to the date range selection
	 */
	#handleDateRangeChange( selectedItem ) {
		selectedTimePeriod = selectedItem;
		this.state.analytics = new OptimizationAnalytics( SphereAdminSession.optimization );
		this.#updateOptimizationImpressionAnalytics();
	}
	
	/**
	 * Handles a change to the selected engagement event
	 */
	#handlMetricSelected( metric ){
		this.state.selectedMetric = metric;
		this.#updateOptimizationConversionAnalytics();
	}

	//

	/**
	 * @return the name of the enagement event for the selected metric
	 */
	#getEngagementEventName() {
		const selectedMetric = this.state.selectedMetric;
		const engagementEventType = MetricEventTypes.engagementTypes.find( type => type.eventId === selectedMetric.engagementEventId );
		const engagementEventName = ( engagementEventType != null ) ? engagementEventType.name : selectedMetric.engagementEventId;
		return engagementEventName;
	}

	/**
	 * @return the name of the enagement event for the selected metric
	 */
	#getConversionEventName() {
		const selectedMetric = this.state.selectedMetric;
		const conversionEventType = MetricEventTypes.conversionTypes.find( type => type.eventId === selectedMetric.conversionEventId );
		const conversionEventName = ( conversionEventType != null ) ? conversionEventType.name : selectedMetric.conversionEventId;
		return conversionEventName;
	}
	
	/**
	 * @return The metric for the specified event or zero if none exists
	 */
	#getImpressionAnalytics( eventId, frequency ) {
		const metricProp = ( frequency === 0 ) ? 'count' : 'sessions';
		const eventMetric = this.state.analytics.getEngagementEventMetric( eventId, frequency );
		return ( eventMetric != null ) ? eventMetric[ metricProp ] : 0;;
	}

	/**
	 * @return The metric for the specified event or zero if none exists
	 */
	#getConversionAnalytics( metric ){
		const eventMetric = this.state.analytics.getConversionEventMetric( this.state.selectedMetric.id );
		return ( eventMetric != null ) ? eventMetric[ metric ] : 0;;
	}

	//

	/**
	 * Initiates putting the Optimization into a completed state
	 */
	#completeOptimization() {
		const alert = <Alert content='Are you sure you want to complete the Optimization, this cannot be undone?' okHandler={ this.#handleCompleteConfirm.bind( this ) }/>;
		const openModal = new OpenModalCommand( 'Are you sure?', alert, '500px', true );
		openModal.execute();
	}

	/**
	 * Handles confirmation to complete the Optimization
	 */
	#handleCompleteConfirm() {
		SphereAdminSession.loading = true;
		const completeOptimization = new CompleteOptimizationRequest( SphereAdminSession.optimization );
		completeOptimization.execute( this.#handleCompleteComplete.bind( this ));
	}

	/**
	 * Handles confirmation to complete the Optimization
	 */
	#handleCompleteComplete() {
		SphereAdminSession.loading = false;
	}

}

//

export default ViewOptimizationPanel;
