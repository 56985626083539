/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import ChartsTheme from './ChartsTheme';
import { VictoryBar, VictoryChart } from 'victory';
import DropDownField from '../controls/DropDownField';
import ChartTimeValues from '../../model/ChartTimeValues';
import RetrieveTopPerformersRequest from '../../requests/channels/RetrieveTopPerformersRequest';

//

/**
 * The currently selected time period
 */
var selectedTimePeriod = ChartTimeValues.availableRanges[ 2 ];

/**
 * The currently selected time period
 */
var selectedIncrement = selectedTimePeriod.defaultIncrement;

//

/**
 * Renders imppressions as a line chart
 */
class TopPerformersChart extends Component {
	
	constructor( props ) {
		super( props );
		this.state = {};
		this.conversionEvents = [ 'sphere-channel-click', 'add-to-cart', 'place-order' ].reverse();
		this.#retrieveConversions();
	}

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		var markup = ( this.state.metrics != null ) ? this.#getMetricsMarkup() : '';
		return markup;
	}
	
	/**
	 * @return the markup for the conversions chart
	 */
	#getMetricsMarkup(){

		var metrics = this.state.metrics;

		var theme = ChartsTheme;
		var colors = ["7400b8","5e60ce","4ea8de","56cfe1","64dfdf","80ffdb"];

		return ( 
			<div className='grid'>
				<div className='grid-cell default-33'>
					<h3>Top Performers</h3>
				</div>
				<div className='grid-cell default-66 align-right'>
					<DropDownField
						className='padless'
						itemsWidth='100px' 
						hideBackground={true} 
						labelAlignRight={true} 
						alignRight={true}
						style={{ display: 'inline-block' }}
						items={[ 'Channels', 'Content' ]}
						//labelField='label'
						selectedIndex={0}
						changeHandler={this.#handleDateRangeChange.bind( this )}/>
					<DropDownField
						className='padless pad-cell-left'
						itemsWidth='100px' 
						hideBackground={true} 
						labelAlignRight={true} 
						alignRight={true}
						style={{ display: 'inline-block' }}
						items={[ 'Views', 'Clicks', 'Add to Cart' ]}
						//labelField='label'
						selectedIndex={1}
						changeHandler={this.#handleDateRangeChange.bind( this )}/>
					<DropDownField
						className='padless pad-cell-left'
						itemsWidth='100px' 
						hideBackground={true} 
						labelAlignRight={true} 
						alignRight={true}
						style={{ display: 'inline-block' }}
						items={ChartTimeValues.availableRanges}
						labelField='label'
						selectedItem={selectedTimePeriod}
						changeHandler={this.#handleDateRangeChange.bind( this )}/>
				</div>
				<div className='grid-cell default-100'>
					<VictoryChart
						theme={theme}
						height={217} 
						padding={{ top: 20, right: 10, bottom: 20, left: 80 }}
						domainPadding={{ x: 15 }}
					>
						<VictoryBar
							//barWidth={15}
							horizontal={true}
							//domainPadding={{ x: barWidth }}
							//barRatio={0.05}
							style={{ data: { fill: ({ datum }) => colors[ datum._x - 1 ] }}}
							data={metrics}
							sortKey='x'
							sortOrder='descending'
						/>
					</VictoryChart>
				</div>
			</div>
		);
	}

	//

	/**
	 * Executes the request to retrieve channel impressions
	 */
	#retrieveConversions(){

		var objectType = RetrieveTopPerformersRequest.OBJECT_TYPE_CHANNEL;
		var eventType = 'sphere-channel-click';
		var { startDate, endDate } = ChartTimeValues.getDateRange( selectedTimePeriod.value);
		this.startDate = startDate;
		this.endDate = endDate;

		var retrieveMetrics = new RetrieveTopPerformersRequest( objectType, eventType, startDate, endDate, selectedIncrement.value );
		retrieveMetrics.execute(( command ) => { this.#handleMetricsRetrieved( command ); } );

		//var retrieveConversions = new RetrieveChannelConversionsRequest( 'home-hero-banner', startDate, endDate, selectedIncrement.value );
		//retrieveConversions.execute(( command ) => { this.#handleConversionsRetrieved( command ); } );
	}

	//

	/**
	 * Handles completion of the RetrieveChannelConversionsRequest
	 */
	#handleMetricsRetrieved( command ){
		var metrics = command.getMetrics();
		console.info( 'metrics', metrics );
		this.setState({ metrics: metrics });
	}

	/**
	 * Handles changes to the chart zoom
	 */
	/*
	handleZoom( domain ) {
		//console.info( 'domain', domain );
		//this.setState({ zoomDomain: domain });
	}
	*/

	/**
	 * Handles changes to the date range selection
	 */
	 #handleDateRangeChange( selectedItem ) {
		selectedTimePeriod = selectedItem;
		selectedIncrement = selectedTimePeriod.defaultIncrement;
		this.#retrieveConversions();
	}
	
	/**
	 * Handles changes to the time increment selection
	 */
	#handleTimeIncrementChange( selectedItem ){
		selectedIncrement = selectedItem;
		this.#retrieveConversions();
	}
	
}

export default TopPerformersChart;
