/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import AudienceRuleCombinators from '../../../model/AudienceRuleCombinators';
import AudienceRuleTypes from '../../../model/AudienceRuleTypes';
import ModifiableObject from '../../../model/ModifiableObject.mjs';
import CreateAudienceCommand from '../../commands/CreateAudienceCommand';
import EditAudienceCommand from '../../commands/EditAudienceCommand';
import OpenModalCommand from '../../commands/OpenModalCommand';
import SetStateCommand from '../../commands/SetStateCommand';
import ValidatorCommand from '../../commands/ValidatorCommand';
import AddIcon from '../../icons/AddIcon';
import CancelIcon from '../../icons/CancelIcon';
import AudienceFactory from '../../managers/AudienceFactory';
import AdminStates from '../../model/AdminStates';
import ObjectStatusMap from '../../model/ObjectStatusMap';
import SphereAdminSession from '../../model/SphereAdminSession';
import SaveAudienceRequest from '../../requests//audiences/SaveAudienceRequest';
import Alert from '../controls/Alert';
import DropDownField from '../controls/DropDownField';
import Hint from '../controls/Hint';
import InputField from '../controls/InputField';

//

/**
 * The available rule combinators
 */
const combinators = [ { value: 0, label: 'AND' }, { value: 1, label: 'OR' } ];

//

/**
 * The AudienceEditor contains the UI for creating and editing an Audience
 */
class AudienceEditor extends Component {
	
	/**
	 * Constructs the panel.
	 */
	 constructor(){
		super();

		this.state = {
			selectedExperiences: []
		};

		this.experiencesGridRef = React.createRef();
	}

	// Rendering

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		const audience = SphereAdminSession.audience;
		if( audience != null ) {
			return 	<div className='content-panel audience-editor'>
						{ !this.props.modal && this.#getHeaderMarkup() }
						{ this.#getPrimaryFieldsMarkup() }
						{ this.#getRulesEditorMarkup() }
					</div>;
		} else {
			return 	<div className='content-panel audience-editor'>
						{ this.#getNoAudienceFoundMarkup() }
					</div>;
		}
		
	}

	/**
	 * @return the markup for the header ui
	 */
	#getHeaderMarkup() {
		const audience = SphereAdminSession.audience;
		const isLocked = ( audience.status === ModifiableObject.LOCKED );
		const isEditing = ( SphereAdminSession.currentState === AdminStates.ADMIN_AUDIENCES_EDIT );
		const title = ( isEditing ) ? 'Edit Audience' : 'Create Audience';
		const saveDisabled = ( audience.status === ModifiableObject.SAVED );
		const saveButtonsDisabledClass = ( saveDisabled ) ? ' disabled' : '';
		return 	<div>
					<div className='grid'>
						<div className='grid-cell default-50'>
							<h2>{ title }</h2>
							<div className='breadcrumb'>
								<a href='/audiences/' className='breadcrumb'>Audiences</a> / { audience.name }
							</div>
						</div>
						<div className='grid-cell default-50 align-right header-actions'>
							{ !isLocked && 
								<button
									className={'primary-button control-pad-left' + saveButtonsDisabledClass }
									disabled={ saveDisabled } style={{ width: '80px' }}
									onClick={ this.#handleSave.bind( this )}>Save</button>
							}
							<button
								className={'button control-pad-left'}
								style={{width: '80px'}}
								onClick={ this.#handleCancel.bind( this )}>Cancel</button>
						</div>
					</div>
					{ isLocked &&
						<div className='panel-cell'>The Audience is in use by a published optimization</div>
					}
				</div>;
	}

	/**
	 * @return The markup for displaying id, name, and model fields
	 */
	#getPrimaryFieldsMarkup(){
		const audience = SphereAdminSession.audience;
		const isLocked = ( audience.status === ModifiableObject.LOCKED );
		
		const nameToolTip = 'User friendly name for the Audience.';
		const status = ( audience.locked ) ? 'locked' : ObjectStatusMap[ audience.status ];
		const statusIndicatorElement = <div className={'status-indicatator ' + status}></div>;

		return <div className='grid panel-cell primary-fields'>
					<div className='grid-cell default-100'>
						{ !this.props.modal && statusIndicatorElement }
						<label>Name <Hint width='250px' content={nameToolTip}/></label>
						<InputField 
							value={ audience.name } 
							maxLength='256' 
							disabled={ isLocked } 
							onChange={( value ) => {  this.#handleFieldChanged( 'name', value ); }}/>
					</div>
				</div>;
	}

	/**
	 * @return The mark up that displays the UI for setting up the rules of the Audience
	 */
	#getRulesEditorMarkup(){
		const audience = SphereAdminSession.audience;
		const isLocked = ( audience.status === ModifiableObject.LOCKED );
		
		const rulesToolTip = 'Rules define the criteria in which an audience is chosen';
		const hasRules = ( audience.rules.length > 0 );
		const rulesMarkup = ( hasRules ) ? this.#getRulesListMarkup() : this.#getNoRulesMarkup();

		return <div style={{ display: 'flex', alignItems: 'stretch' }}>
					<div style={{ flexGrow: 2 }}>
						<div className='panel-cell'>
							<h3>Rules <Hint width='250px' content={rulesToolTip}/></h3>
							{ rulesMarkup }
							{ !hasRules && !isLocked &&
								<div className='pad-cell-top pad-cell-bottom' style={{ textAlign: 'center', marginTop: 26 }}>
									<button className='button' onClick={ this.#handleAddRule.bind( this )} style={{ width: 120 }}>
										Add Rule
									</button>
								</div>
							}
						</div>
						{ hasRules && !isLocked && 
							<div className='pad-cell-top pad-cell-bottom' style={{ textAlign: 'center', marginTop: 26 }}>
								<button className='link-button' onClick={ this.#handleAddGroup.bind( this )} style={{ width: 120 }}>
										Add Group
									</button>
							</div>
						}
					</div>
				</div>;
	}

	/**
	 * @return The mark up that displays the UI for setting up the rules of the Audience
	 */
	#getNoRulesMarkup(){
		return 	<div>
					<div className='pad-cell-bottom'>No rules have been specified for this Audience, it will include everyone.</div>
				</div>;
	}

	/**
	 * @return The mark up that displays the UI for setting up the rules of the Audience
	 */
	#getRulesListMarkup() {
		const audience = SphereAdminSession.audience;
		const rules = audience.rules;
		const isLocked = ( audience.status === ModifiableObject.LOCKED );

		const groups = [];
		const groupsMarkup = [];

		// first organize the rules into their respective groups
		rules.forEach(( rule, index ) => {
			if( groups[ rule.groupId ] == null ) groups[ rule.groupId ] = [];
			groups[ rule.groupId ].push( rule );
		});

		groups.forEach( groupedRules => {
			const rulesMarkup = [];
			groupedRules.forEach(( rule, index ) => {
				rulesMarkup.push( this.#getMarkupForRule( rule ));
			});

			groupsMarkup.push( 
				<div className='rule-group'>
					{ rulesMarkup }
					{ !isLocked && 
						<div className='pad-cell-top pad-cell-bottom' style={{  textAlign: 'center', margin: '26px 0' }}>
							<button className='link-button' onClick={ this.#handleAddRule.bind( this )} style={{ width: 120 }}>
								<AddIcon/>
							</button>
						</div>
					}
				</div> );
		});

		return <div>{ groupsMarkup }</div>;
	}

	/**
	 * @return the markup for rendering a single rule
	 */
	#getMarkupForRule( rule ) {
		const audience = SphereAdminSession.audience;
		const isLocked = ( audience.status === ModifiableObject.LOCKED );

		const type = AudienceRuleTypes.find( type => type.id === rule.typeId );
		const visibleVariableName = ( type != null && type.variablePrefix != null ) ? rule.variableName.replace( type.variablePrefix, '' ) : rule.variableName;
		const operatorIndex = ( type != null ) ? type.operators.findIndex( operator => operator.value === rule.operator ) : 0;
		const showCombinator = ( rule.groupId > 0 || rule.position > 0 );
		return (
			<div key={ rule.id } className='audience-rule'>
				{ showCombinator && 
					<div className='combinator pad-cell-bottom' style={{ width: '100%', textAlign: 'center'  }} >
						<DropDownField
							width='70px'
							itemsWidth='100px'
							hideBackground={ true } 
							labelField='label'
							items={ combinators }
							selectedIndex={ rule.combinator }
							disabled={ isLocked }
							changeHandler={( combinator ) => { this.#handleRuleCombinatorChanged( rule, combinator ); }}/>
					</div>
				}
				<div>
					<DropDownField
						width='250px'
						itemsWidth='250px'
						label='Select a Rule Type'
						labelFunction={ ( type ) => { return `${ type.group } - ${ type.name }` }}
						items={ AudienceRuleTypes }
						selectedItem={ type }
						disabled={ isLocked }
						changeHandler={( type ) => { this.#handleRuleTypeSelected( rule, type ); }}/>
				</div>
				{ type != null && type.variableName == null &&
					<div className='pad-cell-left' style={{ flexGrow: 2 }}>
						<InputField
							value={ visibleVariableName }
							maxLength='256'
							disabled={ isLocked }
							onChange={( value ) => { this.#handleRuleVariableNameFieldChanged( type, rule, value ); }}/>
					</div>
				}
				{ type != null &&
						<div className='pad-cell-left'>
							<DropDownField
								width='150px'
								itemsWidth='150px'
								labelField='label'
								items={ type.operators }
								selectedIndex={ operatorIndex }
								disabled={ isLocked }
								changeHandler={( operator ) => { this.#handleRuleOperatorChanged( rule, operator ) }}/>
						</div>
				}
				{ type != null && type.requiresValue &&
					<div className='pad-cell-left' style={{ flexGrow: 2 }}>
						<InputField
							value={ rule.value }
							maxLength='256'
							disabled={ isLocked }
							onChange={( value ) => { this.#handleRuleValueFieldChanged( rule, value ); }}/>
					</div>
				}
				{ !isLocked && 
					<div className='pad-cell-left' style={{ flexGrow: ( type != null && type.requiresValue ) ? 0 : 1, display: 'flex', justifyContent: 'flex-end' }}>
						<button className='link-button' onClick={ () => { this.#handleRemoveRule( rule ); }}>
							<CancelIcon size='26'/>
						</button>
					</div>
				}
			</div>
		);
	}

	/**
	 * @return Markup for the case when the Audience cannot be found
	 */
	#getNoAudienceFoundMarkup() {
		return 	<div className='grid'>
					<div className='grid-cell default-100'>
						<h2>{ 'Edit Audience' }</h2>
						<div className='breadcrumb'>
							<a href='/audiences/' className='breadcrumb'>Audiences</a>
						</div>
					</div>
					<div className='panel-cell grid-cell default-100' style={{ textAlign: 'center' }}>
						Audience could not be found. <button className='link-button' onClick={ this.#handleCreateAudience.bind( this )}>Create Audience</button>
					</div>
				</div>;
	}

	//

	/**
	 * Invalidates the state of the panel, used to trigger state updates
	 */
	#invalidate(){
		SphereAdminSession.audience.status = ModifiableObject.MODIFIED;
		this.setState({}); // redraw
	};

	// Event Handlers

	/**
	 * Handles changes to the input fields, invalidating the Channel object
	 */
	#handleFieldChanged( field, value ) {
		// console.info( field, value );
		SphereAdminSession.audience[ field ] = value;
		this.#invalidate();
	};

	/**
	 * Handles the click to add a new rule
	 */
	#handleAddRule() {
		const audiences = SphereAdminSession.audience;
		const rules = audiences.rules;
		const groupId = ( rules.length === 0 ) ? 0 : rules[ rules.length - 1 ].groupId;
		
		const rule = AudienceFactory.createAudienceRule( audiences );
		rule.groupId = groupId;
		rule.combinator = AudienceRuleCombinators.AND;
		
		this.#invalidate();
	};

	/**
	 * Handles the click to add a new group. This creates a new Rule within a new group
	 */
	#handleAddGroup() {
		const audiences = SphereAdminSession.audience;
		const rules = audiences.rules;
		const groupId = rules[ rules.length - 1 ].groupId + 1;
		
		const rule = AudienceFactory.createAudienceRule( audiences );
		rule.groupId = groupId;
		rule.combinator = AudienceRuleCombinators.AND;
		this.#invalidate();
	};

	/**
	 * Handles changes to a rule's type
	 */
	#handleRuleTypeSelected( rule, type ) {
		const variableName = ( type.variableName != null ) ? type.variableName : '';
		rule.typeId = type.id;
		rule.name = type.name;
		rule.variableName = ( type.variablePrefix == null ) ? variableName : type.variablePrefix + variableName;
		rule.operator = type.operators[ 0 ].value;
		console.info( rule );
		this.#invalidate();
	};

	/**
	 * Handles changes a rule's value
	 */
	#handleRuleVariableNameFieldChanged( type, rule, value ) {
		rule.variableName = ( type.variablePrefix == null ) ? value : type.variablePrefix + value;
		this.#invalidate();
	};

	/**
	 * Handles changes a rule's value
	 */
	#handleRuleOperatorChanged( rule, operator ) {
		rule.operator = operator.value;
		this.#invalidate();
	};

	/**
	 * Handles changes a rule's value
	 */
	#handleRuleValueFieldChanged( rule, value ) {
		rule.value = value;
		this.#invalidate();
	};

	/**
	 * Handles changes a rule's combinator
	 */
	#handleRuleCombinatorChanged( rule, combinator ) {
		rule.combinator = combinator.value;
		this.#invalidate();
	};

	/**
	 * Handles the click to add a new rule
	 */
	#handleRemoveRule( rule ) {
		SphereAdminSession.audience.removeRule( rule );
		this.#invalidate();
	};

	// Save, Cancel, Publish

	/**
	 * Handles a click on the save button, saves the Channel 
	 */
	#handleSave() {
		const fields = { 
			id: ValidatorCommand.isNotNullOrEmpty, 
			name: ValidatorCommand.isNotNullOrEmpty 
		};

		const validateSite = new ValidatorCommand( SphereAdminSession.audience, fields );
		const isValid = validateSite.execute();

		if( isValid ){
			SphereAdminSession.loading = true;
			const saveAudience = new SaveAudienceRequest( SphereAdminSession.audience );
			saveAudience.execute(( command ) => { this.#handleSaveComplete( command ); });
		} else {
			
			const invalidFields = validateSite.getInvalidFields();
			const invalidFieldsElements = [];
	
			var i = 0;
			invalidFields.forEach( field => {
				invalidFieldsElements.push( <li key={i++}>{field}</li> );
			});
	
			const content = <div className='alert'>
				The Audience cannot be saved. The following fields are invalid or incomplete:
				<ul>{invalidFieldsElements}</ul>
			</div>;
	
			const openModal = new OpenModalCommand( 'Invalid Audience', content, '500px', true );
			openModal.execute();
		}
	};

	/**
	 * Handles completion of the save channel reques
	 */
	#handleSaveComplete() {
		SphereAdminSession.loading = false;
		if( !this.props.modal ){
			if( SphereAdminSession.currentState === AdminStates.ADMIN_AUDIENCES_CREATE ) {
				const editAudience = new EditAudienceCommand( SphereAdminSession.audience );
				editAudience.execute();
			} else {
				this.setState({}); // redraw
			}
		}

		if( this.props.saveHandler != null ) this.props.saveHandler( SphereAdminSession.audience );
	}

	/**
	 * Handles a click on the cancel button
	 */
	#handleCancel() {
		const hasChanged = SphereAdminSession.audience.status === ModifiableObject.MODIFIED || SphereAdminSession.audience.status === ModifiableObject.CREATED;
		if( hasChanged ){
			const alert = <Alert content='You have unsaved changes, are you sure you want to exit?' okHandler={ this.#handleCancelConfirm.bind( this ) }/>;
			const openModal = new OpenModalCommand( 'Are you sure?', alert, '500px', true );
			openModal.execute();
		} else {
			const setState = new SetStateCommand( AdminStates.ADMIN_AUDIENCES );
			setState.execute();
		}
	}

	/**
	 * Handles a confirmation to cancel changes
	 */
	#handleCancelConfirm() {
		const setState = new SetStateCommand( AdminStates.ADMIN_AUDIENCES );
		setState.execute();
	}

	/**
	 * Handles the click to create an Audience. Only possible when the Audience cannot be found
	 */
	#handleCreateAudience( e ) {
		const create = new CreateAudienceCommand();
		create.execute();
	}

	// Public

	/**
	 * Saves the Audience
	 */
	save() {
		this.#handleSave();
	}

}

//

export default AudienceEditor;
