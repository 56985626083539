/*
 * (c) Verra Technology Corporation
 */

import AudienceRule from "../../model/AudienceRule";
import Optimization from "../../model/Optimization";
import OptimizationModelTypes from "../../model/OptimizationModelTypes";
import SphereAdminSession from "../model/SphereAdminSession";

/**
 * Factory class for creating Optimization model objects
 */
const OptimizationFactory = {

	/**
	 * Creates a new, empty Channel
	 * @return Optimization
	 */	
	createOptimization: function(){
		const optimization = new Optimization();
		optimization.accountId = SphereAdminSession.selectedAccount.id;
		optimization.id = window.crypto.randomUUID();
		return optimization;
	}
	
};

export default OptimizationFactory;
