/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import { 
	VictoryAxis,
	VictoryBar,
	VictoryChart,
	VictoryLabel} from 'victory';
import Loader from '../controls/Loader';
import ChartsTheme from './ChartsTheme';
import DropDownField from '../controls/DropDownField';
import SphereAdminSession from '../../model/SphereAdminSession';
import ChannelModelTypes from '../../model/ChannelModelTypes';

//

const CHANNEL_VIEW_TYPE = 'Channel';
const VARIANTS_VIEW_TYPE = 'Variants';

/**
 * 
 */
const viewTypes = [
	CHANNEL_VIEW_TYPE,
	VARIANTS_VIEW_TYPE
];

//

/**
 * Displays a bar graph of winner probabilities
 */
class VariantWinnerChart extends Component {

	/**
	 * Constructs the component
	 */
	constructor(){
		super();
		this.state = {
			selectedViewType: ( SphereAdminSession.channel.model === ChannelModelTypes.PREDICTIVE ) ? CHANNEL_VIEW_TYPE : VARIANTS_VIEW_TYPE
		};
	}

	//

	/**
	 * Renders the component
	 * @see react docs
	 */
	render(){
		const metrics = this.props.metrics;
		const markup = ( metrics == null ) ? <Loader/> : 
					<div className='grid'>
						<div className='grid-cell default-33'>
							<h3 style={{ display: 'inline-block' }}>Probabilty to be the Best - {this.props.selectedConversionEvent.name}</h3>
						</div>
						<div className='grid-cell default-66 align-right'>
							<DropDownField
								className='padless'
								itemsWidth='225px' 
								hideBackground={true} 
								labelAlignRight={true} 
								alignRight={true}
								style={{ display: 'inline-block' }}
								items={viewTypes}
								selectedItem={ this.state.selectedViewType }
								changeHandler={ this.#handleViewTypeSelected.bind( this )}
							/>
						</div>
						{ this.#getChartMarkup() }
					</div>;

		return markup;
	}

	/**
	 * @return the markup for the chart
	 */
	#getChartMarkup(){
		let chartData = ( this.state.selectedViewType === CHANNEL_VIEW_TYPE ) ? this.#getChannelChartData() : this.#getVariantsChartData();
		// chartData = chartData.concat( this.#getVariantsChartData() );
		const height = chartData.length * 10;
		const theme =  ChartsTheme;
		const labelStyle = { ...ChartsTheme.bar.style.labels };
		labelStyle.fontSize = 4;

		const markup = <div className='grid-cell default-100 start-row' style={{ padding: '10px 0' }}>
			<VictoryChart 
				theme={theme}
				width={300}
				height={height} 
				sortKey='i'
				sortOrder='ascending'
				domain={{ y:[ 0, 100 ]}}
				singleQuadrantDomainPadding={false}
				domainPadding={{ x: 5, y: [ 0, 10 ] }}
				padding={{ top: 0, right: 5, bottom: 0, left: 1 }}
			>
				<VictoryAxis tickFormat={() => ''} dependentAxis={true} style={{ axis: { stroke: "none" }, ticks: { stroke: "transparent" } }} />
				<VictoryAxis tickFormat={() => ''} dependentAxis={false} style={{ axis: { stroke: "none" }, ticks: { stroke: "transparent" } }} />
				<VictoryBar
					//sortKey='i'
					// sortOrder='ascending'
					horizontal={true}
					barWidth={7}
					// barRatio={0.1}
					style={{ data: { fill: ({ datum }) => datum.color } }}
					data={chartData}
					labels={({ datum }) => {
						let percent = Intl.NumberFormat( 'en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 } ).format( datum.y ) + '%';
						return percent;
					}}
					labelComponent={
						<VictoryLabel 
							dx={3}
							style={ labelStyle }/>
					}
				/>
			</VictoryChart>	
		</div>;
		return markup;
	}
	
	/**
	 * @return data for displaying variants in the chart
	 */
	#getChannelChartData(){
		const chartData = [];
		const conversionMetrics = this.props.metrics.getConversionEventMetric( this.props.selectedEngagementEvent.id, this.props.selectedConversionEvent.id );

		if( conversionMetrics.contentSuccessProbability != null ) chartData.push({ x: 'Channel', y: conversionMetrics.contentSuccessProbability, color: '#FF8F57' });
		if( conversionMetrics.controlSuccessProbability != null ) chartData.push({ x: 'Control', y: conversionMetrics.controlSuccessProbability, color: '#555555' });

		return chartData;
	}
	
	/**
	 * @return data for displaying variants in the chart
	 */
	#getVariantsChartData(){
		const chartData = [];
		const conversionMetrics = this.props.metrics.getAllConversionContentEventMetrics( this.props.selectedEngagementEvent.id, this.props.selectedConversionEvent.id );
		
		let i = 0;
		let y;

		Object.keys( conversionMetrics.content ).forEach( contentId => {
			let contentMetric = conversionMetrics.content[ contentId ];
			let contentObj = contentMetric.getContent();
			y = contentMetric.successProbability;
			if( y != null ) chartData.push({ i: i++, x: contentObj.name, y, color: contentObj.color });
		});

		// let controlMetric = conversionMetrics.control;
		// y = controlMetric.successProbability;
		// if( y != null ) chartData.push({ i: i++, x: 'Control', y, color: '#555555' });

		return chartData;
	}

	/**
	 * Handles view type selections
	 */
	#handleViewTypeSelected( selectedViewType ){
		this.setState({ selectedViewType });
	}

}

export default VariantWinnerChart;
