/*
 * (c) Verra Technology Corporation
 */

import ServiceRequestCommand from '../ServiceRequestCommand.js';
import SphereAdminServices from '../../model/SphereAdminServices.js';
import ModifiableObject from '../../../model/ModifiableObject.mjs';
import SphereAdminSession from '../../model/SphereAdminSession.js';

/**
 * Saves an Audience
 */
class SaveAudienceRequest extends ServiceRequestCommand {

	/**
	 * Constructs the Request
	 * @param audience The Audience to save
	 */
	constructor( audience ){
		super();
		this.audience = audience;
	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
    getService() {
		return SphereAdminServices.SAVE_AUDIENCE;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		return { audience: this.audience };
	}

   /**
	* Handles completion of the command
	*/
	handleComplete() {
		const response = this.getResponse();
		if( response.success ) {
			this.audience.status = ModifiableObject.SAVED; // TODO: should this come from the DB?
			SphereAdminSession.audiences.push( this.audience );
		}
		//console.info( 'SaveContentRequest::handleComplete content', this.content );
	}
 
}

export default SaveAudienceRequest;
