/*
 * (c) Verra Technology Corporation
 */

import Command from '../../commands/Command.mjs';
import AdminStates from '../model/AdminStates';
import SphereAdminSession from '../model/SphereAdminSession';
import SetStateCommand from './SetStateCommand';

/**
 * Sets the state to create audience
 */
class CreateAudienceCommand extends Command {
	
	/**
	 * Handles execution of the command
	 */
	handleExecute() {
		const setState = new SetStateCommand( AdminStates.ADMIN_AUDIENCES_CREATE );
		setState.execute();
	}
	
}

//

export default CreateAudienceCommand;
