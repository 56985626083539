/*
 * (c) Verra Technology Corporation
 */

import Command from '../../commands/Command.mjs';
import AdminStates from '../model/AdminStates';
import SetStateCommand from './SetStateCommand';

/**
 * Initiates the view to create an Experience for an Optimization
 */
class CreateExperienceForOptimizationCommand extends Command {
	
	/**
	 * Handles execution of the command
	 */
	handleExecute() {
		const setState = new SetStateCommand( AdminStates.ADMIN_EXPERIENCES_CREATE_FOR_OPTIMIZATION, null, false, false );
		setState.execute();
	}
	
}

//

export default CreateExperienceForOptimizationCommand;
