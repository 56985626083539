/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';

import SphereAdminSession from '../model/SphereAdminSession';
import DataGrid from '../components/controls/DataGrid';
import Alert from '../components/controls/Alert';
import OpenModalCommand from '../commands/OpenModalCommand';
import RetrieveAudiencesListRequest from '../requests//audiences/RetrieveAudiencesListRequest';
import EditAudienceCommand from '../commands/EditAudienceCommand';
import CreateAudienceCommand from '../commands/CreateAudienceCommand';
import AudienceRule from '../../model/AudienceRule';
import DeleteAudiencesRequest from '../requests//audiences/DeleteAudiencesRequest';
import AudiencesList from '../components/optimizations/AudiencesList';

//

/**
 * Displays the list of Audiences created within the account
 */
class AudiencesListPanel extends Component {
	
	/**
	 * Constructs the ChannelPanel.
	 */
	constructor() {
		super();
		
		this.state = {
			audiencesSelected: false
		};

		this.audiencesList = React.createRef();

		SphereAdminSession.loading = true;
		const retrieveAudiences = new RetrieveAudiencesListRequest();
		retrieveAudiences.execute(( command ) => { this.#handleAudiencesRetrieved( command ); } );
	}

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {	
		const deleteDisabled = ( this.state.audiencesSelected ) ? '' : ' disabled';
		const emptyResultsMarkup = <div>You do not have any Audiences. <button className='link-button' onClick={ this.#handleCreateNewAudience.bind( this )}>Create Audience</button></div>;

		const markup = ( 
			<div className='audiences-panel'>
				<div className='grid'>
					<h2 className='grid-cell default-100'>Audiences</h2>
					<div className='grid-cell'>
						<button className='button' onClick={ this.#handleCreateNewAudience.bind( this )}>Create</button>
					</div>
					<div className='grid-cell pad-cell-left'>
						<button className={'button' + deleteDisabled} onClick={ this.#handleDelete.bind( this )}>Delete</button>
					</div>
				</div>
				<div className='pad-cell-top'>
					<AudiencesList
						ref={ this.audiencesList }
						statusIndicator={ true }
						noContent={ emptyResultsMarkup }
						rowClickHandler={ this.#handleAudienceListItemClick.bind( this )}
						checkBoxChangeHandler={ this.#handleGridCheckBoxSelect.bind( this )}/>
				</div>
			</div>
		);

		return markup;
	}

	// Handlers

	/**
	 * Handles the response from the request to retrieve Channel
	 */
	#handleAudiencesRetrieved( command ) {
		SphereAdminSession.loading = false;
		this.setState({}); // redraw
	};

	/**
	 * Handles clicks on the new Channel button
	 */
	#handleCreateNewAudience( e ) {
		const createAudience = new CreateAudienceCommand();
		createAudience.execute();
	};

	/**
	 * Handles clicks on an Audience in the list
	 */
	#handleAudienceListItemClick( audience ) {
		const editAudience = new EditAudienceCommand( audience );
		editAudience.execute();
	};

	/**
	 * Handles clicks on a checkbox in the Content grid
	 */
	#handleGridCheckBoxSelect( grid ){
		const selected = grid.getChecked();
		this.setState({ audiencesSelected: ( selected.length > 0 )});
	}

	/**
	 * Handles the click to create a new site
	 */
	#handleDelete( e ){
		if( this.state.audiencesSelected ){
			const alert = <Alert content='Are you sure you want to delete these Audiences?' okHandler={ this.#handleConfirmDelete.bind( this ) }/>;
			const openModal = new OpenModalCommand( 'Are you sure?', alert, '500px', true );
			openModal.execute();
		}
	}

	/**
	 * Handles the click to create a new site
	 */
	 #handleConfirmDelete( e ){
		SphereAdminSession.loading = true;
		const selectedItems = this.audiencesList.current.getChecked();
		const ids = selectedItems.map( item => item.id );
		const deleteAudiences = new DeleteAudiencesRequest( ids );
		deleteAudiences.execute( command  => this.#handleDeleteComplete( command ));
	}

	/**
	 * Handles the click to create a new site
	 */
	 #handleDeleteComplete( command ){
		SphereAdminSession.loading = false;
		this.audiencesList.current.unCheckAll();

		const audiencesInUse = command.getAudiencesInUse();
		if( audiencesInUse.length > 0 ){
			const inUseMarkup = audiencesInUse.map( audience => <li key={audience.audienceId}>The Audience with ID {audience.audienceId} is used by the Optimization <a href={'/optimizations/edit/' + audience.optimizationId + '/'}>{audience.optimizationId }</a></li> );
			const alertContent = <div>The following Audiences could not be deleted because they are in use:<ul>{inUseMarkup}</ul></div>;
			const alert = <Alert content={alertContent} showCancelBtn={false}/>;
			const openModal = new OpenModalCommand( 'Cound not delete Content', alert, '500px', true );
			openModal.execute();
		}
	}

}

//

export default AudiencesListPanel;
