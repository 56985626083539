/*
 * (c) Verra Technology Corporation
 */

import AudienceRule from "./AudienceRule";
import ModifiableObject from "./ModifiableObject.mjs";

/**
 * Represents an Optimization
 */
class Audience extends ModifiableObject {

	/**
	 * Defines the structure of the Channel class. Used with ObjectUtil.CreateFromDefinition
	 */
	static definition = {
		type: Audience,
		properties: {
			rules: {
				type: AudienceRule
			}
		}
	}

	// Properties

	/**
	 * The ID of the account in which the Audience is associated
	 */
	accountId = null;

	/**
	 * The id of the Audience
	 */
	id;

	/**
	 * The name of the Audience
	 */
	name = '';

	/**
	 * The rules for the Audience
	 */
	rules = [];

	//

	/**
	 * Adds a new AudienceRule to the Audience
	 * @param rule The AudienceRule to add
	 */
	addRule( rule ){
		this.rules.push( rule );
	}

	/**
	 * Removes an AudienceRule from the Audience
	 * @param rule The AudienceRule to remove
	 */
	removeRule( ruleToRemove ){
		var i = 0;
		this.rules = this.rules.filter(( rule ) => {
			const isRule = ( rule === ruleToRemove ); 
			if( !isRule ) rule.position = i++;
			return !isRule;
		});
	}

}

//

export default Audience;