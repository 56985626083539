/*
 * (c) Verra Technology Corporation
 */

import Command from '../../commands/Command.mjs';
import AdminStates from '../model/AdminStates';
import SetStateCommand from './SetStateCommand';

/**
 * Initiates the view/state for creating a Site
 */
class CreateSiteCommand extends Command {
	
	/**
	 * Handles execution of the command
	 */
	handleExecute() {
		const setState = new SetStateCommand( AdminStates.ADMIN_CONFIGURATION_SITES_CREATE );
		setState.execute();
	}
	
}

//

export default CreateSiteCommand;
