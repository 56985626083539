/*
 * (c) Verra Technology Corporation
 */

import ServiceRequestCommand from '../ServiceRequestCommand';
import SphereAdminServices from '../../model/SphereAdminServices';
import ModifiableObject from '../../../model/ModifiableObject.mjs';
import SphereAdminSession from '../../model/SphereAdminSession';

/**
 * Saves an Optimization
 */
class SaveOptimizationRequest extends ServiceRequestCommand {

	/**
	 * Constructs the Request
	 * @param optimization The Optimization to save
	 */
	constructor( optimization ){
		super();
		this.optimization = optimization;
	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
    getService() {
		return SphereAdminServices.SAVE_OPTIMIZATION;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		return { optimization: this.optimization };
	}

   /**
	* Handles completion of the command
	*/
	handleComplete() {
		const response = this.getResponse();
		if( response.success ) {
			this.optimization.status = ModifiableObject.SAVED; // TODO: should this come from the DB?
			SphereAdminSession.optimizations.push( this.optimization );
		}
		//console.info( 'SaveContentRequest::handleComplete content', this.content );
	}
 
}

export default SaveOptimizationRequest;
