/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';

/**
 * Hint Component. Renders a small question mark and tool tip to display contextual help 
 * content to the user
 */
class Hint extends Component {

	/**
	 * 
	 */
	constructor() {
		super();
		this.state = {
			tooTipOpen: false
		};
	}
	
	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {

		// default 'right' positioning
		var top = 'auto';
		var right = 'auto';
		var bottom = '0px';
		var left = '20px';

		if( this.props.position === 'left' ) {
			left = 'auto';
			right = '20px';
		}

		var style = { 
			display: (( this.state.tooTipOpen ) ? 'inline' : 'none' ), 
			width: this.props.width,
			top: top,
			right: right,
			bottom: bottom,
			left: left
		};

		return ( 
			<div className='hint'>
				<span onMouseMove={handleOver.bind( this )} onMouseOut={handleOut.bind( this )}>?</span>
				<div className='tool-tip' style={style}>
					{this.props.content}
				</div>
			</div>
		);
	}

}

//

/**
 * Handles mouse over events
 * TODO: consider mobile
 */
function handleOver( e ) {
	this.setState({ tooTipOpen: true })
};

/**
 * Handles mouse over events
 * TODO: consider mobile
 */
function handleOut( e ) {
	this.setState({ tooTipOpen: false })
};

//

export default Hint;
