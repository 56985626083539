/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import TopPerformersChart from '../components/charts/TopPerformersChart';
import LayersIcon from '../icons/LayersIcon';
import ScreenIcon from '../icons/ScreenIcon';
import LargePencilIcon from '../icons/LargePencilIcon';
import PageIcon from '../icons/PageIcon';
import MatchPanelsHeightCommand from '../commands/MatchPanelsHeightCommand';
import AdminStates from '../model/AdminStates';
import SetStateCommand from '../commands/SetStateCommand';

//

/**
 * The administration dashboard
 */
class DashboardPanel extends Component {
	
	/**
	 * Constructs the Component
	 */
	constructor(){
		super();
		this.commonTasksRef = React.createRef();
		this.otherTasksRef = React.createRef();
		window.addEventListener( 'resize', this.#resizePanels.bind( this ));
	}

	/**
	 * Renders the component
	 * @see react docs
	 */
	render(){

		return ( 
			<div className='content-panel'>
				<div className='grid'>
					<div className='grid-cell default-100'>
						<h2>Dashboard</h2>
					</div>
					{/*
					<div className='grid-cell default-100'>
						<div className='panel-cell'>
							<h3>Getting Started</h3>
							<p>Welcome to Verra! If you're new to the platform we suggest getting started with the following videos.</p>
							<iframe 
								src="https://www.youtube.com/embed/bhiR4A4zMLg" 
								title="Introduction to Verra" 
								frameBorder="0" 
								allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
								allowFullScreen>
							</iframe>
						</div>
					</div>
					<div className='grid-cell default-50 start-row'>
						<div ref={this.impressionsContainerRef} className='panel-cell'>
							<TopPerformersChart/>
						</div>
					</div>
					*/}
					<div className='grid-cell default-50 start-row'>
						<div ref={this.commonTasksRef} className='panel-cell'>
							<h3>Common Tasks</h3>
							<div style={{ textAlign: 'center '}}>
								{/*
								<div className='dashboard-task-icon'>
									<div className='icon'>
										<PerformanceIcon/>
									</div>
									<div className='label'>View Metrics</div>
								</div>
								*/}
								<button className='dashboard-task-icon' onClick={() => this.#handleTask( AdminStates.ADMIN_CHANNELS_CREATE )}>
									<div className='icon'>
										<LargePencilIcon/>
									</div>
									<div className='label'>Create Channel</div>
								</button>
								<button className='dashboard-task-icon' onClick={() => this.#handleTask( AdminStates.ADMIN_CHANNELS_BROWSE )}>
									<div className='icon'>
										<LayersIcon/>
									</div>
									<div className='label'>View Channels</div>
								</button>
								<button className='dashboard-task-icon pad-control-left' onClick={() => this.#handleTask( AdminStates.ADMIN_CONTENT_BROWSE )}>
									<div className='icon'>
										<PageIcon/>
									</div>
									<div className='label'>Search Content</div>
								</button>
								<button className='dashboard-task-icon pad-control-left' onClick={() => this.#handleTask( AdminStates.ADMIN_CONFIGURATION_SITES )}>
									<div className='icon'>
										<ScreenIcon/>
									</div>
									<div className='label'>Manage Sites</div>
								</button>
							</div>
						</div>
					</div>
					<div className='grid-cell default-50 pad-cell-left'>
						<div ref={this.otherTasksRef} className='panel-cell'>
							<h3>Other Tasks</h3>
							<ul>
								<li><button className='link-button' onClick={() => this.#handleTask( AdminStates.ADMIN_CHANNELS_BROWSE )}>View Channels</button></li>
								<li><button className='link-button' onClick={() => this.#handleTask( AdminStates.ADMIN_CHANNELS_CREATE )}>Create New Channel</button></li>
								<li><button className='link-button' onClick={() => this.#handleTask( AdminStates.ADMIN_CONTENT_BROWSE )}>View Content</button></li>
								<li><button className='link-button' onClick={() => this.#handleTask( AdminStates.ADMIN_CONTENT_CREATE )}>Create New Content</button></li>
								<li><button className='link-button' onClick={() => this.#handleTask( AdminStates.ADMIN_CONFIGURATION_SITES )}>Manage Sites</button></li>
								<li><button className='link-button' onClick={() => this.#handleTask( AdminStates.ADMIN_CONFIGURATION_EVENTS )}>Manage Events</button></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		);
	}

	/**
	 * Lifecycle method @see React
	 */
	componentDidMount(){
		this.#resizePanels();
	}

	//

	/**
	 * Resizes the admin panels keeping the UI looking hot
	 */
	#resizePanels(){
		var resizePanels = new MatchPanelsHeightCommand( 
			[ this.commonTasksRef.current, this.otherTasksRef.current ]
		);
		resizePanels.execute();
	}

	/**
	 * Handles clicks on the tasks buttons / links
	 */
	#handleTask( state ){
		var setState = new SetStateCommand( state );
		setState.execute();
	}

}

export default DashboardPanel;
