/*
 * (c) Verra Technology Corporation
 */

import React, { Component } from 'react';
import DataGrid from './DataGrid';
import InputField from './InputField';
import ArrowLeftIcon from '../../icons/ArrowLeftIcon';
import ArrowRightIcon from '../../icons/ArrowRightIcon';
import Loader from './Loader';
import Alert from './Alert';
import OpenModalCommand from '../../commands/OpenModalCommand';
import SphereAdminSession from '../../model/SphereAdminSession';
import SearchItemsRequest from '../../requests/SearchItemsRequest';
import DeleteItemsRequest from '../../requests/DeleteItemsRequest';

/**
 * The ItemListPanel renders a searchable list of objects
 */
class ItemListPanel extends Component {
	
	/**
	 * Constructs the ContentPanel.
	 */
	constructor( props ) {
		super( props );
		
		this.state = {
			loading: false,
			hasSearched: false,
			searching: false,
			itemsSelected: false,
			itemsList: { pageStart: 0, pageSize: 20, count: 0, items: [] }
		};

		this.searchField = React.createRef();
		this.itemsGrid = React.createRef();
	}

	/**
	 * Handles component update events
	 * @see react docs
	 */
	componentDidMount() {
		if( this.props.retrieveOnLoad ) this.#retrieveItems( this.state.itemsList.pageStart, this.state.itemsList.pageSize );
	}

	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		const itemsList = this.state.itemsList;
		const items = itemsList.items;

		const pageStart = itemsList.pageStart + 1;
		const pageEnd = Math.min( itemsList.pageStart + itemsList.pageSize, itemsList.count );
		const hasPages = itemsList.count > itemsList.pageSize;
		const hasPrevPages = ( hasPages && itemsList.pageStart > 0 );
		const hasNextPages = ( hasPages && pageEnd < itemsList.count );

		const prevDisabled = ( hasPrevPages ) ? '' : ' disabled';
		const nextDisabled = ( hasNextPages ) ? '' : ' disabled';

		let createBtn = '';
		let deleteBtn = '';

		if( this.props.showControls ){
			const deleteDisabled = ( this.state.itemsSelected ) ? '' : ' disabled';
			createBtn = <div className='grid-cell'>
				<button className='button' onClick={ this.#handleCreateItem.bind( this ) }>Create</button>
			</div>;
			deleteBtn = <div className='grid-cell pad-cell-left'>
				<button className={ 'button' + deleteDisabled } onClick={ this.#handleDelete.bind( this ) }>Delete</button>
			</div>;
 		}

		const searchInputPadding = ( this.props.showControls ) ? 'pad-cell-left' : '';
		const showCheckColumn = ( !this.props.singleSelect || this.props.singleSelect == null );
		const maxBodyHeight = ( this.props.maxBodyHeight != null ) ? this.props.maxBodyHeight : 'auto';
		const statusIndicator = ( this.props.statusIndicator != null ) ? this.props.statusIndicator : true;

		const emptyContentMarkup = ( this.state.searching ) ? <Loader/> : 
			( !this.state.hasSearched ) ? '' : this.props.noItemsContent;

		return (
			<div>
				<div className='grid'>
					{ createBtn }
					{ deleteBtn }
					<form onSubmit={ this.#handleSearch.bind( this ) }>
						<div className={ 'grid-cell default-40 ' + searchInputPadding }>
							<InputField id='content-search-input' placeholder='Search' ref={ this.searchField }/>
						</div>
						<div className='grid-cell'>
							<button className='button icon-button control-pad-left' onClick={ this.#handleSearch.bind( this ) }>
								<ArrowRightIcon/>
							</button>
						</div>
					</form>
					<div className='grid-cell-right pad-cell-left'>
						<span className='results-count'>{ pageStart } - { pageEnd } of { itemsList.count }</span>
						<button className={ 'button icon-button control-pad-left' + prevDisabled } onClick={ this.#handlePagePrev.bind( this )}>
							<ArrowLeftIcon/>
						</button>
						<button className={ 'button icon-button control-pad-left' + nextDisabled } onClick={ this.#handlePageNext.bind( this )}>
							<ArrowRightIcon/>
						</button>
					</div>
				</div>
				<div className='pad-cell-top'>
					<DataGrid 
						ref={ this.itemsGrid }
						headers={ this.props.headers } 
						data={ items } 
						idField='id' 
						checkColumn={ showCheckColumn } 
						statusIndicator={ statusIndicator } 
						statusField='status' 
						maxBodyHeight={ maxBodyHeight }
						noContent={ emptyContentMarkup }
						rowClickHandler={ this.props.clickHandler }
						checkBoxChangeHandler={ this.#handleGridCheckBoxSelect.bind( this )}/>
				</div>
			</div>
		);
	}

	/**
	 * Handles component update events
	 * @see react docs
	 */
	componentDidUpdate() {
		if( this.props.componentUpdateHandler != null ) this.props.componentUpdateHandler();
	}

	//

	/**
	 * Retrieves the items
	 */
	#retrieveItems( pageStart, pageSize ) {
		if( this.itemsGrid.current != null ) this.itemsGrid.current.unCheckAll();
		this.setState({ loading: true, searching: false, itemsSelected: false  });
		const retrieveItemsList = new SearchItemsRequest( this.props.listEndPoint, null, pageStart, pageSize );
		retrieveItemsList.execute(( command ) => { this.#handleItemsRetrieved( command ); } );
	}

	/**
	 * Searches content
	 */
	#searchItems( phrase, pageStart, pageSize ) {
		if( this.itemsGrid.current != null ) this.itemsGrid.current.unCheckAll();
		this.setState({ loading: true, searching: true, itemsSelected: false });
		const retrieveItemsList = new SearchItemsRequest( this.props.searchEndPoint, phrase, pageStart, pageSize );
		retrieveItemsList.execute(( command ) => { this.#handleItemsRetrieved( command ); } );
	}

	/**
	 * Handles the response from the request to retrieve content
	 */
	#handleItemsRetrieved( command ) {
		const itemsList = command.getResult();
		this.setState({ searching: false, hasSearched: true, loading: false, itemsList });
		if( this.props.retrievedHandler != null ) this.props.retrievedHandler( itemsList );
	};

	/**
	 * Handles the click to create a new item
	 */
	#handleCreateItem( e ){
		const createContent = new this.props.createCommand();
		createContent.execute();
	}

	/**
	 * Handles clicks on a checkbox in the Content grid
	 */
	#handleGridCheckBoxSelect( grid ) {
		const selected = grid.getChecked();
		this.setState({ itemsSelected: ( selected.length > 0 )});
		if( this.props.checkBoxChangeHandler != null ) this.props.checkBoxChangeHandler( selected );
	}

	/**
	 * Handles the click to delete items
	 */
	#handleDelete( e ) {
		if( this.state.itemsSelected ) {
			const alert = <Alert content='Are you sure you want to delete these items?' okHandler={ this.#handleConfirmDelete.bind( this ) }/>;
			const openModal = new OpenModalCommand( 'Are you sure?', alert, '500px', true );
			openModal.execute();
		}
	}

	/**
	 * Handles the click to create a new site
	 */
	#handleConfirmDelete( e ) {
		SphereAdminSession.loading = true;
		const selectedItems = this.itemsGrid.current.getChecked();
		const itemIds = selectedItems.map( item => item.id );
		const deleteItems = new DeleteItemsRequest( itemIds, this.props.deleteEndPoint );
		deleteItems.execute( command  => this.#handleDeleteComplete( command ));
	}

	/**
	 * Handles the click to create a new site
	 */
	#handleDeleteComplete( command ) {
		SphereAdminSession.loading = false;
		const itemsInUse = command.getItemsInUse();
		const deleteMessageHandler = ( this.props.deleteMessageHandler != null ) ? this.props.deleteMessageHandler( itemsInUse ) : this.#showCouldNotDeleteMessage( itemsInUse ); 

		// some of the content was deleted, reload the grid
		const selectedItems = this.itemsGrid.current.getChecked();
		if( selectedItems.length !== itemsInUse.length ){
			if( this.state.searching ) {
				const phrase = this.searchField.current.getValue();
				this.#searchItems( phrase, this.state.itemsList.pageStart, this.state.itemsList.pageSize );
			} else {
				this.#retrieveItems( this.state.itemsList.pageStart, this.state.itemsList.pageSize );
			}
		}
	}

	/**
	 * Shows messaging to the user regarding items that could not be deleted. This can be overridden by 
	 * passing in a deleteMessageHandler prop
	 */
	#showCouldNotDeleteMessage( itemsInUse ) {
		
		if( itemsInUse.length > 0 ){
			const inUseMarkup = itemsInUse.map( item => <li key={ item.id }>{ item.itemId} is used by the { item.usedByType } { item.usedById }</li> );
			const alertContent = <div>The following items could not be deleted because they are in use<ul>{ inUseMarkup }</ul></div>;
			const alert = <Alert content={ alertContent } showCancelBtn={ false }/>;
			const openModal = new OpenModalCommand( 'Could not delete items', alert, '700px', true );
			openModal.execute();
		}
	}


	/**
	 * Handles the click to create a new site
	 */
	#handleSearch( e ) {
		e.preventDefault();
		var phrase = this.searchField.current.getValue();
		if( phrase.replace( / /g, '' ) === '' ) {
			this.#retrieveItems( 0, this.state.itemsList.pageSize );
		} else {
			this.#searchItems( phrase, 0, this.state.itemsList.pageSize );
		}
	}

	/**
	 * Handles the click to create a new site
	 */
	#handlePagePrev( e ) {
		var btn = e.target.closest( 'button' );
		if( !btn.classList.contains( 'disabled' )){
			if( this.state.searching ) {
				var phrase = this.searchField.current.getValue();
				this.#searchItems( phrase, this.state.itemsList.pageStart - this.state.itemsList.pageSize, this.state.itemsList.pageSize );
			} else {
				this.#retrieveItems( this.state.itemsList.pageStart - this.state.itemsList.pageSize, this.state.itemsList.pageSize );
			}
		}
	}

	/**
	 * Handles the click to create a new site
	 */
	#handlePageNext( e ) {
		var btn = e.target.closest( 'button' );
		if( !btn.classList.contains( 'disabled' )){
			if( this.state.searching ){
				var phrase = this.searchField.current.getValue();
				this.#searchItems( phrase, this.state.itemsList.pageStart + this.state.itemsList.pageSize, this.state.itemsList.pageSize );
			} else {
				this.#retrieveItems( this.state.itemsList.pageStart + this.state.itemsList.pageSize, this.state.itemsList.pageSize );
			}
		}
	}

	//

	/**
	 * @return the elements selected
	 */
	getChecked() {
		return this.itemsGrid.current.getChecked();
	}

}

//

export default ItemListPanel;
