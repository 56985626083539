/*
 * (c) Verra Technology Corporation
 */

import CodeEditor from '@uiw/react-textarea-code-editor';
import React, { Component } from 'react';

import ExperienceModification from '../../../model/ExperienceModification';
import ModifiableObject from '../../../model/ModifiableObject.mjs';
import AdminStates from '../../model/AdminStates';
import CSSProperties from '../../model/CSSProperties';
import CSSPropertyType from '../../model/CSSPropertyType';
import SphereAdminSession from '../../model/SphereAdminSession';

import ExperienceModificationType from '../../../model/ExperienceModificationType';
import CreateExperienceCommand from '../../commands/CreateExperienceCommand';
import EditAudienceCommand from '../../commands/EditAudienceCommand';
import EditOptimizationCommand from '../../commands/EditOptimizationCommand';
import OpenModalCommand from '../../commands/OpenModalCommand';
import SetStateCommand from '../../commands/SetStateCommand';
import ValidatorCommand from '../../commands/ValidatorCommand';
import SaveExperienceRequest from '../../requests//experiences/SaveExperienceRequest';
import AddExperienceToOptimizationRequest from '../../requests/optimizations/AddExperienceToOptimizationRequest';
import StylePropertyPicker from '../controls/StylePropertyPicker';

import Alert from '../controls/Alert';
import ColorPicker from '../controls/ColorPicker';
import DropDownField from '../controls/DropDownField';
import Hint from '../controls/Hint';
import InputField from '../controls/InputField';

import ElementUtil from '../../../util/ElementUtil';
import AddIcon from '../../icons/AddIcon';
import ArrowDownIcon from '../../icons/ArrowDownIcon';
import ArrowLeftIcon from '../../icons/ArrowLeftIcon';
import ArrowRightIcon from '../../icons/ArrowRightIcon';
import ArrowUpIcon from '../../icons/ArrowUpIcon';
import CancelIcon from '../../icons/CancelIcon';
import CodeIcon from '../../icons/CodeIcon';
import CopyIcon from '../../icons/CopyIcon';
import CssIcon from '../../icons/CssIcon';
import EditIcon from '../../icons/EditIcon';
import JavaScriptIcon from '../../icons/JavaScriptIcon';
import MoveIcon from '../../icons/MoveIcon';
import NotesIcon from '../../icons/NotesIcon';
import RemoveIcon from '../../icons/RemoveIcon';
import TextColorIcon from '../../icons/TextColorIcon';
import VisibilityIcon from '../../icons/VisibilityIcon';
import VisibilityOffIcon from '../../icons/VisibilityOffIcon';
import ObjectStatusMap from '../../model/ObjectStatusMap';

//

/**
 * Defines labels for the modification types
 */
const modificationTypeIcons = {
	'0': <NotesIcon size='24' color='#ffffff'/>,
	'1': <CodeIcon size='24' color='#ffffff'/>,
	'2': <TextColorIcon size='24' color='#ffffff'/>,
	'3': <MoveIcon size='24' color='#ffffff'/>,
	'4': <AddIcon size='24' color='#ffffff'/>,
	'5': <RemoveIcon size='24' color='#ffffff'/>,
	'6': <CssIcon size='24' color='#ffffff'/>,
	'7': <JavaScriptIcon size='24' color='#ffffff'/>,
};

//

/**
 * Provides UI for creating and editing experiences
 */
class ExperienceEditor extends Component {

	//

	/**
	 * Constructs the ContentPanel.
	 */
	constructor() {
		super();

		// console.info( 'ExperienceEditor', SphereAdminSession.optimization, SphereAdminSession.experience );

		this.state = { 
			selectedSite: null, 
			path: '', 
			querySelector: null,
			primaryElement: null,
			selectedElements: null,
			editingMode: null,
			editorContent: '',
			codeInEditorIsInvalid: false,

			showModificationsList: false,
			modificationsWidth: 350,
			editorHeight: 200,

			viewChanges: true,
			frameLoaded: false
		};

		this.highlightedElement = null;

		// UI

		this.controlsContainerRef = React.createRef();
		this.iFrameContainerRef = React.createRef();
		this.iFrameRef = React.createRef();
		this.hoverHighlight = React.createRef();
		this.selectedHighlightsContainer = React.createRef();

		// Editing
		this.#buildModificationTypeConfig();

		// Events
		
		this.upListener = this.#stopResize.bind( this );
		this.moveListener = this.#resize.bind( this );

		if( window.location !== window.parent.location ){
			window.addEventListener( 'message', this.#handleWindowMessage.bind( this ));
			window.parent.postMessage({ action: 'GET_SITE' }, '*' );
		}

		window.addEventListener( 'resize', this.#handleResize.bind( this ) );
		this.componentDidUpdate(); // force a resize
		
	}

	/**
	 * Builds the configuration object used to render and manage the different modification types
	 */
	#buildModificationTypeConfig() {
		// Maps modification types to configuration within the editor
		this.modificationTypeConfig = {};
		this.modificationTypeConfig[ ExperienceModificationType.TEXT ] = {
			render: this.#getTextEditorMarkup,
			getValue: this.#getElementTextContent,
			handleChange: this.#handleTextChanged
		};

		this.modificationTypeConfig[ ExperienceModificationType.MARKUP ] = {
			render: this.#getCodeEditorMarkup,
			language: 'html',
			getValue: this.#getElementMarkupContent,
			handleChange: this.#handleMarkupChanged
		};

		this.modificationTypeConfig[ ExperienceModificationType.STYLES ] = {
			render: this.#getStylesEditorMarkup,
			language: 'css',
			getValue: this.#getElementMarkupContent,
			handleChange: this.#handleMarkupChanged
		};

		this.modificationTypeConfig[ ExperienceModificationType.CSS ] = {
			render: this.#getCodeEditorMarkup,
			language: 'css',
			getValue: this.#getCssContent,
			handleChange: this.#handleCssChanged
		};

		this.modificationTypeConfig[ ExperienceModificationType.JS ] = {
			render: this.#getCodeEditorMarkup,
			language: 'javascript',
			getValue: this.#getJsContent,
			handleChange: this.#handleJsChanged
		};
	}

	/**
	 * Handles the mounting of the component
	 */
	componentDidUpdate() {
		setTimeout( () => { this.#handleResize(); }, 200 );
	}
	
	/**
	 * Renders the component
	 * @see react docs
	 */
	render() {
		let markup = '';
		if( SphereAdminSession.experience != null ) {
			markup = this.#getExperienceEditorMarkup();
		} else {
			markup = this.#getNoExperienceMarkup();
		}
		return markup;
	}

	// Rendering

	/**
	 * @return the full Experience Editor markup
	 */
	#getExperienceEditorMarkup() {
		const editorConfig = this.modificationTypeConfig[ this.state.editingMode ];
		const editorRenderer = ( editorConfig != null ) ? editorConfig.render : null;
		return <div className='content-panel experience-editor no-select'>
					{ this.#getHeaderMarkup() }
					{ this.#getPrimaryFieldsMarkup() }
					{ this.#getControlsMarkup() }
					<div style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'stretch', flexShrink: 0 }}>
						{ this.state.showModificationsList && this.#getModificationsListMarkup() }
						{ this.state.showModificationsList && 
							<div 
								className='vertical-resize-handle no-select' 
								style={{ alignSelf: 'center' }}
								onMouseDown={ this.#startModificationsListResize.bind( this )}></div>
						}
						<div style={{ flexGrow: 1, overflow: 'auto' }}>
							{ editorRenderer != null && editorRenderer.apply( this ) }
							{ editorRenderer != null && <hr className='resize-handle no-select' onMouseDown={ this.#startEditorResize.bind( this )}/> }
							{ this.#getFrameMarkup() }
						</div>
					</div>
				</div>;
	}

	/**
	 * Gets the markup for the header section
	 */
	#getHeaderMarkup() {
		const experience = SphereAdminSession.experience;
		const isLocked = ( experience?.status === ModifiableObject.LOCKED );
		const isEditing = ( SphereAdminSession.currentState === AdminStates.ADMIN_EXPERIENCES_EDIT);
		const title = ( isEditing ) ? 'Edit Experience' : 'Create Experience';

		const isFromOptimization = ( SphereAdminSession.optimizationId != null ); // ( SphereAdminSession.currentState === AdminStates.ADMIN_EXPERIENCES_CREATE_FOR_OPTIMIZATION );
		// const breadcrumb = ( isFromOptimization ) ? 
		// 		<div className='breadcrumb'><a href='/optimization/create/'>{ SphereAdminSession.optimization.name }</a> / { experience.name }</div> : 
		// 		<div className='breadcrumb'><a href='/experiences/'>Experiences</a> / { experience.name }</div>;

		const breadcrumb = <div className='breadcrumb'><a href='/experiences/'>Experiences</a> / { experience?.name }</div>;

		const saveDisabled = ( experience?.status === ModifiableObject.SAVED );
		const saveButtonsDisabledClass = ( saveDisabled ) ? ' disabled' : '';

		return 	<div>
					<div className='grid'>
						<div className='grid-cell default-50'>
							<h2>{title}</h2>
							{breadcrumb}
						</div>
						{ experience != null &&
							<div className='grid-cell default-50 align-right header-actions'>
								{ !isFromOptimization && !isLocked &&
									<button
										className={ 'primary-button control-pad-left' + saveButtonsDisabledClass }
										disabled={ saveDisabled }
										style={{ width: '80px' }}
										onClick={ this.#handleSave.bind( this )}>
											Save
									</button>
								}
								{ isFromOptimization &&
									<button
										className={ 'primary-button control-pad-left' }
										style={{ width: '200px' }}
										onClick={ this.#handleSaveAndAdd.bind( this )}>
											Add to Optimization
									</button>
								}
								{ isFromOptimization &&
									<button
										className={ 'button control-pad-left' + saveButtonsDisabledClass }
										disabled={ saveDisabled }
										style={{ width: '80px' }}
										onClick={ this.#handleSave.bind( this )}>
											Save
									</button>
								}
								<button className={ 'button control-pad-left' } style={{ width: '80px' }} onClick={ this.#handleCancel.bind( this )}>Cancel</button>
							</div>
						}
					</div>
					{ experience != null && isLocked && 
						<div className='panel-cell'>The Experience is in use by a published optimization</div>
					}
				</div>;
	}

	/**
	 * Gets the primary fields markup
	 */
	#getPrimaryFieldsMarkup() {
		const experience = SphereAdminSession.experience;
		const isLocked = ( experience.status === ModifiableObject.LOCKED );

		const nameToolTip = 'User friendly name for the Experience.';
		const status = ObjectStatusMap[ experience.status ];
		const siteTooltip = 'The site in which the changes will apply';

		return <div>
					<div className='grid panel-cell primary-fields'>
						<div className='grid-cell default-80'>
						<div className={ 'status-indicatator ' + status }></div>
							<label>Name <Hint width='250px' content={ nameToolTip }/></label>
							<InputField 
								value={ experience.name } 
								maxLength='256' 
								disabled={ isLocked } 
								onChange={( value ) => { this.#handleFieldChanged( 'name', value ); }}/>
						</div>
						<div className='grid-cell default-20 pad-cell-left'>
							<label>Site <Hint width='250px' content={ siteTooltip }/></label>
							<DropDownField 
								labelField='name' 
								items={ SphereAdminSession.sites }
								selectedItem={ this.state.selectedSite }
								disabled={ isLocked } 
								changeHandler={ this.#handleSiteSelected.bind( this )}/>
						</div>
					</div>
				</div>;
	}

	/**
	 * The markup for the experience controls
	 */
	#getControlsMarkup() {
		const experience = SphereAdminSession.experience;
		const isLocked = ( experience.status === ModifiableObject.LOCKED );

		const selectedElements = this.state.selectedElements;
		const primaryElement = this.state.primaryElement;
		const isSingleElement = ( selectedElements?.length === 1 );
		const previewEnabled = ( this.state.viewChanges );

		const controlDisabled = ( selectedElements != null && previewEnabled ) ? '' : 'disabled';
		const globalBtnsDisabled = ( previewEnabled ) ? '' : 'disabled';

		const prevDisabled = ( previewEnabled && isSingleElement && primaryElement.previousElementSibling != null ) ? '' : 'disabled';
		const nextDisabled = ( previewEnabled && isSingleElement && primaryElement.nextElementSibling != null ) ? '' : 'disabled';
		const upDisabled = ( previewEnabled && isSingleElement && primaryElement.parentElement != null ) ? '' : 'disabled';
		const downDisabled = ( previewEnabled && isSingleElement && primaryElement.firstElementChild != null ) ? '' : 'disabled';
		const textDisabled = ( previewEnabled && primaryElement != null && primaryElement.firstElementChild == null && primaryElement.firstChild != null && primaryElement.firstChild.nodeType === 3 ) ? '' : 'disabled';
		
		const textSelected = ( this.state.editingMode === ExperienceModificationType.TEXT  ) ? 'selected' : '';
		const markupSelected = ( this.state.editingMode === ExperienceModificationType.MARKUP ) ? 'selected' : '';
		const stylesSelected = ( this.state.editingMode === ExperienceModificationType.STYLES ) ? 'selected' : '';
		const cssSelected = ( this.state.editingMode === ExperienceModificationType.CSS ) ? 'selected' : '';
		const jsSelected = ( this.state.editingMode === ExperienceModificationType.JS ) ? 'selected' : '';

		return 	<div>
					{ !isLocked && 
						<div ref={ this.controlsContainerRef } className='panel-cell experience-editor-controls'>
							<button
								className={ 'control-button control-pad-right' + prevDisabled }
								onClick={ this.#handleToggleModificationsList.bind( this )}>
									<EditIcon size='26' color='#ffffff'/>
							</button>
							<button
								className={ 'control-button control-pad-left ' + prevDisabled }
								onClick={ e => this.#handleElementNavigation( primaryElement.previousElementSibling )}>
									<ArrowLeftIcon size='26' color='#ffffff'/>
							</button>
							<button
								className={ 'control-button control-pad-left ' + nextDisabled }
								onClick={ e => this.#handleElementNavigation( primaryElement.nextElementSibling )}>
									<ArrowRightIcon size='26' color='#ffffff'/>
							</button>
							<button
								className={ 'control-button control-pad-left ' + upDisabled }
								onClick={ e => this.#handleElementNavigation( primaryElement.parentElement )}>
									<ArrowUpIcon size='26' color='#ffffff'/>
							</button>
							<button
								className={ 'control-button control-pad-left control-pad-right ' + downDisabled }
								onClick={ e => this.#handleElementNavigation( primaryElement.firstElementChild )}>
									<ArrowDownIcon size='26' color='#ffffff'/>
							</button>
							<button
								className= { `control-button control-pad-left ${ textDisabled } ${ textSelected }` }
								onClick={ e => this.#handleToggleEditor( ExperienceModificationType.TEXT ) }>
									<NotesIcon size='26' color='#ffffff'/>
							</button>
							<button
								className={ `control-button control-pad-left ${ controlDisabled } ${ markupSelected }` }
								onClick={ e => this.#handleToggleEditor( ExperienceModificationType.MARKUP ) }>
									<CodeIcon size='26' color='#ffffff'/>
							</button>
							<button
								className={ `control-button control-pad-left control-pad-right ${ controlDisabled } ${ stylesSelected }` }
								onClick={ e => this.#handleToggleEditor( ExperienceModificationType.STYLES )}>
									<TextColorIcon size='24' color='#ffffff'/>
							</button>
							<button
								className={ 'control-button control-pad-left ' + controlDisabled }
								onClick={ e => this.#handleElementNavigation( primaryElement.firstElementChild )}>
									<MoveIcon size='26' color='#ffffff'/>
							</button>
							<button
								className={ 'control-button control-pad-left ' + controlDisabled }
								onClick={ this.#handleAddElement.bind( this )}>
									<AddIcon size='26' color='#ffffff'/>
							</button>
							<button
								className={ `control-button control-pad-left ${ controlDisabled }` }
								onClick={ this.#handleRemoveElement.bind( this  ) }>
									<RemoveIcon size='26' color='#ffffff'/>
							</button>
							<button
								className={ `control-button control-pad-left ${ globalBtnsDisabled } ${ cssSelected }` }
								onClick={ e => this.#handleToggleEditor( ExperienceModificationType.CSS ) }>
									<CssIcon size='26' color='#ffffff'/>
							</button>
							<button
								className={ `control-button control-pad-left ${ globalBtnsDisabled } ${ jsSelected }` }
								onClick={ e => this.#handleToggleEditor( ExperienceModificationType.JS ) }>
									<JavaScriptIcon size='26' color='#ffffff'/>
							</button>
							<button
								className={ 'control-button control-pad-left ' }
								onClick={ e => this.#handleTogglePreview( !this.state.viewChanges ) }>
									{ !this.state.viewChanges && <VisibilityIcon size='22' color='#ffffff'/> }
									{ this.state.viewChanges && <VisibilityOffIcon size='22' color='#ffffff'/> }
							</button>
							<div id='selected-element-path'>
								<div
									className='input'
									contentEditable={ true }
									suppressContentEditableWarning={ true }
									onBlur={ e => this.#handleElementPathInputChanged( e.target.textContent ) }>
										{ this.state.querySelector }
									</div>
								<button onClick={ this.#handleCopyElementPath.bind( this )}>
									<CopyIcon size={ 13 } color='#ffffff'/>
								</button>
							</div>
						</div>
					}
					{ isLocked &&
						<div ref={ this.controlsContainerRef } className='panel-cell experience-editor-controls'>
							<button
								className={ 'control-button control-pad-right' + prevDisabled }
								onClick={ this.#handleToggleModificationsList.bind( this )}>
									<EditIcon size='26' color='#ffffff'/>
							</button>
							<button
								className={ 'control-button control-pad-left ' }
								onClick={ e => this.#handleTogglePreview( !this.state.viewChanges ) }>
									{ !this.state.viewChanges && <VisibilityIcon size='22' color='#ffffff'/> }
									{ this.state.viewChanges && <VisibilityOffIcon size='22' color='#ffffff'/> }
							</button>
						</div>
					}
				</div>;
	}

	/**
	 * The markup for the modifications list
	 */
	#getModificationsListMarkup() {
		const experience = SphereAdminSession.experience;
		const isLocked = ( experience.status === ModifiableObject.LOCKED );
		const modifications = SphereAdminSession.experience.modifications;
		const modsList = [];
		Object.keys( modifications ).forEach( key => {
			const modification = modifications[ key ];
			const modificationSelected = ( modification.path === this.state.querySelector );
			const itemHighlight = ( modificationSelected ) ? 'highlighted' : '';
			modsList.push( 
				<div className={ 'modification-item ' + itemHighlight } onClick={ e => this.#handleSelectModification( key )}>
					<div>{ modificationTypeIcons[ modification.type ]} </div>
					<div style={{ flexGrow: 2 }}>{ ExperienceModificationType.MODIFICATION_TYPE_LABELS[ modification.type ]} </div>
					<button className='control-button view' onClick={ e => this.#handleTogglePreviewOfModification( e, key )}>
						{ !modification.isVisible && 
							<VisibilityIcon size={ 18 }/>
						}
						{ modification.isVisible && 
							<VisibilityOffIcon size={ 18 }/>
						}
					</button>
					{ !isLocked && 
						<button className='control-button remove' onClick={ e => this.#handleRemoveModification( e, key )}>
							<CancelIcon size={ 18 }/>
						</button>
					}
					{ modification.type !== ExperienceModificationType.CSS && modification.type !== ExperienceModificationType.JS && 
						<div className={ 'modification-path ' }>{ modification.path }</div>
					}
				</div>
			)
		});
		return 	<div 
					className='panel-cell modifications-list' 
					style={{ maxWidth: this.state.modificationsWidth, minWidth: this.state.modificationsWidth, marginRight: 13, overflowY: 'auto' }}>
					{ modsList }
				</div>
	}

	/**
	 * @return The markup for displaying the text editor
	 */
	#getTextEditorMarkup() {
		const editorConfig = this.modificationTypeConfig[ this.state.editingMode ];
		return 	<div 
					className='panel-cell no-select'>
					<div className={ 'experience-text-editor'} >
						<textarea
							value={ editorConfig.getValue.apply( this )}
							onChange={ editorConfig.handleChange.bind( this ) }
							style={{ height: this.state.editorHeight }}
						/>
					</div>
				</div>;
	}

	/**
	 * @return The markup for displaying the code editor
	 */
	#getCodeEditorMarkup() {
		const editorConfig = this.modificationTypeConfig[ this.state.editingMode ];
		const hasInvalidCodeClass = ( this.state.codeInEditorIsInvalid ) ? ' invalid' : '';
		return 	<div className='panel-cell no-select'>
					<div 
						className={ 'experience-code-editor ' + hasInvalidCodeClass }
						style={{ height: this.state.editorHeight, overflowX: 'auto', overflowY: 'auto', backgroundColor: '#1b1b1b', }}>
						<CodeEditor
							value={ editorConfig.getValue.apply( this ) }
							language={ editorConfig.language }
							data-color-mode='dark'
							onChange={ editorConfig.handleChange.bind( this ) }
							padding={ 15 }
							style={{
								minHeight: this.state.editorHeight,
								fontSize: 12,
								backgroundColor: '#1b1b1b',
								fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
							}}
						/>
					</div>
				</div>;
	}

	/**
	 * @return The markup for displaying style controls
	 */
	#getStylesEditorMarkup() {
		// const editorConfig = this.modificationTypeConfig[ this.state.editingMode ];
		const modifications = SphereAdminSession.experience.modifications;
		const key = `${ ExperienceModificationType.STYLES }-${ this.state.querySelector }`;
		const modification = modifications[ key ];

		const disabled = ( this.state.selectedElements?.length === 0 );
		const disabledStyle = ( disabled ) ? 'disabled' : '';

		const propertyControls = [];
		if( modification != null ) {
			Object.keys( modification.value ).forEach(( styleProp, index ) => {

				const value = modification.value[ styleProp ].value;
				const elementValueStatus = ( value == null || value === '' ) ? 'no-value' : '';
				const styleDefinition = CSSProperties[ styleProp ];
				let valueElement;

				if( styleDefinition.type === CSSPropertyType.OPTION ) {
					valueElement = <DropDownField 
										label='choose value'
										items={ styleDefinition.options } 
										selectedItem={ value } 
										hideBackground={ true } 
										hideButton={ true }
										labelAlignRight={ true }
										positionTop={ true }
										disabled={ disabled }
										style={{ width: '100%' }}
										changeHandler={ value => this.#handleStyleChanged( styleProp, value )}/>;
				} else if( styleDefinition.type === CSSPropertyType.TEXT ) {
					valueElement = 	<InputField 
										className={ elementValueStatus }
										defaultValue={ value } 
										placeholder='enter value'
										style={{ height: 30, textAlign: 'right' }}
										disabled={ disabled }
										onChange={ value => this.#handleStyleChanged( styleProp, value )}/>;
				} else if( styleDefinition.type === CSSPropertyType.COLOR ){
					valueElement = 	<div style={{ width: '100%', padding: '0 8px 0 0', textAlign: 'right' }}>
										<ColorPicker 
											color={ value }
											disabled={ disabled }
											onChange={ value => this.#handleStyleChanged( styleProp, value )}/>
									</div>;
				}

				propertyControls.push( 
					<div key={ index++ } className={ `property-control` }>
						<label style={{ flexBasis: '33%' }}>{ styleProp }</label>
						{ valueElement }
						<button className='remove-button' onClick={ e => this.#handleRemoveStyleProperty( styleProp )}>
							<CancelIcon size={ 18 }/>
						</button>
					</div>
				); 
			});
		}

		propertyControls.push(
			<div key='property-picker' className={ `property-control` }>
				<StylePropertyPicker showLabel={ propertyControls.length === 0 } selectHandler={ this.#handleAddNewStyleProperty.bind( this )}/>
			</div>
		);

		return 	<div className='pad-cell-top no-select'>
					<div className='experience-styles-editor' style={{ height: this.state.editorHeight }}>
						<div className={ `property-controls ${ disabledStyle }` } style={{ height: this.state.editorHeight }}>{ propertyControls }</div>
					</div>
				</div>;
	}

	/**
	 * @return The markup for displaying secondary fields
	 */
	#getFrameMarkup() {
		const previewEnabled = ( this.state.viewChanges );
		const hightlightDisplay = ( previewEnabled && this.highlightedElement != null ) ? 'block' : 'none';
		const selectedDisplay = ( previewEnabled && this.state.selectedElements?.length > 0 ) ? 'block' : 'none';
		const siteBaseUrl = ( this.state.selectedSite != null ) ? this.state.selectedSite.url : '';
		const path = siteBaseUrl + this.state.path;
		const frameDisplay = ( this.state.frameLoaded ) ? 'block' : 'none';

		return 	<div className='panel-cell pad-cell-left' style={{ display: frameDisplay }}>
					<div className='grid pad-cell-bottom'>
						<div className='grid-cell default-90'>
							<InputField value={ path } onChange={ value => this.state.path = value }/>
						</div>
						<div className='grid-cell default-10 pad-cell-left'>
							<button style={{ width: '100%' }} className={ 'button' } onClick={ this.#handleNavigateToPage.bind( this )}>Go</button>
						</div>
					</div>
					<div ref={ this.iFrameContainerRef } className='no-select' style={{ position: 'relative', overflow: 'hidden' }}>
						<div ref={ this.hoverHighlight } className='hover-highlight' style={{ display: hightlightDisplay }}></div>
						<div ref={ this.selectedHighlightsContainer } style={{ display: selectedDisplay }}></div>
						<iframe
							ref={ this.iFrameRef }
							width='100%'
							onLoad={ this.#handleIFrameLoaded.bind( this )}
							onMouseUp={ this.upListener }
							onMouseOut={ this.#stopResize.bind( this )}/>
					</div>
				</div>;
	}

	/**
	 * @return The markup for when no Experience can be found
	 */
	#getNoExperienceMarkup() {
		return 	<div className='content-panel experience-editor no-select'>
					{ this.#getHeaderMarkup() }
					<div className='panel-cell grid-cell default-100' style={{ textAlign: 'center' }}>
						Experience could not be found. <button className='link-button' onClick={ this.#handleCreateExperience.bind( this )}>Create Experience</button>
					</div>
				</div>;
	}

	// Primary Field Handlers
	
	/**
	 * Handles a selection of a site from the Sites drop down
	 */
	#handleSiteSelected( site ) {
		// console.info( 'handleSiteSelected', site );
		SphereAdminSession.experience.siteId = site.id;
		this.#navigateToPage( site, this.state.path );
	}

	/**
	 * Handles a selection of a site from the Sites drop down
	 */
	#handleNavigateToPage() {
		this.#navigateToPage( this.state.selectedSite, this.state.path );
	}

	/**
	 * Navigates the iframe to a page
	 */
	#navigateToPage( site, path ) {
		const mode = ( SphereAdminSession.currentState === AdminStates.ADMIN_EXPERIENCES_EDIT ) ? 'edit' : 'create';
		let url = `https://${ site.url }${ path }?verra-edit-mode=${ mode }&verra-site-id=${ site.id }&verra-experience-id=${ SphereAdminSession.experience.id }&verra-id=${ SphereAdminSession.token }`;
		if( SphereAdminSession.optimizationId != null ) url += `&verra-optimization-id=${ SphereAdminSession.optimizationId }`;
		// console.info( 'navigate', SphereAdminSession.experience.id );
		this.#saveToStorage();
		window.parent.postMessage({ action: 'NAVIGATE', url: url }, '*' );
	}

	/**
	 * Handles changes to the input fields, invalidating the Channel object
	 */
	#handleFieldChanged( field, value ) {
		// console.info( field, value );
		SphereAdminSession.experience.status = ModifiableObject.MODIFIED;
		SphereAdminSession.experience[ field ] = value;
		this.setState({}); // force a redraw
	};

	//

	/**
	 * Handles the click to toggle the modifications list
	 */
	#handleToggleModificationsList() {
		this.setState({ showModificationsList: !this.state.showModificationsList });
	}

	// HTML Navigation

	/**
	 * Navigates the selected content to a specifc element
	 */
	#handleElementNavigation( element ) {
		if( element != null ) {
			this.state.querySelector = ElementUtil.getQuerySelectorPath( element );
			this.#selectElements([ element ]);
		}
	}

	// Editing Handlers
	 
	/**
	 * Handles the opening and closing of the editor panel
	 */
	#handleToggleEditor( mode ) {
		const state = { editingMode: ( mode !== this.state.editingMode ) ? mode : null };
		this.setState( state );
	}

	// Preview

	/**
	 * Handles the toggle to enable or disable previewing
	 * @param viewChanges Whether or not to view or hide changes
	 */
	#handleTogglePreview( viewChanges ) {
		const experience = SphereAdminSession.experience;
		Object.keys( experience.modifications ).forEach(( key ) => {
			this.#toggleModification( key, viewChanges );
		});
		this.setState({ viewChanges, primaryElement: null, selectedElements: null });
	}

	/**
	 * Toggles the visibility of a modification
	 */
	#toggleModification( key, viewChanges ) {
		const modification = SphereAdminSession.experience.modifications[ key ];
		const iframe = this.iFrameRef.current;
		const prop = ( viewChanges ) ? 'value' : 'original';
		const elements = iframe.contentWindow.document.querySelectorAll( modification.path );
		const value = modification[ prop ];

		modification.isVisible = viewChanges;

		if( modification.type === ExperienceModificationType.TEXT && elements.length > 0 ) {

			elements.forEach(( element, index ) => element.textContent = ( viewChanges ) ? value : modification.original[ index ] );

		} else if( modification.type === ExperienceModificationType.MARKUP && elements.length > 0 ) {

			elements.forEach(( element, index ) => {
				const template = document.createElement( 'template' );
				template.innerHTML = ( viewChanges ) ? value : modification.original[ index ];
				const newElement = template.content.firstChild;
				element.replaceWith( newElement );
			});
			
		} else if( modification.type === ExperienceModificationType.STYLES ) {

			this.#applyStylesModification( key, modification, viewChanges );
		
		} else if( modification.type === ExperienceModificationType.REMOVE && elements.length > 0 ) {

			// for preview purposes we simply set the display to none, a live experience will remove it from the DOM
			elements.forEach( element => {
				if( !viewChanges && modification.original != null && modification.original !== '' ) {
					element.style.display = modification.original;
				} else if( !viewChanges ) {
					element.style.removeProperty( 'display' );
				} else {
					element.style.display = 'none';
				}
			});

		} else if ( modification.type === ExperienceModificationType.CSS ) {

			const iframe = this.iFrameRef.current;
			let styleElement = iframe.contentWindow.document.getElementById( 'verra-global-css' );
			if( styleElement == null ) {
				styleElement = document.createElement( 'style' );
				styleElement.setAttribute( 'id', 'verra-global-css' );
				iframe.contentWindow.document.head.appendChild( styleElement );
			}
			styleElement.textContent = modification[ prop ];

		} else if ( modification.type === ExperienceModificationType.JS ) {

			const iframe = this.iFrameRef.current;
			let scriptElement = iframe.contentWindow.document.getElementById( 'verra-global-js' );
			if( scriptElement == null ) {
				scriptElement = document.createElement( 'script' );
				scriptElement.setAttribute( 'id', 'verra-global-js' );
				iframe.contentWindow.document.head.appendChild( scriptElement );
			}
			scriptElement.textContent = modification[ prop ];

		}
	}

	//

	/**
	 * Handles the click to copy the selected elements path
	 */
	#handleElementPathInputChanged( value ) {
		const iFrame = this.iFrameRef.current;
		const elements = iFrame.contentWindow.document.querySelectorAll( value );
		// if( elements != null && elements.length > 0 ){
			this.state.querySelector = value;
			this.#selectElements( elements );
		// }
	}

	/**
	 * Handles the click to copy the selected elements path
	 */
	#handleCopyElementPath() {
		navigator.clipboard.writeText( ElementUtil.getQuerySelectorPath( this.state.primaryElement ));
	}

	// Modification value getters 

	/**
	 * @return The editor content based on the provided element
	 */
	#getElementTextContent() {
		const content = ( this.state.primaryElement != null ) ? this.state.primaryElement.textContent : '';
		return content;
	}

	/**
	 * @return The editor content based on the provided element
	 */
	#getElementMarkupContent() {
		const content = ( this.state.primaryElement != null ) ? (( this.state.primaryElement.nodeType === 3 ) ? this.state.primaryElement.nodeValue : this.state.primaryElement.outerHTML ) : '';
		return content;
	}

	/**
	 * @return The global css for the experience
	 */
	#getCssContent() {
		const cssModification = SphereAdminSession.experience.modifications[ 'head #verra-global-css' ];
		return ( cssModification != null ) ? cssModification.value : '';
	}

	/**
	 * @return The global css for the experience
	 */
	#getJsContent() {
		const jsModification = SphereAdminSession.experience.modifications[ 'head #verra-global-js' ];
		return ( jsModification != null ) ? jsModification.value : '';
	}

	// Modification Change Handlers and Methods

	/**
	 * Handles changes to a text element
	 */
	#handleTextChanged( e ) {
		const value = e.target.value;
		const experience = SphereAdminSession.experience;
		const path = this.state.querySelector; // ElementUtil.getQuerySelectorPath( this.state.primaryElement );
		const key = `${ ExperienceModificationType.TEXT }-${ path }`;

		let modification = experience.modifications[ key ];
		if( modification == null ) {
			modification = new ExperienceModification();
			modification.type = ExperienceModificationType.TEXT;
			modification.path = path;
			modification.original = [];
			this.state.selectedElements.forEach( element => modification.original.push( element.textContent ));
			experience.modifications[ key ] = modification;
		}

		modification.value = value;
		this.state.selectedElements.forEach( element => element.textContent = value );

		experience.status = ModifiableObject.MODIFIED;

		this.setState({}); // redraw
		this.#updateHighlightPositions();
	}

	/**
	 * Handles markup changes to an HTML element
	 */
	#handleMarkupChanged( e ) {
		const value = e.target.value;

		// validate the changes
		const parser = new DOMParser();
  		const doc = parser.parseFromString( value, 'text/xml' );
		const parseError = ( doc.documentElement.querySelector( 'parsererror' ) != null );

		// if valid, make the change
		if( !parseError ) {
			const modifications = SphereAdminSession.experience.modifications;
			const path = this.state.querySelector;

			// TODO: if a markup modification already exists this should over write it with a warning?
			// Or, ideally, the changes is simply merged as a single change. The problem now, if the first
			// change is a markup up change the enclosing tag is included in the original value. When the 
			// preview is turned off, the markup shows up as text, instead of markup

			let modification = modifications[ path ];
			if( modification == null ) {
				modification = new ExperienceModification();
				modification.type = ExperienceModificationType.MARKUP;
				modification.path = path;
				// modification.original = this.state.primaryElement.outerHTML;
				modification.original = [];
				this.state.selectedElements.forEach( element => modification.original.push( element.outerHTML ) );
				modifications[ path ] = modification;
			}
			modification.value = value;

			const newElements = [];
			this.state.selectedElements.forEach(( element, index ) => {
				const template = document.createElement( 'template' );
				template.innerHTML = value;
				const newElement = template.content.firstChild;
				element.replaceWith( newElement );
				newElements.push( newElement );
				if( index === 0 ) this.state.primaryElement = newElement;
			});
			this.state.selectedElements = newElements;

			SphereAdminSession.experience.status = ModifiableObject.MODIFIED;
			this.#updateHighlightPositions();
		}

		this.setState({ codeInEditorIsInvalid: parseError });
	}

	/**
	 * Select handler for the StylePropertyPicker
	 */
	#handleAddNewStyleProperty( property ) {
		const modifications = SphereAdminSession.experience.modifications;
		const key = `${ ExperienceModificationType.STYLES }-${ this.state.querySelector }`;

		let modification = modifications[ key ];
		if( modification == null ) {
			modification = new ExperienceModification();
			modification.type = ExperienceModificationType.STYLES;
			modification.path = this.state.querySelector;
			modification.value = {};
			modifications[ key ] = modification;
		}

		if( modification.value[ property ] == null ) {
			modification.value[ property ] = { 
				value: null
			}
			this.setState({});
		}
	}

	/**
	 * Handles the click to remove a style property
	 */
	#handleRemoveStyleProperty( styleProp ) {
		const modifications = SphereAdminSession.experience.modifications;
		const key = `${ ExperienceModificationType.STYLES }-${ this.state.querySelector }`;
		const modification = modifications[ key ];
		delete modification.value[ styleProp ];
		this.#applyStylesModification( key, modification, true );
		this.#updateHighlightPositions();
		if( Object.keys( modification.value ).length === 0 ) delete modifications[ key ];
		this.setState({});
	}

	/**
	 * Handles changes an element style from the styles editor.
	 * In the editor / preview mode, styles are tracked in individual style tags. When applied live, style 
	 * changes are all bundled into a single style tag, along with any global css changes.
	 */
	#handleStyleChanged( styleProp, value ) {
		const property = CSSProperties[ styleProp ];
		const modifications = SphereAdminSession.experience.modifications;
		const path = this.state.querySelector;
		const key = `${ExperienceModificationType.STYLES}-${ path }`;
		const styleTagId = key.replace( / /g, '---' );

		if( property.type === CSSPropertyType.COLOR ) value = `rgba(${ value.rgb.r },${ value.rgb.g },${ value.rgb.b },${ value.rgb.a })`;

		let modification = modifications[ key ];
		if( modification == null ) {
			modification = new ExperienceModification();
			modification.type = ExperienceModificationType.STYLES;
			modification.path = path;
			modification.value = {};
			modifications[ key ] = modification;
		}

		const iframe = this.iFrameRef.current;
		let styleElement = iframe.contentWindow.document.getElementById( styleTagId );
		if( styleElement == null ) {
			styleElement = document.createElement( 'style' );
			styleElement.setAttribute( 'id', styleTagId );
			iframe.contentWindow.document.head.appendChild( styleElement );
		}

		if( modification.value[ styleProp ] == null ) modification.value[ styleProp ] = {};
		modification.value[ styleProp ].value = value;

		let hasChange = false;
		let styleContent = `${ path } {`;
		Object.keys( modification.value ).forEach( prop => {
			if( modification.value[ prop ].value !== modification.value[ prop ].original ) {
				hasChange = true;
				styleContent += `${ prop }:${ modification.value[ prop ].value };`;
			} else {
				delete modification.value[ prop ];
			}
		});
		styleContent += '}';

		if( hasChange ) {
			styleElement.textContent = styleContent;
		} else {
			delete modifications[ key ];
			styleElement.remove();
		}

		SphereAdminSession.experience.status = ModifiableObject.MODIFIED;
		
		this.#updateHighlightPositions();
		this.setState({}); // redraw
	}

	/**
	 * Applies a style modification
	 * @param key The modification key in the Experience.modifications object
	 * @param modification The modification of TYPE_STYLES to apply
	 * @param applyChanges Determines if the modification should be applied or removed
	 */
	#applyStylesModification( key, modification, applyChanges ) {
		const styleTagId = key.replace( / /g, '---' );
		const iframe = this.iFrameRef.current;

		let styleElement = iframe.contentWindow.document.getElementById( styleTagId );
		if( styleElement == null ) {
			styleElement = document.createElement( 'style' );
			styleElement.setAttribute( 'id', styleTagId );
			iframe.contentWindow.document.head.appendChild( styleElement );
		}

		if( !applyChanges ) {
			styleElement.textContent = '';
		} else {
			let styleContent = `${ modification.path } {`;
			Object.keys( modification.value ).forEach( prop => {
				if( modification.value[ prop ].value !== modification.value[ prop ].original ) {
					styleContent += `${ prop }:${ modification.value[ prop ].value };`;
				}
			});
			styleContent += '}';
			styleElement.textContent = styleContent;
		}
	}

	/**
	 * Handles the click to remove an element
	 */
	#handleAddElement() {
		
	}

	/**
	 * Handles the click to remove an element
	 */
	#handleRemoveElement() {
		const modifications = SphereAdminSession.experience.modifications;
		const path = this.state.querySelector;
		const key = `${ ExperienceModificationType.REMOVE }-${ path }`;
		
		let modification = modifications[ key ];
		if( modification == null ) {
			modification = new ExperienceModification();
			modification.type = ExperienceModificationType.REMOVE;
			modification.path = path;
			modification.original = this.state.primaryElement.style.getPropertyValue( 'display' );
			modifications[ key ] = modification;
		}

		this.state.selectedElements.forEach( element => element.style.display = 'none' );

		SphereAdminSession.experience.status = ModifiableObject.MODIFIED;
		this.setState({ primaryElement: null, selectedElements: null });
	}

	/**
	 * Handles changes to the global CSS modification
	 */
	#handleCssChanged( e ) {
		const value = e.target.value;
		const modifications = SphereAdminSession.experience.modifications;
		const path = 'head #verra-global-css';
		
		let modification = modifications[ path ];

		if( modification == null ) {
			modification = new ExperienceModification();
			modification.type = ExperienceModificationType.CSS
			modification.path = path;
			modifications[ path ] = modification;
		}

		const iframe = this.iFrameRef.current;
		let styleElement = iframe.contentWindow.document.getElementById( 'verra-global-css' );
		if( styleElement == null ) {
			styleElement = document.createElement( 'style' );
			styleElement.setAttribute( 'id', 'verra-global-css' );
			iframe.contentWindow.document.head.appendChild( styleElement );
		}

		modification.value = value;
		styleElement.textContent = value;

		SphereAdminSession.experience.status = ModifiableObject.MODIFIED;

		this.setState({}); // redraw
		if( this.state.primaryElement != null ) this.#updateHighlightPositions(); // in case the change affects a selected element
	}

	/**
	 * Handles changes to the global JS modification
	 */
	#handleJsChanged( e ) {
		const value = e.target.value;
		const modifications = SphereAdminSession.experience.modifications;
		const path = 'head #verra-global-js';
		
		let modification = modifications[ path ];

		if( modification == null ) {
			modification = new ExperienceModification();
			modification.type = ExperienceModificationType.JS
			modification.path = path;
			modifications[ path ] = modification;
		}

		const iframe = this.iFrameRef.current;
		let scriptElement = iframe.contentWindow.document.getElementById( 'verra-global-js' );
		if( scriptElement == null ) {
			scriptElement = document.createElement( 'script' );
			scriptElement.setAttribute( 'id', 'verra-global-js' );
			iframe.contentWindow.document.head.appendChild( scriptElement );
		}

		modification.value = value;
		scriptElement.textContent = value;

		SphereAdminSession.experience.status = ModifiableObject.MODIFIED;

		this.setState({}); // redraw
	}

	/**
	 * Handles selecting the elements of a modification
	 */
	#handleSelectModification( key ) {
		const modification = SphereAdminSession.experience.modifications[ key ];
		if( modification.type === ExperienceModificationType.CSS ) {
			this.#handleToggleEditor( ExperienceModificationType.CSS )
		} else if ( modification.type === ExperienceModificationType.JS ) {
			this.#handleToggleEditor( ExperienceModificationType.JS )
		} else if ( this.state.viewChanges ) {
			this.state.selectedElements = null;
			this.state.querySelector = modification.path;
			this.#selectElements( this.iFrameRef.current.contentWindow.document.querySelectorAll( modification.path ));
		}
	}

	/**
	 * Handles toggling the preview of a single modification
	 */
	#handleTogglePreviewOfModification( e, key ) {
		e.stopPropagation();
		const modification = SphereAdminSession.experience.modifications[ key ];
		this.#toggleModification( key, !modification.isVisible );
		this.#updateHighlightPositions();
		this.setState({});
   	}

	/**
	 * Handles removal of a modification
	 */
   	#handleRemoveModification( e, key ) {
		e.stopPropagation();
		this.#toggleModification( key, false );
	   	delete SphereAdminSession.experience.modifications[ key ];
		this.#updateHighlightPositions();
		this.setState({});
   	}

	// Element Selection
	
	/**
	 * Selects an element for editing and/or import
	 */
	#selectElements( elements ) {
		this.selectedHighlightsContainer.current.innerHTML = '';

		let primaryElement;
		elements.forEach(( element, index ) => {
			if( index === 0 ) primaryElement = element;
			const highlight = document.createElement( 'div' );
			highlight.className = 'selected-highlight';
			this.selectedHighlightsContainer.current.appendChild( highlight );
			this.#positionHighlight( highlight, element ); 
		});

		if( primaryElement != null ) {
			// if we're currently in text mode, make sure the selected element(S) supports text editing, change to HTML if not
			const isTextNode = ( primaryElement.firstElementChild == null && primaryElement.firstChild.nodeType === 3 );
			if( this.state.editingMode === ExperienceModificationType.TEXT && !isTextNode ) this.state.editingMode = ExperienceModificationType.MARKUP;
		}

		const state = { primaryElement, selectedElements: elements, editingMode: this.state.editingMode };
		this.setState( state );
	}
	
	/**
	 * Updates the highlight positions
	 */
	#updateHighlightPositions() {
		if( this.highlightedElement != null ) {
			this.#positionHighlight( this.hoverHighlight.current, this.highlightedElement );
		}
		if( this.state.selectedElements != null ){
			const highlights = document.querySelectorAll( '.selected-highlight' );
			highlights.forEach(( highlight, i ) => {
				this.#positionHighlight( highlight, this.state.selectedElements[ i ]);
			});
		}
	}

	/**
	 * Positions the element highlight
	 * @param highlight The highlight element, either for the moused over element or selected element
	 * @param elementToHighlight The element within the iframe to hightlight
	 */
	#positionHighlight( highlight, elementToHighlight ) {
		const elementRect = elementToHighlight.getBoundingClientRect();
		highlight.style.top = ( elementRect.y ) + 'px';
		highlight.style.left = ( elementRect.x ) + 'px';
		highlight.style.width = ( elementRect.width ) + 'px';
		highlight.style.height = ( elementRect.height ) + 'px';
	}

	// iframe events

	/**
	 * Handles the load event from the iframe
	 */
	#handleIFrameLoaded( event ) {
		const experience = SphereAdminSession.experience;
		const isLocked = ( experience.status === ModifiableObject.LOCKED );
		const iframe = this.iFrameRef.current;

		if( !isLocked ) {
			iframe.contentWindow.document.body.addEventListener( 'mouseover', this.#handleMouseOver.bind( this ));
			iframe.contentWindow.document.body.addEventListener( 'mouseout', this.#handleMouseOut.bind( this ));
		}
		
		iframe.contentWindow.document.body.addEventListener( 'click', this.#handleDocumentClick.bind( this ), { capture: true });
		iframe.contentWindow.addEventListener( 'scroll', this.#handleIFrameScroll.bind( this ));
		iframe.contentWindow.addEventListener( 'resize', this.#handleIFrameResize.bind( this ));

		// load the changes into the iframe
		this.#handleTogglePreview( true );
		this.setState({ frameLoaded: true });
	}

	/**
	 * Handles mouse over events in the iframe
	 */
	#handleMouseOver( e ) {
		if( this.state.viewChanges ) {
			this.highlightedElement = e.target;
			const hoverHighlight = this.hoverHighlight.current
			hoverHighlight.style.display = 'block';
			this.#positionHighlight( hoverHighlight, this.highlightedElement );
		}
	}

	/**
	 * Handles mouse out events in the iframe
	 */
	#handleMouseOut( e ) {
		const hoverHighlight = this.hoverHighlight.current;
		hoverHighlight.style.display = 'none';
		this.highlightedElement = null;
	}

	/**
	 * Handles all clicks within the document
	 */
	#handleDocumentClick( e ) {
		e.preventDefault();
		e.stopPropagation(); 
		
		const experience = SphereAdminSession.experience;
		const isLocked = ( experience.status === ModifiableObject.LOCKED );

		const a = e.target.closest( 'a' );
		if( a != null ) this.state.path = a.href.split( window.location.origin )[ 1 ];

		if( this.state.viewChanges && !isLocked ) {
			this.state.selectedElements = null;
			this.state.querySelector = ElementUtil.getQuerySelectorPath( e.target );
			this.#selectElements([ e.target ]);
		} else {
			// put this in an else as selectElements will set the state and we don't need to call it twice
			this.setState({});
		}
	}

	/**
	 * Handles the scroll event from the iframe
	 */
	#handleIFrameScroll( e ) {
		this.#updateHighlightPositions();
	}

	/**
	 * Handles the resize event from the iframe
	 */
	#handleIFrameResize( e ) {
		this.#updateHighlightPositions();
	}

	// Post Message Handlers

	/**
	 * Handles iframe post message events
	 */
	#handleWindowMessage( event ) {
		// console.info( 'handleWindowMessage', event.data );
		if( event.data.action === 'SET_SITE' && this.iFrameRef.current != null ){
			this.#setSite( event.data.siteId, event.data.siteContent, event.data.path );
		}
	}

	/**
	 * Handles ...
	 */
	#setSite( siteId, siteContent, path ) {
		this.iFrameRef.current.contentWindow.document.open();
		this.iFrameRef.current.contentWindow.document.write( siteContent );
		this.iFrameRef.current.contentWindow.document.close();

		SphereAdminSession.experience.siteId = Number( siteId );

		const sites = SphereAdminSession.sites;
		const selectedSite = sites.find( site => site.id.toString() === siteId.toString() );

		this.setState({ path, selectedSite });
	}

	// Panel Resizing
	
	/**
	 * Begins the resize of the editor
	 */
	#startModificationsListResize( e ) {
		this.resizeModifications = true;
		this.resizeEditor = false;
		this.startDragX = e.clientX;
		this.startWidth = this.state.modificationsWidth;
		document.addEventListener( 'mouseup', this.upListener );
		document.addEventListener( 'mousemove', this.moveListener );
	}

	/**
	 * Begins the resize of the editor
	 */
	#startEditorResize( e ) {
		this.resizeModifications = false;
		this.resizeEditor = true;
		this.startDragY = e.clientY;
		this.startHeight = this.state.editorHeight;
		document.addEventListener( 'mouseup', this.upListener );
		document.addEventListener( 'mousemove', this.moveListener );
	}

	/**
	 * Ends the resize of the editor
	 */
	#stopResize( e ) {
		this.resizeModifications = false;
		this.resizeEditor = false;
		document.removeEventListener( 'mouseup', this.upListener );
		document.removeEventListener( 'mousemove', this.moveListener );
	}

	/**
	 * Handles the mouse move event, resizing the editor
	 */
	#resize( e ) {
		let state;
		if( this.resizeModifications ) {
			state = { modificationsWidth: e.clientX - this.startDragX + this.startWidth }
		} else if( this.resizeEditor ) {
			state = { editorHeight: e.clientY - this.startDragY + this.startHeight }
		}
		this.setState( state );
	}

	//

	/**
	 * Handles window resize events
	 */
	#handleResize() {
		this.#resizeContentContainers();
	}

	/**
	 * Resizes the edit and preview containers
	 */
	#resizeContentContainers() {
		const iFrameContainer = this.iFrameContainerRef.current;
		if( iFrameContainer != null ){
			const clientHeight = window.innerHeight;
			const controlsContainer = this.controlsContainerRef.current;
			const controlsRect = ( controlsContainer != null ) ? controlsContainer.getBoundingClientRect() : { y: 0 };
			const iFrameContainerRect = iFrameContainer.getBoundingClientRect();
			const height = clientHeight - iFrameContainerRect.y + controlsRect.y - 39;

			iFrameContainer.style.maxHeight = height + 'px';
			iFrameContainer.style.height = height + 'px';
			this.iFrameRef.current.style.height = height + 'px';
		}
	}

	// Save

	/**
	 * Saves the experience to session storage
	 */
	#saveToStorage() {
		const experienceData = JSON.stringify( SphereAdminSession.experience );
		window.sessionStorage.setItem( 'verra-site-mode-experience', experienceData );
	}

	/**
	 * Handles the click to save the Experience
	 */
	#handleSave() {
		this.#save( this.#handleSaveComplete.bind( this ));
	}

	/**
	 * Handles the click to save the Experience and then add it to an Optimization
	 */
	#handleSaveAndAdd() {
		console.info( 'handleSaveAndAdd' );
		this.#save( this.#addToOptimization.bind( this ));
	}

	/**
	 * Performs the save operation
	 */
	#save( saveCompleteHandler ) {
		console.info( 'save' );
		const fields = { 
			id: ValidatorCommand.isNotNullOrEmpty, 
			name: ValidatorCommand.isNotNullOrEmpty 
		};

		const validateSite = new ValidatorCommand( SphereAdminSession.experience, fields );
		const isValid = validateSite.execute();

		if( isValid ) {
			console.info( 'valid' );
			SphereAdminSession.loading = true;
			const saveAudience = new SaveExperienceRequest( SphereAdminSession.experience );
			saveAudience.execute( saveCompleteHandler );
		} else {
			
			const invalidFields = validateSite.getInvalidFields();
			const invalidFieldsElements = [];
	
			var i = 0;
			invalidFields.forEach( field => {
				invalidFieldsElements.push( <li key={ i++ }>{ field }</li> );
			});
	
			const content = <div className='alert'>
				The Experience cannot be saved. The following fields are invalid or incomplete:
				<ul>{ invalidFieldsElements }</ul>
			</div>;
	
			const openModal = new OpenModalCommand( 'Invalid Experience', content, '500px', true );
			openModal.execute();
		}
	}

	/**
	 * Handles completion of the save channel reques
	 */
	#handleSaveComplete(){
		this.#saveToStorage();
		if( SphereAdminSession.currentState === AdminStates.ADMIN_AUDIENCES_CREATE ) {
			const editAudience = new EditAudienceCommand( SphereAdminSession.audience );
			editAudience.execute();
		} else {
			SphereAdminSession.loading = false;
			this.setState({}); // redraw
		}
	}

	/**
	 * Executes the request to add the Experience to an Optimization
	 */
	#addToOptimization() {
		console.info( 'addToOptimization' );
		const addExperience = new AddExperienceToOptimizationRequest( SphereAdminSession.optimizationId, SphereAdminSession.experience );
		addExperience.execute( this.#handleAddToOptimizationComplete.bind( this ));
	}

	/**
	 * Executes the request to add the Experience to an Optimization
	 */
	#handleAddToOptimizationComplete() {
		console.info( 'handleAddToOptimizationComplete' );
		const editOptimization = new EditOptimizationCommand( SphereAdminSession.optimizationId );
		editOptimization.execute();
	}

	/**
	 * Handles a click on the cancel button
	 */
	#handleCancel() {
		const hasChanged = SphereAdminSession.experience.status === ModifiableObject.MODIFIED || SphereAdminSession.experience.status === ModifiableObject.CREATED;
		if( hasChanged ){
			const alert = <Alert content='You have unsaved changes, are you sure you want to exit?' okHandler={ this.#handleCancelConfirm.bind( this ) }/>;
			const openModal = new OpenModalCommand( 'Are you sure?', alert, '500px', true );
			openModal.execute();
		} else {
			const isFromOptimization = ( SphereAdminSession.optimizationId != null );
			if( isFromOptimization ) {
				const editOptimization = new EditOptimizationCommand( SphereAdminSession.optimizationId );
				editOptimization.execute();
			} else {
				const setState = new SetStateCommand( AdminStates.ADMIN_EXPERIENCES );
				setState.execute();
			}
		}
	};

	/**
	 * Handles a confirmation to cancel changes
	 */
	#handleCancelConfirm() {
		const isFromOptimization = ( SphereAdminSession.optimizationId != null );
		if( isFromOptimization ) {
			const editOptimization = new EditOptimizationCommand( SphereAdminSession.optimizationId );
			editOptimization.execute();
		} else {
			const setState = new SetStateCommand( AdminStates.ADMIN_EXPERIENCES );
			setState.execute();
		}
	};

	//

	/**
	 * Handles the click to create a new Experience. Only applicable if the Experience cannot be found
	 */
	#handleCreateExperience() {
		const create = new CreateExperienceCommand();
		create.execute();
	}

}

//

export default ExperienceEditor;
