/*
 * (c) Verra Technology Corporation
 */

import ServiceRequestCommand from '../ServiceRequestCommand';
import SphereAdminSession from '../../model/SphereAdminSession';
import SphereAdminServices from '../../model/SphereAdminServices';

/**
 * Deletes Sites
 */
class DeleteSitesRequest extends ServiceRequestCommand {

	/**
	 * Constructs the Request
	 * @param siteIds An array of Site IDs to delete
	 */
	constructor( siteIds ){
		super();
		this.siteIds = siteIds;
	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
    getService() {
		return SphereAdminServices.DELETE_SITES;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		return { accountId: SphereAdminSession.selectedAccount.id, siteIds: this.siteIds };
	}

   /**
	* Handles completion of the command
	*/
	handleComplete() {
		// console.info( 'DeleteSitesRequest::handleComplete', response );
		// TODO: handle errors
		var response = this.getResponse();
		if( response.success ){
			var updatedSites = [];
			SphereAdminSession.sites.forEach(( site ) => {
				if( !this.siteIds.includes( site.id ) ) updatedSites.push( site );
			});
			SphereAdminSession.sites = updatedSites;
		}
	}

}

export default DeleteSitesRequest;
